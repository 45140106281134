import { useState, useEffect } from 'react';
import './Countdown.scss';
import { getRemainingTimeUntilMsTimestamp } from './Utils/CountdownTimerUtils';
import { useTranslation } from 'react-i18next';

const defaultRemainingTime = {
  seconds: '00',
  minutes: '00',
  hours: '00',
  days: '00'
};

const CountdownTimer = ({ countdownTimestampMs, type, timeOut }) => {
  const { t } = useTranslation();
  const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);
  const [timeEnd, setTimeEnd] = useState(false);
  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    if (timeEnd === false && isInit === false) {
      const intervalId = setInterval(() => {
        const count = getRemainingTimeUntilMsTimestamp(countdownTimestampMs);
        updateRemainingTime(count);
        setIsInit(true);
        if (timeOut) tickerTimeOut(count, timeOut);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [countdownTimestampMs]);

  function updateRemainingTime(countdown) {
    setRemainingTime(countdown);
  }

  function tickerTimeOut(timeCount, timeOut) {
    const { days, hours, minutes, seconds } = timeCount;
    if (
      parseInt(days) === 0 &&
      parseInt(hours) === 0 &&
      parseInt(minutes) === 0 &&
      parseInt(seconds) === 0
    ) {
      timeOut();
      setTimeEnd(true);
    }
  }

  if (type === undefined) {
    return <SimpleMode remainingTime={remainingTime} t={t} />;
  } else {
    return <HardMode remainingTime={remainingTime} t={t} />;
  }
};

const TimeNumber = ({ number, hideColon }) => {
  return (
    <>
      <span style={{ '--value': number }} />
      {hideColon ? null : ':'}
    </>
  );
};

const SimpleMode = ({ remainingTime, t }) => {
  return (
    <span className="countdown font-mono text-2xl text-[#0103D1] sbm_countdown">
      <TimeNumber number={remainingTime.minutes} />
      <TimeNumber number={remainingTime.seconds} hideColon={true} />
    </span>
  );
};
const HardMode = ({ remainingTime, t }) => {
  return (
    <div className="grid grid-flow-col place-self-center gap-5 text-center auto-cols-auto mt-10 mb-5">
      <div className="flex flex-col place-self-center p-2 bg-neutral rounded-box text-neutral-content">
        <span className="countdown font-mono text-5xl sbm_countdown">
          <span style={{ '--value': remainingTime.days }} />
        </span>
        {t('countdown.d')}
      </div>
      <div className="flex flex-col place-self-center p-2 bg-neutral rounded-box text-neutral-content">
        <span className="countdown font-mono text-5xl sbm_countdown">
          <span style={{ '--value': remainingTime.hours }} />
        </span>
        {t('countdown.h')}
      </div>
      <div className="flex flex-col place-self-center p-2 bg-neutral rounded-box text-neutral-content">
        <span className="countdown font-mono text-5xl sbm_countdown">
          <span style={{ '--value': remainingTime.minutes }} />
        </span>
        {t('countdown.m')}
      </div>
      <div className="flex flex-col place-self-center p-2 bg-neutral rounded-box text-neutral-content">
        <span className="countdown font-mono text-5xl sbm_countdown">
          <span style={{ '--value': remainingTime.seconds }} />
        </span>
        {t('countdown.s')}
      </div>
    </div>
  );
};

export default CountdownTimer;
