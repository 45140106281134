import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../components/loading';
import { useDeal } from '../../contexts/DealContext';
import DealItems from './DealItems';
import Styles from './Home.module.scss';

const DealList = () => {
  const { dealList, initialLoading, loadMore, hasMore } = useDeal();
  console.log('dealList', dealList);
  if (initialLoading) return <Loading />;
  return (
    <>
      <div className="px-4 mt-5 pb-20 overflow-y-scroll scroll-ui">
        {dealList ? (
          <InfiniteScroll
            className=" grid grid-cols-12 gap-3 overflow-hidden scroll-ui-hide"
            dataLength={dealList.length}
            next={loadMore}
            hasMore={hasMore}
            loader={<Loading />}
            endMessage={<div className="col-span-12">No data</div>}
          >
            {dealList.map((item, keys) => (
              <DealItems key={keys} dataItem={item} />
            ))}
          </InfiniteScroll>
        ) : (
          <div className="grid gap-2 gap-y-7 grid-cols-1">
            <p className="text-[#E6564E] text-center">พบกันดีลใหม่เร็วๆ นี้</p>
          </div>
        )}
      </div>
    </>
  );
};
export default DealList;
