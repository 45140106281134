import HTMLReactParser from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import { CoinIcon } from '../../assets';
import { ImageLoading } from '../../components';
import { numberWithCommas } from '../../utils/number';
import styles from './Home.module.scss';

const DealItems = ({ dataItem }) => {
  console.log('dataItem', dataItem);
  const { title, details, thumbnail_image, price } = dataItem;
  const navigate = useNavigate();
  const onClick = (dataItem, balance) => {
    navigate('/line/deal-detail', { state: dataItem });
  };

  return (
    <>
      <div
        className={` col-span-6 md:col-span-4 lg:col-span-3 group rounded-lg cursor-pointer ${styles.items_box} bg-white`}
        onClick={() => onClick(dataItem)}
      >
        <div className="aspect-[62/71] flex flex-col justify-between pb-2">
          <ImageLoading
            className="object-cover aspect-[1/1] rounded-t-[5px] grow-0"
            src={thumbnail_image}
            aspectDefault={'1/1'}
          />
          <div className={`mx-2 grow-0`}>
            <div className="flex flex-row justify-end items-center w-full">
              <div className="text-[#181617] text-[14px] font-bold text-lg mx-1 ">
                {numberWithCommas(price)}
              </div>
              <img
                src={CoinIcon}
                className="aspect-square w-5 flex-none"
                alt="coin"
              />
            </div>
          </div>
          <div className="p-1 text-[#171717] grow">
            <div className="font-bold flex justify-between items-center text-left">
              <span className="line-clamp-1 text-[16px]">{title}</span>
            </div>
          </div>
          <div className="p-1 font-kanitLight text-[#171717] grow">
            <div className="flex justify-between items-center text-left">
              <span className="line-clamp-1 text-[14px]">
                {HTMLReactParser(details)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DealItems;
