import { createContext, useEffect, useState } from 'react';
import { FAIL, SUCCESS } from './constant';
import { AccountInfo, loginWithUser } from './services/api';
import ConfigApi from './services/config';
import { getPhoneByToken } from './utils';
import { getSystemInfo } from './config/system_api';
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  let [user, setUser] = useState(null);
  let [isLoading, setIsloading] = useState(true);
  let [dashMenu, setDastMenu] = useState('home');
  let [termChecked, setTermChecked] = useState(false);
  let [consentOne, setConsentOne] = useState(false);
  let [consentTwo, setConsentTwo] = useState(false);

  let [isKyc, setIsKyc] = useState(false);

  let [profile, setProfile] = useState(null);

  let [consentData, setConsentData] = useState();
  let [consentDataDashboard, setConsentDataDashboard] = useState();

  let [referUrl, setReferUrl] = useState(null);

  let [systemInfo, setSystemInfo] = useState(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const token = getToken();
        if (token) {
          await initProfile(token);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsloading(false);
      }
    };
    fetchUserInfo();
  }, []);

  const initLoginByToken = (data) => {
    saveToken(data);
    setIsLogin(true);
  };

  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token;
  };

  const getCookie = (name) => {
    var re = new RegExp(name + '=([^;]+)');
    var value = re.exec(document.cookie);
    return value != null ? unescape(value[1]) : null;
  };

  const [token, setToken] = useState(getToken());
  const [isLogin, setIsLogin] = useState(
    getToken() !== undefined ? true : false
  );

  const saveToken = (userToken) => {
    localStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken.token);
  };

  let [cookiePolicy, setCookiePolicy] = useState(
    getCookie('viewed_cookie_policy') === 'yes' ? true : false
  );

  let [phoneNumber, setPhoneNumber] = useState();

  let login = async (user, pass) => {
    setIsloading(true);
    const { data: dataRes, status } = await loginWithUser(user, pass);
    const { data } = dataRes;
    if (status === 200) {
      const profileRes = await initProfile(data.token);
      // console.log('profileRes', profileRes);
      // const phone = await getPhoneByToken(data.token);
      // const phone = await getPhoneByToken(data.token);
      setPhoneNumber(profileRes?.username);

      setUser({
        type: SUCCESS,
        data: data
      });
      saveToken(data);
      setIsLogin(true);
    } else {
      setUser({
        type: FAIL,
        data: data
      });
      setIsLogin(false);
    }
    setIsloading(false);
  };

  let logout = () => {
    resetContext();
  };

  let resetContext = () => {
    setUser(null);
    setIsLogin(false);
    localStorage.removeItem('token');
    setToken(getToken());
  };

  let initProfile = async (token) => {
    const { data: dataRes, status } = await AccountInfo(token);
    const { data } = dataRes;
    if (status === 200) {
      setProfile(data);
      setPhoneNumber(data?.username);
      setIsKyc(data?.is_kyc);
    } else {
      setProfile(null);
      setIsKyc(false);
    }
    return data;
  };

  const setUpProfile = (data) => {
    const appConfig = new ConfigApi(process.env);
    const { PROJECT_ID } = appConfig;
    const currentUrlRefer = getReferUrl(PROJECT_ID);
    setProfile(data);
    setIsKyc(data.is_kyc);
    setReferUrl(`${currentUrlRefer}${data?.referral}`);
  };

  const getReferUrl = (PROJECT_ID) => {
    let urlRef = '';
    if (PROJECT_ID === 'speedkub-dev') {
      urlRef = 'https://speedkub-dev.web.app/register?refer=';
    } else if (PROJECT_ID === 'speedkub-stg') {
      urlRef = 'https://speedkub-stg.web.app/register?refer=';
    } else {
      urlRef = 'https://www.speedkub.io/register?refer=';
    }
    return urlRef;
  };

  const initSystemInfo = async () => {
    try {
      const res = await getSystemInfo();
      const data = res.data.data;
      const { value } = data;
      setSystemInfo(value);
    } catch (error) {
      console.log('error', error)
    }
  };

  let value = {
    user,
    isLoading,
    login,
    logout,
    token,
    isLogin,
    dashMenu,
    setDastMenu,
    setUpProfile,
    termChecked,
    setTermChecked,
    profile,
    setProfile,
    consentOne,
    setConsentOne,
    consentTwo,
    setConsentTwo,
    cookiePolicy,
    setCookiePolicy,
    consentData,
    setConsentData,
    consentDataDashboard,
    setConsentDataDashboard,
    phoneNumber,
    setPhoneNumber,
    referUrl,
    setReferUrl,
    initProfile,
    isKyc,
    initSystemInfo,
    systemInfo,
    initLoginByToken
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
