import { useEffect, useState, createContext, useContext } from 'react';
import * as walletApi from '../api/walletApi';
import { getLocalStorage } from '../utils/localStorage';
import _ from 'lodash';
import { useSystem } from './SystemContext';

const WalletContext = createContext();

function WalletContextProvider({ children }) {
  const [history, setHistory] = useState([]);
  const [earn, setEarn] = useState([]);
  const [burn, setBurn] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);

  const [hasMore, setHasMore] = useState(true);
  const [tab, setTab] = useState('earn');
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(1);

  const { reloadConfig } = useSystem();

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        setInitialLoading(true);
        await reloadConfig();
        if (getLocalStorage()) {
          await getHistory(tab);
        }
      } catch (err) {
        // console.log('window.location.href', window.location.href);
        // console.log(err);
      } finally {
        setInitialLoading(false);
      }
    };
    fetchHistory();
  }, []);

  const getHistory = async (type) => {
    try {
      setInitialLoading(true);
      const res = await walletApi.getWalletHistory(type, page, limit);
      const data = await _.sortBy(res.data.data.data, 'created_at').reverse();
      const earnCurrent = [];
      const burnCurrent = [];
      if (data.length > 0) {
        for (const item of data) {
          if (type === 'burn') {
            burnCurrent.push(item);
          } else {
            earnCurrent.push(item);
          }
        }
      }

      setHistory(res.data.data);
      setPage(res.data.data?.page)
      setBurn(burnCurrent);
      setEarn(earnCurrent);
    } catch (err) {
      const { pathname, search } = window.location;
      window.location.replace(pathname + search);
    } finally {
      setInitialLoading(false);
    }
  };

  const loadMore = async () => {
    try {

      const res = await walletApi.getWalletHistory(tab, history.page + 1, limit);
      const dataHistory = tab === 'burn' ? burn : earn;
      const allData = [...dataHistory, ...(res.data.data.data ?? [])];
      const data = await _.sortBy(allData, 'created_at').reverse();

      const earnCurrent = [];
      const burnCurrent = [];

      if (data.length > 0) {
        for (const item of data) {
          if (tab === 'burn') {
            burnCurrent.push(item);
          } else {
            earnCurrent.push(item);
          }
        }
      }
      setHistory(res.data.data);
      setBurn(burnCurrent);
      setEarn(earnCurrent);
      if (res.data.data.data === null) {
        setHasMore(false);
      }
    } catch (err) {
      console.log('err', err);
    } finally {
    }
  };

  return (
    <WalletContext.Provider
      value={{
        initialLoading,
        getHistory,
        history,
        earn,
        burn,
        loadMore,
        tab,
        setTab,
        hasMore
      }}
    >
      {children}
    </WalletContext.Provider>
  );
}

export const useWallet = () => {
  return useContext(WalletContext);
};

export default WalletContextProvider;
