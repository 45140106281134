import axiosLineOA from '../config/axios';

export const getWalletBalance = () => {
  return axiosLineOA.get('/api/v1/wallet/balance');
};
export const getWalletWallet = (type, page, limit) =>
  axiosLineOA.get('api/v1/wallet/transactions', {
    params: {
      type,
      page,
      limit
    }
  });
