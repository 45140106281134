import axiosSBM from '../config/axios';

export const getAllNews = (phone) => {

    axiosSBM.defaults.headers.common['x-sbd-platform'] = "sbm-sabuymoney";
    if (phone) {
        axiosSBM.defaults.headers.common['x-sbd-user'] = phone;
    }

    return axiosSBM.get('/api/v1/news/list');
};
