import React from 'react';
import { Spinner } from './components';
import { useAuth } from './contexts/AuthContext';
import { useLoading } from './contexts/LoadingContext';
import RouterApp from './route/routerApp';
import { useSystem } from './contexts/SystemContext';
import MaintenanceMode from './MaintenanceMode';
import LoadingScreen from './components/LoadingScreen';
import { useLocation } from 'react-router-dom';
import { Navigate, useSearchParams } from 'react-router-dom';
import { getToken } from './utils/localStorage';

const App = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const { loading } = useLoading();
  const { initialLoading: initialLoadingSystem, maintenance } = useSystem();
  const { initialLoading } = useAuth();

  if (initialLoading || initialLoadingSystem) return <LoadingScreen />;
  if (maintenance.is_maintenance) return <MaintenanceMode />;

  //Check Redirect Page from Line rich menu
  const { pathname } = location;
  if (pathname === '/line') {
    const redirectPage = searchParams.get('page');
    const token = getToken();

    if (redirectPage) {
      const goPageMapping = {
        login: token ? '/profile' : '/login?page=deal',
        register: token ? '/profile' : '/login?page=deal',
        deal: token ? '' : '/login?page=deal',
        wallet: token ? '/wallet' : '/login?page=wallet'
      };
      const goRedirectPage = `line${goPageMapping[redirectPage]}`;
      console.log('goPageMapping[redirectPage]', goRedirectPage);
      return (
        <Navigate to={goRedirectPage} replace state={{ from: location }} />
      );
    }

    if (!token) {
      return (
        <Navigate
          to="/line/login?page=deal"
          replace
          state={{ from: location }}
        />
      );
    }
  }

  return (
    <>
      {loading && <Spinner />}
      <RouterApp />
    </>
  );
};
export default App;
