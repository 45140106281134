import React, { useContext, useEffect, useState } from 'react';
import { QrcodeIcon, ChartSquareBarIcon } from '@heroicons/react/outline';
import { Loading, HotDealBanner } from '../../components';
import CoinHome from '../../assets/coin.png';
import SpeedLogoGray from '../../assets/logo-gray.png';
import { dealCategory } from '../../config/filter_deal';
import Favorite from '../../assets/filter/favorite.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Qrcode from '../../assets/qr_download-new.png';


import {
  getDealByCategory,
  getInventoryList,
  getWalletBalance,
  search
} from '../../services/api';
import './rewards.scss';
import { AuthContext } from '../../auth';
import { toCommas } from '../../utils';
import DownloadAppBox from '../../components/DownloadAppBox';

export const BalanceCoin = ({ title, balanceValue, loading }) => {
  return (
    <>
      {/* <div className="container flex justify-center">
        <p className="text-2xl text-[#E6564E] text-center">{title ?? ''}</p>
      </div> */}

      <div className="container flex justify-center items-center">
        {loading ? (
          <Loading isLoading={loading} />
        ) : (
          <>
            <img
              className="mask mask-circle w-10 md:w-10 lg:w-10"
              alt="speedkub-coin"
              src={CoinHome}
            />
            <div className='font-bold text-[1.4rem]'>{toCommas(balanceValue)} SKS.</div>
          </>
        )}
      </div>
      {/* <div className="divider"></div> */}
    </>
  );
};

const SubMenu = ({ t }) => {
  return (
    <>
      <div className="container flex">
        <Link
          to="/history"
          className="btn btn-ghost flex flex-col justify-center w-1/3"
        >
          <ChartSquareBarIcon
            className="aspect-square w-6 "
            aria-hidden="true"
          />
          <span className="mt-2">{t('dash_menu.history')}</span>
        </Link>
        <Link
          to="/favorite"
          className="btn btn-ghost flex flex-col justify-center w-1/3"
        >
          <img
            src={Favorite}
            className="aspect-square w-6"
            aria-hidden="true"
          />
          <span className="mt-2">{t('dash_menu.my_favorite')}</span>
        </Link>
        <Link
          to="/qr"
          className="btn btn-ghost flex flex-col justify-center w-1/3"
        >
          <QrcodeIcon className="aspect-square w-6" aria-hidden="true" />
          <span className="mt-2">{t('dash_menu.my_qr')}</span>
        </Link>
      </div>
    </>
  );
};

const RewardsItems = ({ item, one }) => {
  const navigator = useNavigate();
  const { pathname } = useLocation();

  console.log('item', item)
  console.log('one', one)

  const [urlImage, setUrlImage] = useState([]);
  useEffect(() => {
    const loadImage = async () => {
      let listImage = [];
      listImage.push(item?.thumbnail_image);
      // for (const key in item.images) {
      //   let url = item.images[key];
      // listImage.push(url);
      //   setUrlImage(listImage);
      // }
      setUrlImage(listImage);
    };
    loadImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickToDetail = () => {
    if (item.id) {
      item.images = urlImage;
      navigator(`/rewards/${item.id}`, {
        replace: true,
        state: { itemData: item, pathname }
      });
    }
  };

  const rowStyle = one ? 'w-1/1' : 'w-1/2';

  console.log('urlImage', urlImage)
  return (
    <div
      className={`container pl-10 justify-center cursor-pointer lg:px-10 md:${rowStyle} lg:${rowStyle} xl:${rowStyle}`}
      onClick={clickToDetail}
    >
      <div className="card card-side h-32 rounded-none bg-base-100 hover:bg-base-300 my-4 justify-center w-full shadow-xl items-center">
        {urlImage.length > 0 ? (
          <div className="avatar">
            <div className="aspect-square object-scale-down h-28 w-28 rounded">
              <img src={urlImage[0]} alt="thumb" />
            </div>
          </div>
        ) : (
          <div className="px-3 h-28 w-28 rounded bg-[#D3D3D3]">
            <img
              className="object-center object-contain  h-28 w-28"
              src={SpeedLogoGray}
              alt="speed-logo-gray"
            />
          </div>
        )}
        <div className="card-body body-item h-full">
          <h2 className="card-title text-[#E6564E]">
            {toCommas(item.price)} SKS.
          </h2>
          <p className="text-left text-[#F2F2F2]  ">{item.title}</p>
        </div>
      </div>
    </div>
  );
};

const FilterItem = ({ t, item, isActive, onClick }) => {
  return isActive ? (
    <div onClick={onClick} className="ml-2 mt-2">
      <label className="btn btn-sm btn-primary rounded-full text-white place-self-center ">
        <img
          className="mask mask-circle w-5"
          src={item?.icon}
          alt={item?.name}
        />
        <span className="place-self-center leading-5 mt-0.5 ml-0.5">
          {t(`filter.${item.name}`)}
        </span>
      </label>
    </div>
  ) : (
    <div onClick={onClick} className="ml-2 mt-2">
      <label className="btn btn-sm btn-outline hover:bg-[#E6564E] text-white hover:text-white rounded-full shadow-sm hover:border-[#E6564E]">
        <img
          className="mask mask-circle w-5"
          src={item?.icon}
          alt={item.name}
        />
        <span className="place-self-center leading-5 mt-0.5 ml-0.5">
          {t(`filter.${item.name}`)}
        </span>
      </label>
    </div>
  );
};

const RewardsContent = ({ t, hideSubMenu, title, phone }) => {
  const [loading, setLoading] = useState(false);
  const [loadingDeal, setLoadingDeal] = useState(false);

  const [data, setData] = useState();
  const { token } = useContext(AuthContext);
  const [balanceValue, setBalanceValue] = useState(0);
  const [category, setCategory] = useState('');
  const [categoryList, setCategoryList] = useState(dealCategory);

  const [searchValue, setSearchValue] = useState('');

  const onSearch = async (e) => {
    if (e.key === 'Enter') {
      const data = await search(token, searchValue, phone, category);
      setData(data.data);
    } else {
      const data = await search(token, searchValue, phone, category);
      setData(data.data);
    }
  };
  const getKey = (key) => {
    setSearchValue(key);
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      setLoadingDeal(true);
      const { data: inventoryList } = await getInventoryList(token, phone);
      const { data: walletBalance } = await getWalletBalance(token);

      setBalanceValue(walletBalance?.data.balance);
      const { status } = inventoryList;
      if (status !== 500) {
        setData(inventoryList.data.data);
      }
      setLoading(false);
      setLoadingDeal(false);
    }
    fetchData();
  }, []);

  const clear = async () => {
    setSearchValue('');
    getKey('');
    const { data: inventoryList } = await getInventoryList(token, phone);
    const { status } = inventoryList;
    if (status !== 500) {
      setData(inventoryList.data.data);
    }
  };

  const clickFilter = async (item) => {
    const categoryData = item.value;
    setCategory(categoryData);
    setLoadingDeal(true);
    const { data: dealList } = await getDealByCategory(token, categoryData, phone);
    const { status } = dealList;
    if (status !== 500) {
      setData(dealList);
    }
    setLoadingDeal(false);
  };


  return (
    <>
      <div className="w-full h-full flex flex-col items-center pt-5 pb-40">
        <BalanceCoin
          loading={loading}
          title={title}
          balanceValue={balanceValue}
        />
        <DownloadAppBox />
        {/* <div className="">
          <div className="relative grid justify-items-center">

            <h3 className="text-lg font-bold mb-2">
              {t('scan_qr.ms')}
            </h3>
            <img src={Qrcode} alt="" />
          </div>
        </div> */}
      </div>
    </>
  );


  // return (
  //   <>
  //     <div className="w-full h-full flex flex-col items-center pt-5 pb-40">
  //       <BalanceCoin
  //         loading={loading}
  //         title={title}
  //         balanceValue={balanceValue}
  //       />
  //       {hideSubMenu ? null : <SubMenu t={t} />}
  //       {/* <HotDealBanner /> */}
  //       {loadingDeal ? (
  //         <Loading isLoading={loadingDeal} />
  //       ) : (
  //         <>
  //           <div className="mt-4">
  //             <div className="form-field flex flex-row">
  //               <input
  //                 type="text"
  //                 className="w-96 rounded-full h-8 text-black p-4"
  //                 id="input_text"
  //                 value={searchValue}
  //                 onKeyDown={onSearch}
  //                 onChange={(e) => {
  //                   getKey(e.target.value);
  //                 }}
  //               />
  //               <span
  //                 className="absolute right-9 top-1 text-black cursor-pointer"
  //                 onClick={clear}
  //               >
  //                 {searchValue && (
  //                   <svg
  //                     xmlns="http://www.w3.org/2000/svg"
  //                     fill="none"
  //                     viewBox="0 0 24 24"
  //                     strokeWidth={1.5}
  //                     stroke="currentColor"
  //                     className="w-6 h-6"
  //                   >
  //                     <path
  //                       strokeLinecap="round"
  //                       strokeLinejoin="round"
  //                       d="M6 18L18 6M6 6l12 12"
  //                     />
  //                   </svg>
  //                 )}
  //               </span>
  //               <span
  //                 className="text-white float-right cursor-pointer ml-2"
  //                 onClick={onSearch}
  //               >
  //                 <svg
  //                   xmlns="http://www.w3.org/2000/svg"
  //                   fill="none"
  //                   viewBox="0 0 24 24"
  //                   strokeWidth={1.5}
  //                   stroke="currentColor"
  //                   className="w-6 h-6"
  //                 >
  //                   <path
  //                     strokeLinecap="round"
  //                     strokeLinejoin="round"
  //                     d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
  //                   />
  //                 </svg>
  //               </span>
  //             </div>
  //           </div>
  //           <div className="m-5 container flex flex-row flex-wrap justify-center">
  //             {categoryList.map((item, index) => {
  //               return (
  //                 <FilterItem
  //                   key={index}
  //                   t={t}
  //                   item={item}
  //                   onClick={() => clickFilter(item)}
  //                   isActive={category === item.value}
  //                 />
  //               );
  //             })}
  //           </div>
  //           <div className="container-box-list px-5 flex flex-row flex-wrap">
  //             {data ? (
  //               data.map((item) => {
  //                 return (
  //                   <RewardsItems
  //                     key={item.id}
  //                     item={item}
  //                     one={data.length === 1}
  //                   />
  //                 );
  //               })
  //             ) : (
  //               <div className="flex flex-col justify-center items-center mt-10">
  //                 <div>No result not found</div>
  //               </div>
  //             )}
  //           </div>
  //         </>
  //       )}
  //     </div>
  //   </>
  // );
};

export default RewardsContent;
