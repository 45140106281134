import React from 'react';
import { Content, MainContainer, Footer } from '../../layout';
import { useTranslation } from 'react-i18next';
import CoinHome from '../../assets/coin.png';
import { Link, useLocation } from 'react-router-dom';

const SuccessPage = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const rewardCoinValue = state?.stateData?.data?.reward ?? null;
  return (
    <MainContainer>
      <Content minHSceen={true}>
        <div className="w-full h-full flex flex-col justify-center items-center">
          <div className="container flex justify-center">
            <img
              className="mask mask-circle w-48 md:w-48 lg:w-48"
              alt="speedkub-coin"
              src={CoinHome}
            />
          </div>
          <div className="card flex-shrink-0 w-full max-w-xl">
            <div className="card-body">
              <div className="form-control">
                {rewardCoinValue ? (
                  <>
                    <p className="text-2xl text-[#E6564E] text-center">
                      ยินดีด้วย! คุณได้รับ
                    </p>
                    <p className="text-2xl text-[#E6564E] text-center">
                      {rewardCoinValue} เหรียญ
                    </p>
                    <p className="text-2xl text-[#E6564E] text-center">
                      จากการลงทะเบียน
                    </p>
                  </>
                ) : (
                  <>
                    <p className="text-2xl text-[#E6564E] text-center">
                      ยินดีด้วย! คุณได้ลงทะเบียนสำเร็จแล้ว
                    </p>
                  </>
                )}
              </div>
              <div className="form-control mt-6">
                <Link
                  to="/dashboard"
                  className="btn btn-primary text-lg rounded-full text-white"
                >
                  ตกลง
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Content>
      <Footer />
    </MainContainer>
  );
};
export default SuccessPage;
