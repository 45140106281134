import { useLocation } from 'react-router-dom';

import HTMLReactParser from 'html-react-parser';
import { ImageLoading } from '../../components';

const NewsContainerDetail = () => {
  const location = useLocation();
  const { id, title, detail, thumbnail_image } = location.state;

  return (
    <>
      <div className="relative z-10">
        <div className="p-4">
          <ImageLoading
            className="object-cover aspect-[4/3] rounded-[5px]"
            aspectDefault={'4/3'}
            src={thumbnail_image}
            alt={title}
          />
        </div>
        <div className="p-4">
          <div className="text-[#171717] text-[1.3rem]">
            <div className="font-bold flex justify-between">
              <span>{title}</span>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="text-[#171717] text-[1.1rem]">
            <div className="font-bold flex justify-between">
              <span>{HTMLReactParser(detail)}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewsContainerDetail;
