import axios from 'axios';
import axiosLineOA from '../config/axios';
import { getLineTokenStorage } from '../utils/localStorage';

// export const getAccountInfo = () => axiosLineOA.get('/account/info');
export const getAccountInfo = () => axiosLineOA.get('/api/v1/customer/info');

export const LoginWithLine = (data) => {
  const lineLogin = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_URI
  });
  const lineToken = getLineTokenStorage();
  const apiKey = process.env.REACT_APP_BACKEND_API_KEY;
  lineLogin.defaults.headers.common['Authorization'] = `Bearer ${lineToken}`;
  lineLogin.defaults.headers.common['api-key'] = apiKey;

  return lineLogin.post(`api/v1/authen/line/sign-in`, data);
};

export const registerStep1 = (data) => {
  return axiosLineOA.post('/api/v1/authen/register-step1', data);
};

export const registerStep2 = (data) => {
  return axiosLineOA.post('/api/v1/authen/register-step2', data);
};

export const refreshToken = () => {
  return axiosLineOA.post('/api/v1/authen/refresh-token', {
    refresh_token: localStorage.getItem('line_t_r')
  });
};

export const cancelUserMember = () => {
  return axiosLineOA.post('/api/v1/authen/line/sign-out');
};
