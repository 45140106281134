import axios from 'axios';
import { pef1_decrypt, pef1_encrypt } from '../utils/pdpa';

export const getTokenConsent = async () => {
  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_PDPA_BASE_URI}/auth/v1/token`;
    const payload = {
      grant_type: 'client_credentials',
      client_id: 'SBD',
      client_secret: `${process.env.REACT_APP_BACKEND_PDPA_CLIENT_SECRET}`
      // client_secret: "kxYYH9Y6TM",
    };
    const { data } = await axios.post(apiUrl, payload);
    return data;
  } catch (error) {
    // throw new Error(error);
    console.error(error);
    return error.response;
  }
};

const instancePDPA = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_PDPA_BASE_URI,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }
});
instancePDPA.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('line_t_c');
    if (token) {
      config.headers['Authorization'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instancePDPA.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const { access_token } = await getTokenConsent();
          localStorage.removeItem('line_t_c');
          localStorage.setItem('line_t_c', access_token);
          instancePDPA.defaults.headers.common['Authorization'] = access_token;
          return instancePDPA(originalConfig);
        } catch (_error) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }
          return Promise.reject(_error);
        }
      }
      if (err.response.status === 401 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }
    return Promise.reject(err);
  }
);
export const getSecretConsent = async () => {
  try {
    const apiUrl = `/auth/v1/secret`;
    const { data } = await instancePDPA.get(apiUrl);
    return data;
  } catch (error) {
    // throw new Error(error);
    console.log(error.response);
    return error.response;
  }
};

export const getConsentParties = async (phone) => {
  try {
    const apiUrl = `/arctic/v1/parties/consents`;
    const config = {
      params: {
        entity: 'SBD',
        channel: 'SpeedKub',
        phone: `${phone}`
      }
    };
    const { data } = await instancePDPA.get(apiUrl, config);
    return data;
  } catch (error) {
    // throw new Error(error);
    console.error(error);
    return error;
  }
};

export const getConsent = async (phone, status) => {
  try {
    const apiUrl = `/arctic/v1/consents`;
    const config = {
      params: {
        entity: 'SBD',
        channel: 'SpeedKub',
        status: `${status}`, // Fixed value (“active”)
        phone: `${phone}`
      }
    };
    const { data } = await instancePDPA.get(apiUrl, config);
    return data;
  } catch (error) {
    // throw new Error(error);

    console.error(error);
    return error;
  }
};

export const saveConsent = async (body, secret) => {
  try {
    const apiUrl = `/arctic/v1/parties/consents`;
    console.log('body', body);
    const data_encrypt = await pef1_encrypt(secret, JSON.stringify(body));
    console.log('data_encrypt', data_encrypt);
    const res = await instancePDPA.patch(apiUrl, data_encrypt);
    const data_decrypt = await pef1_decrypt(secret, res?.data);
    return data_decrypt;
  } catch (error) {
    // throw new Error(error);
    // await handleAuth(error);
    console.error(error);
    return error;
  }
};
