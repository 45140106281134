import axiosLineOA from '../config/axios';

export const getAllNews = (phone) => {
  axiosLineOA.defaults.headers.common['x-sbd-platform'] = "sbd-speedkub";
  if (phone) {
    axiosLineOA.defaults.headers.common['x-sbd-user'] = phone;
  }

  return axiosLineOA.get('/api/v1/news/list');
};
