import {
  Buzzebees,
  Indeem,
  Kerry1,
  Laundrybar,
  LockBox2,
  Ninjavan,
  OrangeExpress,
  SwopMart,
  TeroSabuy,
  TripleILogistics,
} from "../../assets/partner";

export const PartnerSection = ({ t }) => {
  return (
    <div className="pt-10 px-20 pb-12 flex-col bg-white justify-center">
      <p className="text-3xl text-[#49494A] font-bold text-center leading-loose lg:leading-loose">
        {t("home.our_partners")}
      </p>
      <div className="flex flex-col justify-center">
        <div className="flex justify-center flex-wrap">
          <div className="px-5 flex justify-center items-center sm:basis-full md:basis-2/4 lg:basis-1/4 xl:basis-1/4">
            <img
              src={TeroSabuy}
              className="object-scale-down w-72"
              alt="TeroSabuy"
            />
          </div>
          <div className=" flex justify-center my-16 items-center sm:basis-full md:basis-2/4 lg:basis-1/4 xl:basis-1/4">
            <img src={Kerry1} alt="Kerry1" className="object-scale-down w-80" />
          </div>
          <div className="px-1 flex justify-center items-center sm:basis-full md:basis-2/4 lg:basis-1/4 xl:basis-1/4">
            <img
              src={OrangeExpress}
              className="object-scale-down w-72"
              alt="OrangeExpress"
            />
          </div>
        </div>
        <div className="flex justify-center flex-wrap">
          <div className="px-5 flex justify-center items-center sm:basis-full md:basis-2/4 lg:basis-1/4 xl:basis-1/4">
            <img
              src={Indeem}
              className="object-scale-down w-48 "
              alt="Indeem"
            />
          </div>
          <div className="px-5 flex justify-center my-16 items-center sm:basis-full md:basis-2/4 lg:basis-1/4 xl:basis-1/4">
            <img
              src={Laundrybar}
              alt="Laundrybar"
              className="object-scale-down w-64"
            />
          </div>
          <div className="px-5 flex justify-center my-16 items-center sm:basis-full md:basis-2/4 lg:basis-1/4 xl:basis-1/4">
            <img
              src={LockBox2}
              alt="LockBox2"
              className="object-scale-down w-64"
            />
          </div>
        </div>
        <div className="flex justify-center flex-wrap">
          <div className="px-5 flex justify-center my-16 items-center sm:basis-full md:basis-2/4 lg:basis-1/4 xl:basis-1/4">
            <img
              src={Buzzebees}
              alt="Buzzebees"
              className="object-scale-down w-32"
            />
          </div>
          <div className="px-5 flex justify-center items-center sm:basis-full md:basis-2/4 lg:basis-1/4 xl:basis-1/4">
            <img
              src={SwopMart}
              className="object-scale-down w-48 "
              alt="SwopMart"
            />
          </div>
          <div className="px-5 flex justify-center my-16 items-center sm:basis-full md:basis-2/4 lg:basis-1/4 xl:basis-1/4">
            <img
              src={Ninjavan}
              alt="Ninjavan"
              className="object-scale-down w-64"
            />
          </div>
        </div>
        <div className="flex justify-center flex-wrap">
          
          <div className="px-5 flex justify-center items-center sm:basis-full md:basis-2/4 lg:basis-1/4 xl:basis-1/4">
            <img
              src={TripleILogistics}
              className="object-scale-down w-48 "
              alt="TripleILogistics"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
