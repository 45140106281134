import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/AuthContext';
import { useDeal } from '../../contexts/DealContext';
import { SearchIcon } from '../../assets';
import { useState } from 'react';

const FilterSearch = () => {
  const { t } = useTranslation();
  const { searchDeal } = useDeal();
  const { phoneNumber } = useAuth();
  const [keyword, setKeyword] = useState('');

  const onClick = () => {
    searchDeal(keyword, phoneNumber);
  };

  const handleChange = (e) => {
    setKeyword(e.target.value);
  }

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      onClick()
    }
  }

  return (
    <div className={`px-4`}>
      <div className="form-control w-full">
        <div className="input-group">
          <button className="btn btn-outline bg-transparent hover:bg-transparent border-r-0" onClick={onClick}>
            <img src={SearchIcon} className='h-6' alt='search icon' />
          </button>
          <input type="text" name='keyword' placeholder={t('search.input_search_placeholder')} className="input input-bordered w-full pl-0 border-l-0" onChange={handleChange} onKeyUp={handleKeyUp} />
        </div>
      </div>
    </div>
  );
};
export default FilterSearch;
