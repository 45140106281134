import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  ErrorPage,
  HistoryPage,
  HomePage,
  NewsPage,
  NewsDetail
} from '../pages';
import DealDetailPage from '../pages/DealDetailPage';
import { getLocalStorage } from '../utils/localStorage';

const RouterApp = () => {
  const token = getLocalStorage() ?? '';
  return token ? (
    <Routes>
      <Route path="/sabuymoney" element={<HomePage />} />
      <Route path="/sabuymoney/deal-detail" element={<DealDetailPage />} />
      <Route path="/sabuymoney/news" element={<NewsPage />} />
      <Route path="/sabuymoney/news-detail" element={<NewsDetail />} />
      <Route path="/sabuymoney/history" element={<HistoryPage />} />
    </Routes>
  ) : (
    <Routes>
      <Route path="/">
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  );
};

export default RouterApp;
