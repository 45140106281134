import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from '../../components';
import Loading from '../../components/loading';
import { useDeal } from '../../contexts/DealContext';
import DeaItems from './DeaItems';
import Styles from './Home.module.scss';
import { useWallet } from '../../contexts/WalletContext';
import { useAuth } from '../../contexts/AuthContext';

const DealList = () => {
  const { dealList, initialLoading, loadMore, hasMore } = useDeal();
  console.log('dealList', dealList)
  if (initialLoading) return <Loading />;
  return (
    <>
      <div className="px-4 pt-5 mb-5">
        {dealList ? (
          <InfiniteScroll
            className="grid gap-3 gap-y-3 grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
            dataLength={dealList.length}
            next={loadMore}
            hasMore={hasMore}
            loader={<Loading />}
            endMessage={<div className={`${Styles.end}`}>Not Data</div>}
          >
            {dealList.map((item, keys) => {
              return <DeaItems key={keys} dataItem={item} />;
            })}
          </InfiniteScroll>
        ) : (
          <div className="grid gap-2 gap-y-7 grid-cols-1">
            <p className="text-[#0103D1] text-center">พบกันดีลใหม่เร็วๆ นี้</p>
          </div>
        )}
      </div>
    </>
  );
};
export default DealList;
