import { useEffect, useState } from 'react';
import { Loading } from '../../components';
import { useNews } from '../../contexts/NewsContext';
import NewsItem from './NewsItem';

const NewsContainer = () => {
  const { newsList, initialLoading } = useNews();
  if (initialLoading) return <Loading />;

  return (
    <>
      <div className="px-5 py-5">
        <div className="grid gap-2 gap-y-7 grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {newsList.map((item, keys) => {
            return <NewsItem key={keys} dataItem={item} />;
          })}
        </div>
      </div>
    </>
  );
};
export default NewsContainer;
