import React from 'react';

import './config/init_api';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './auth';
// import './index.scss';

import {
  AboutPage,
  // RegisterPage,
  CountdownPage,
  LoginPage,
  HomePage,
  UserInfoPage,
  OtpPage,
  SuccessPage,
  ForgotPasswordPage,
  StatusPage,
  DashboardPage,
  SetPasswordPage,
  RewardsPage,
  QRPage,
  RewardsDetailPage,
  ProfilePage,
  TermPage,
  CookiePage,
  PdpaPage,
  ReferPage,
  FavoritePage,
  HistoryPage,
  RegisterPage,
  KycInfoPage
} from './pages';

import { ROUTES } from './constant';
import ProtectedRoutes from './ProtectedRoutes';
import { checkStartSpeedKub } from './utils';
import { useLocation } from 'react-router-dom';
import RegisterContextProvider from './RegisterContext';

const SpeedKubApp = () => {
  return (
    <AuthProvider>
      <RegisterContextProvider>
        <Routes>
          <Route
            path="*"
            element={checkStartSpeedKub() ? <CountdownPage /> : <HomePage />}
          />
          {/* <Route path="/" element={<CountdownPage />} /> */}
          {/* <Route path="about" element={<AboutPage />} />
          <Route path="userinfo" element={<UserInfoPage />} />
          <Route path="kycinfo" element={<KycInfoPage />} />
          <Route path="countdown" element={<CountdownPage />} />
          <Route path="otp" element={<OtpPage />} />
          <Route path="success" element={<SuccessPage />} />
          <Route path="forgot" element={<ForgotPasswordPage />} />
          <Route path="setforgot" element={<SetPasswordPage />} />
          <Route path="status" element={<StatusPage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="term" element={<TermPage />} />
          <Route path="cookie" element={<CookiePage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="register" element={<HomePage />} />
            <Route path="profile" element={<ProfilePage />} />
            <Route path="rewards" element={<RewardsPage />} />
            <Route path="rewards/:id" element={<RewardsDetailPage />} />
            <Route path="history" element={<HistoryPage />} />
            <Route path="qr" element={<QRPage />} />
            <Route path="favorite" element={<FavoritePage />} />
            <Route path={ROUTES.SETPASSWORD} element={<SetPasswordPage />} />
            <Route path={ROUTES.DASHBOARD} element={<DashboardPage />} />
            <Route path="refer" element={<ReferPage />} />
            <Route path="pdpa" element={<PdpaPage />} />
          </Route> */}
          <Route path="about" element={<AboutPage />} />
          {/* <Route path="userinfo" element={<UserInfoPage />} /> */}
          <Route path="kycinfo" element={<KycInfoPage />} />
          {/* <Route path="countdown" element={<CountdownPage />} /> */}
          <Route path="otp" element={<OtpPage />} />
          <Route path="success" element={<SuccessPage />} />
          {/* <Route path="forgot" element={<ForgotPasswordPage />} /> */}
          {/* <Route path="setforgot" element={<SetPasswordPage />} /> */}
          {/* <Route path="status" element={<StatusPage />} /> */}
          <Route path="login" element={<LoginPage />} />
          <Route path="term" element={<TermPage />} />
          <Route path="cookie" element={<CookiePage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="register" element={<HomePage />} />
            {/* <Route path="profile" element={<ProfilePage />} /> */}
            {/* <Route path="rewards" element={<RewardsPage />} /> */}
            {/* <Route path="rewards/:id" element={<RewardsDetailPage />} /> */}
            {/* <Route path="history" element={<HistoryPage />} /> */}
            {/* <Route path="qr" element={<QRPage />} /> */}
            {/* <Route path="favorite" element={<FavoritePage />} /> */}
            {/* <Route path={ROUTES.SETPASSWORD} element={<SetPasswordPage />} /> */}
            <Route path={ROUTES.DASHBOARD} element={<DashboardPage />} />
            <Route path="refer" element={<ReferPage />} />
            <Route path="pdpa" element={<PdpaPage />} />
          </Route>
        </Routes>
      </RegisterContextProvider>
    </AuthProvider>
  );
};
export default SpeedKubApp;
