import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import HTMLReactParser from 'html-react-parser';
import {
  CountdownTimer,
  ImageLoading,
  Loading,
  QRCodeGenerator
} from '../../components';
import { useTranslation } from 'react-i18next';

import styles from './Home.module.scss';
import { CoinIcon } from '../../assets';
import { numberWithCommas } from '../../utils/number';
import dayjs from 'dayjs';
import { useDeal } from '../../contexts/DealContext';
import { useAuth } from '../../contexts/AuthContext';

const Content = ({ data }) => {
  if (data) {
    return <div className="text-[#171717]">{HTMLReactParser(data)}</div>;
  }

  return null;
};

const TabBox = ({ item }) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState('detail');
  // const { receive, use } = useWallet();
  return (
    <>
      <div className="tabs">
        <div
          className={`tab tab-bordered ${tab === 'detail' ? 'tab-active' : null
            } w-1/2`}
          onClick={() => setTab('detail')}
        >
          {t('redeem.desc')}
        </div>
        <div
          className={`tab tab-bordered ${tab === 'condition' ? 'tab-active' : null
            }  w-1/2`}
          onClick={() => setTab('condition')}
        >
          {t('redeem.condition')}
        </div>
      </div>
      <div className={`container p-5`}>
        {tab === 'detail' ? (
          <Content data={item.details} />
        ) : (
          <Content data={item.agreement} />
        )}
      </div>
    </>
  );
};

const RedeemBox = ({ item }) => {
  const { t } = useTranslation();

  const { setCanRedeem } = useDeal();
  const { balance } = useAuth();

  const checkRedeem = () => {
    const button = document.getElementById('redeem-button');
    if (balance?.balance >= item?.price) {
      setCanRedeem(true);
      button.setAttribute('for', 'reward-confirm');
    } else {
      setCanRedeem(false);
      button.setAttribute('for', 'cannot-confirm');
    }
  };

  return (
    <>
      <label id="redeem-button" onClick={checkRedeem}>
        <div className={`${styles.price_box} mx-4 grow-0`}>
          <div className="flex flex-row justify-center items-center w-full">
            <div className="text-[#FFFFFF] text-[16px] text-lg ">
              {t('sbm.redeem')}
            </div>
            <div className="text-[#FFFFFF] text-[16px] font-bold text-lg mx-2 ">
              {numberWithCommas(item?.price)}
            </div>
            <img src={CoinIcon} className="aspect-square w-5 flex-none" alt='coin' />
          </div>
        </div>
      </label>
    </>
  );
};

const CannotRedeemModal = ({ t, item }) => {
  return (
    <div>
      <input type="checkbox" id="cannot-confirm" className="modal-toggle" />
      <label
        id="modal-cannot-confirm"
        className="modal min-h-full cursor-pointer z-[99999]"
        htmlFor="cannot-confirm"
      >
        <label className="modal-box flex flex-col w-11/12 max-w-5xl relative bg-slate-100 justify-between z-[9999]">
          <div className="overflow-auto mt-4">
            <div className="card card-side rounded-none bg-slate-100 my-4 flex-col justify-center items-center">
              <figure className="flex flex-col w-full justify-center items-center p-5 border-2 border-[#0103D1] rounded-lg drop-shadow-xl">
                <img
                  className="h-48 w-full object-contain md:h-full md:w-48 rounded-xl"
                  src={item?.images ? item.images[0] : CoinIcon}
                  alt={item.title}
                />
              </figure>
              <div className="card-body w-full">
                <p className="text-lg font-medium text-[#141519] text-center text">
                  {t('redeem.messagePopup')}
                </p>
              </div>
            </div>
          </div>
        </label>
      </label>
    </div>
  );
};

const ConfirmModal = ({ t, item }) => {
  const [redeem, setRedeem] = useState(false);
  const { state } = useLocation();
  const openRedeem = () => {
    document.getElementById('modal-confirm').click();
    setRedeem(true);
  };
  const closeRedeem = () => {
    setRedeem(false);
  };

  return (
    <div>
      <input type="checkbox" id="reward-confirm" className="modal-toggle" />
      <label
        id="modal-confirm"
        className="modal min-h-full cursor-pointer z-[99999]"
        htmlFor="reward-confirm"
      >
        <label className="modal-box flex flex-col w-11/12 max-w-5xl relative bg-slate-100 justify-between z-[9999]">
          <div className="overflow-auto mt-4">
            <div className="card card-side rounded-none bg-slate-100 my-4 flex-col justify-center items-center">
              <figure className="flex flex-col w-full justify-center items-center p-5 rounded-lg drop-shadow-xl">
                <img
                  className="h-48 w-full object-contain md:h-full md:w-48 rounded-xl"
                  src={item?.thumbnail_image ? item?.thumbnail_image : CoinIcon}
                  alt={item.title}
                />
              </figure>
              <div className="card-body w-full">
                <p className="text-lg font-medium text-[#141519] text-center">
                  {t('redeem.con_top')}
                </p>
                <p className="text-[#49494A] text-center">
                  {t('redeem.con_bottom')} {item?.price} SKS.
                  {t('redeem.con_or')}
                </p>
                <p className="text-[#0103D1] text-center">{t('redeem.tt')}</p>
              </div>
            </div>
          </div>
          <div className="modal-action justify-between">
            <label
              htmlFor="reward-confirm"
              className="btn btn-outline rounded-full text-[#0103D1] border-[#0103D1]"
            >
              {t('cancel')}
            </label>
            <label
              htmlFor="redeem-modal"
              onClick={() => openRedeem()}
              className="btn rounded-full text-white bg-[#0103D1] border-0"
            >
              {t('confirm')}
            </label>
          </div>
        </label>
      </label>
      {redeem ? (
        <RedeemModal t={t} closeRedeem={closeRedeem} item={item} />
      ) : null}
    </div>
  );
};

const RedeemModal = ({ t, item, closeRedeem }) => {
  const minutesToAdd = 5;
  const currentDate = new Date();
  const futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);
  const genQRTime = dayjs(currentDate);
  const [loading, setLoading] = useState(false);
  const [qrCodeData, setQrCodeData] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [qrTimeOut, setQrTimeOut] = useState(false);

  const { getCouponDeal } = useDeal();
  const { getWalletBalance, balance } = useAuth();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      const useDealData = {
        "deal_id": item?.id,
        "tokens": [
          {
            "token_id": balance?.token_id,
            "amount": item?.price,
          }
        ]
      }

      const res = await getCouponDeal(useDealData);
      console.log('res', res);
      const { data: useCouponData } = res.data;
      if (res && res?.status === 200) {
        setQrCodeData(useCouponData[0].code);
        setErrorMsg(undefined);
        await getWalletBalance();
      } else {
        setErrorMsg(res.data);
      }
      setLoading(false);
    }
    if (!qrCodeData) {
      fetchData();
    }
  }, [qrCodeData]);

  const timeOut = () => {
    setQrTimeOut(true);
  };

  return (
    <div>
      <input type="checkbox" id="redeem-modal" className="modal-toggle" />
      <div className="modal min-h-full z-[99999]">
        <div className="modal-box flex flex-col w-11/12 max-w-5xl relative bg-slate-100 justify-between z-[9999] !pb-0 !pl-0 !pr-2">
          <div className="flex modal-open w-full right-2 top-2 text-center">
            <button
              onClick={closeRedeem}
              className="btn btn-sm btn-circle absolute right-2 top-2"
            >
              ✕
            </button>
          </div>
          <div className="overflow-auto scroll-ui">
            <div className="card card-side rounded-none bg-slate-100 my-4 flex-col justify-center items-center">
              {qrCodeData ? (
                <div className="card-body w-full p-0">
                  {qrTimeOut ? null : (
                    <>
                      <p className="text-lg font-medium text-[#141519] text-center ">
                        {item?.title ? HTMLReactParser(item?.title) : null}
                      </p>
                      <p className="text-lg font-medium text-[#141519] text-center">
                        {qrCodeData}
                      </p>
                    </>
                  )}
                  {loading ? (
                    <Loading />
                  ) : (
                    <>
                      {qrCodeData ? (
                        <QRCodeGenerator
                          t={t}
                          value={qrCodeData}
                          qrTimeOut={qrTimeOut}
                        />
                      ) : (
                        <span>{errorMsg?.message}</span>
                      )}
                    </>
                  )}
                  {qrTimeOut ? null : (
                    <p className="text-lg font-medium text-[#141519] text-center flex justify-center items-center">
                      {t('redeem.top')}
                      <span className="mx-2">
                        {!loading ? (
                          <CountdownTimer
                            countdownTimestampMs={futureDate.getTime()}
                            timeOut={timeOut}
                          />
                        ) : null}
                      </span>
                    </p>
                  )}
                  <p className="text-[#141519] text-center">
                    {t('redeem.use_ago')} {genQRTime.format('DD/MM/YYYY')}{' '}
                    {t('redeem.time')}
                    {genQRTime.format('HH:mm:ss')}
                  </p>
                </div>
              ) : errorMsg ? (
                <div className="card-body w-full p-0">
                  <p className="text-lg font-medium text-[#141519] text-center flex justify-center items-center">
                    {errorMsg?.message}
                  </p>
                </div>
              ) : (
                <div className="card-body w-full p-0">
                  <Loading />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ModelMain = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const item = location.state;
  const { canRedeem } = useDeal();
  return (
    <>
      {item && canRedeem ? (
        <ConfirmModal t={t} item={item} />
      ) : (
        <CannotRedeemModal t={t} item={item} />
      )}
    </>
  );
};

const DealContainerDetail = () => {
  const location = useLocation();
  const item = location.state;
  const { title, thumbnail_image } = item;
  return (
    <>
      <div className="relative z-10">
        <div className="p-4">
          <ImageLoading
            className="object-cover aspect-[1/1] rounded-[5px]"
            aspectDefault={'1/1'}
            src={thumbnail_image}
            alt={title}
          />
        </div>
        <div className="p-4">
          <div className="text-[#171717] text-[1.3rem]">
            <div className="font-bold flex justify-between">
              <span>{title}</span>
            </div>
          </div>
        </div>
        <div className="p-4">
          <TabBox item={item} />
        </div>
        <div className="p-4">
          <RedeemBox item={item} />
        </div>
      </div>
    </>
  );
};
export default DealContainerDetail;
