import axios from 'axios';
//init api config
import ConfigApi from './config';
import _ from 'lodash';

import { pef1_decrypt, pef1_encrypt } from '../utils';
const appConfig = new ConfigApi(process.env);

const BASE_URI = appConfig.baseUrl;
const API_KEY = appConfig.apiKey;
const PDPA_BASE_URI = appConfig.pdpaUrl;
const PDPA_CLIENT_SECRET = appConfig.pdpaClientSecret;

const getToken = () => {
  const tokenString = localStorage.getItem('token');
  const userToken = JSON.parse(tokenString);
  return userToken?.token;
};

export async function getHeaderConfig(accessToken) {
  let headers = {
    'api-key': `${API_KEY}`
  };
  if (accessToken) {
    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`
    };
  } else {
    headers = {
      ...headers
    };
  }
  return {
    headers
  };
}

export async function getHeaderConfigNew(accessToken, phone) {
  let headers = {};
  if (accessToken) {
    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`
    };
  } else {
    headers = {
      ...headers
    };
  }

  if (phone) {
    headers = {
      ...headers,
      "x-sbd-platform": "sbd-speedkub",
      "x-sbd-user": phone
    };
  }

  return {
    headers
  };
}

async function handleAuth(response) {
  console.log('handleAuth error', response);
  const { status } = response;
  if (status === 401) {
    await Logout();
    localStorage.removeItem('token');
    window.location.assign('/login');
  }
}

export const loginWithUser = async (user, pass) => {
  try {
    const apiUrl = `${BASE_URI}/api/v1/authen/web/sign-in`;
    const config = await getHeaderConfig();
    const payload = { username: user, password: pass };
    const res = await axios.post(apiUrl, payload, config);
    return res;
  } catch (error) {
    // throw new Error(error);
    console.error(error);
    return error.response;
  }
};

export const register = async (user, pass, refer) => {
  try {
    const apiUrl = `${BASE_URI}/api/v1/authen/register-step1`;
    const config = await getHeaderConfig();
    let payload = { username: user, password: pass };
    if (refer) {
      payload.referral = refer;
    }
    const { data } = await axios.post(apiUrl, payload, config);
    debugger;
    return data;
  } catch (error) {
    console.log('err', error.data);
    // throw new Error(error);
    console.error(error);
    return error.response;
  }
};

export const registerStep1 = async (phone) => {
  try {
    const apiUrl = `${BASE_URI}/api/v1/authen/register-step1`;
    const config = await getHeaderConfig();
    let payload = { phone };
    const res = await axios.post(apiUrl, payload, config);
    return res;
  } catch (error) {
    console.log('err', error.data);
    // throw new Error(error);
    console.error(error);
    return error.response;
  }
};

export const registerStep2 = async (data) => {
  try {
    const apiUrl = `${BASE_URI}/api/v1/authen/register-step2`;
    const config = await getHeaderConfig();
    let payload = {
      "username": data?.username,
      "password": data?.password,
      "ref_code": data?.ref_code,
      "pin": data?.pin,
      "referrer_by": data?.referrer_by ?? ''
    };
    const res = await axios.post(apiUrl, payload, config);
    return res;
  } catch (error) {
    console.log('err', error.data);
    // throw new Error(error);
    console.error(error);
    return error.response;
  }
};

export const getNews = async (token, phone) => {
  try {
    const apiUrl = `${BASE_URI}/api/v1/news/list?page=1&limit=20`;
    const config = await getHeaderConfigNew(token, phone);
    const res = await axios.get(apiUrl, config);
    return res;
  } catch (error) {
    // throw new Error(error);
    await handleAuth(error);

    console.error(error);
    return error;
  }
};

export const getNewsNotLogin = async () => {
  try {
    const apiUrl = `${BASE_URI}/api/v1/news/list?page=1&limit=20`;
    const res = await axios.get(apiUrl);
    return res;
  } catch (error) {
    // throw new Error(error);
    await handleAuth(error);

    console.error(error);
    return error;
  }
};

// export const renewOtp = async (key) => {
//   try {
//     const apiUrl = `${BASE_URI}/account/renew-otp`;
//     const config = await getHeaderConfig();
//     const payload = { key };
//     const { data } = await axios.post(apiUrl, payload, config);
//     return data;
//   } catch (error) {
//     // throw new Error(error);
//     console.error(error);
//     return error.response;
//   }
// };

export const signUp = async (key, pin) => {
  try {
    const apiUrl = `${BASE_URI}/auth/sign-up`;
    const config = await getHeaderConfig();
    const payload = { key, pin };
    const { data } = await axios.post(apiUrl, payload, config);
    return data;
  } catch (error) {
    // throw new Error(error);
    console.error(error);
    return error.response;
  }
};

export const infoUpdate = async (form, token) => {
  try {
    console.log('form,token', form, token);
    const apiUrl = `${BASE_URI}/account/info-update`;
    const config = await getHeaderConfig(token);
    const payload = form;
    console.log('form', form);
    const data = await axios.post(apiUrl, payload, config);
    console.log('data', data);
    return data;
  } catch (error) {
    // throw new Error(error);
    await handleAuth(error);

    console.error(error);
    return error.response;
  }
};

export const getCoupon = async (id, token) => {
  try {
    const apiUrl = `${BASE_URI}/inventory/coupon`;
    const config = await getHeaderConfig(token);
    const payload = {
      id: id
    };
    const { data } = await axios.post(apiUrl, payload, config);
    return data;
  } catch (error) {
    // throw new Error(error);
    await handleAuth(error);
    console.error(error);
    return error.response;
  }
};

export const changePassword = async (oldPass, newPass) => {
  try {
    const apiUrl = `${BASE_URI}/account/renew-pwd`;
    const token = await getToken();
    const config = await getHeaderConfig(token);
    const payload = {
      old_pwd: oldPass,
      new_pwd: newPass
    };
    const { data } = await axios.post(apiUrl, payload, config);
    return data;
  } catch (error) {
    // throw new Error(error);
    await handleAuth(error);

    console.error(error);
    return error.response;
  }
};

export const forgotPassword = async (phone) => {
  try {
    const apiUrl = `${BASE_URI}/auth/forgot-pwd`;
    // const token = await getToken();
    const config = await getHeaderConfig();
    const payload = {
      phone: phone
    };
    const { data } = await axios.post(apiUrl, payload, config);
    return data;
  } catch (error) {
    // throw new Error(error);
    await handleAuth(error);

    console.error(error);
    return error;
  }
};

export const forgotVerifyOTP = async (phone, pin, key) => {
  try {
    const apiUrl = `${BASE_URI}/auth/forgot-pwd`;
    // const token = await getToken();
    const config = await getHeaderConfig();
    const payload = {
      phone: phone,
      pin: pin,
      key: key
    };
    const res = await axios.post(apiUrl, payload, config);
    console.log('forgotVerifyOTP data', res);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const forgotNewPassword = async (phone, pin, key, newPassword) => {
  try {
    const apiUrl = `${BASE_URI}/auth/forgot-pwd`;
    // const token = await getToken();
    const config = await getHeaderConfig();
    const payload = {
      phone: phone,
      pin: pin,
      key: key,
      new_pwd: newPassword
    };
    const { data } = await axios.post(apiUrl, payload, config);
    return data;
  } catch (error) {
    // throw new Error(error);
    await handleAuth(error);

    console.error(error);
    return error;
  }
};

export const AccountInfo = async (token) => {
  try {
    const apiUrl = `${BASE_URI}/api/v1/customer/info`;
    const config = await getHeaderConfig(token);
    const res = await axios.get(apiUrl, config);
    return res;
  } catch (error) {
    // throw new Error(error);
    await handleAuth(error);

    console.error(error);
    return error;
  }
};

export const Logout = async (token) => {
  try {
    const apiUrl = `${BASE_URI}/auth/sign-out`;
    const config = await getHeaderConfig(token);
    const { data } = await axios.get(apiUrl, config);
    if (data) {
      localStorage.removeItem('token');
    }
    return data;
  } catch (error) {
    // throw new Error(error);
    // await handleAuth(error.response);
    console.error(error);
    return error.response;
  }
};

export const getInventoryList = async (token, phone) => {

  try {
    const apiUrl = `${BASE_URI}/api/v1/deal/list`;
    const config = await getHeaderConfigNew(token, phone);
    // console.log('config', config)
    //   const payload = {
    //     "limit": 1000,
    //     "is_hotdeal": true,
    //     "is_vending": true
    // };
    const payload = {
      special_deal: true,
      // phone
    };
    let res = await axios.get(apiUrl, payload, config);
    res.data.data.data = await _.sortBy(res.data.data.data, 'start_date');
    return res;
  } catch (error) {
    await handleAuth(error.response);
    // throw new Error(error);
    console.error(error);
    return error.response;
  }
};

export const getHotDealList = async (token) => {
  try {
    const apiUrl = `${BASE_URI}/inventory/list`;
    const config = await getHeaderConfig(token);
    const payload = {
      is_hotdeal: true,
    };
    const { data } = await axios.post(apiUrl, payload, config);
    let dataRes = data;
    dataRes.data = await _.sortBy(dataRes.data, 'index');
    console.log(dataRes.data);
    return dataRes;
  } catch (error) {
    await handleAuth(error.response);
    // throw new Error(error);
    console.error(error);
    return error.response;
  }
};

export const sendFavorite = async (token, id) => {
  console.log(id);
  try {
    const apiUrl = `${BASE_URI}/memo/favorite-set?id=${id}`;
    const config = await getHeaderConfig(token);

    //   const payload = {
    //     "id": id,

    // };
    const { data } = await axios.put(apiUrl, {}, config);
    let dataRes = data;
    dataRes.data = await _.sortBy(dataRes.data, 'index');
    return dataRes;
  } catch (error) {
    await handleAuth(error.response);
    // throw new Error(error);
    console.error(error);
    return error.response;
  }
};

export const search = async (token, key, phone, category) => {
  try {
    const apiUrl = `${BASE_URI}/inventory/search?limit=0&offset=0&order_desc=0`;
    const config = await getHeaderConfig(token);
    let payload = {};
    if (category === 'vending') {
      payload = {
        "keyword": key,
        is_vending: true,
        phone,
      };
    } else if (category === '') {
      payload = {
        "keyword": key,
        special_deal: true,
        phone
      };
    }
    else if (category) {
      payload = {
        "keyword": key,
        category,
        phone,
      };
    }

    const { data } = await axios.post(apiUrl, payload, config);
    let dataRes = data;
    dataRes.data = await _.sortBy(dataRes.data, 'index');
    return dataRes;
  } catch (error) {
    await handleAuth(error.response);
    // throw new Error(error);
    console.error(error);
    return error.response;
  }
};

export const getFavoriteList = async (token) => {
  try {
    const apiUrl = `${BASE_URI}/memo/favorite-get`;
    const config = await getHeaderConfig(token);

    const { data } = await axios.get(apiUrl, config);
    let dataRes = data;
    dataRes.data = await _.sortBy(dataRes.data, 'index');
    return dataRes;
  } catch (error) {
    await handleAuth(error.response);
    // throw new Error(error);
    console.error(error);
    return error.response;
  }
};

export const getDealByCategory = async (token, category, phone) => {

  try {
    const apiUrl = `${BASE_URI}/inventory/list`;
    const config = await getHeaderConfig(token);
    let payload = {};
    if (category === 'vending') {
      payload = {
        is_vending: true,
        phone,
      };
    } else if (category === '') {
      payload = {
        special_deal: true,
        phone,
      };
    }
    else if (category) {
      payload = {
        category,
        phone,
      };
    }
    //   const payload = {
    //     "limit": 1000,
    //     "is_hotdeal": true,
    //     "is_vending": true
    // };
    const { data } = await axios.post(apiUrl, payload, config);
    let dataRes = data;
    dataRes.data = await _.sortBy(dataRes.data, 'index');
    return dataRes;
  } catch (error) {
    await handleAuth(error.response);
    // throw new Error(error);
    console.error(error);
    return error.response;
  }
};

export const getWalletBalance = async (token) => {
  try {
    const apiUrl = `${BASE_URI}/api/v1/wallet/balance`;
    const config = await getHeaderConfig(token);
    const res = await axios.get(apiUrl, config);
    return res;
  } catch (error) {
    // throw new Error(error);
    await handleAuth(error.response);
    console.error(error);
    return error.response;
  }
};

export const getWalletHistrory = async (token) => {
  try {
    const apiUrl = `${BASE_URI}/wallet/tnx-list`;
    const config = await getHeaderConfig(token);
    const { data } = await axios.get(apiUrl, config);
    // console.log("data", data);
    let dataRes = data;
    dataRes.data = await _.sortBy(dataRes.data, 'tnx_ts').reverse();
    return dataRes;
  } catch (error) {
    // throw new Error(error);
    await handleAuth(error.response);
    console.error(error);
    return error.response;
  }
};

export const getTokenConsent = async () => {
  try {
    const apiUrl = `${PDPA_BASE_URI}/auth/v1/token`;
    const payload = {
      grant_type: 'client_credentials',
      client_id: 'SBD',
      client_secret: `${PDPA_CLIENT_SECRET}`
      // client_secret: "kxYYH9Y6TM",
    };
    const { data } = await axios.post(apiUrl, payload);
    return data;
  } catch (error) {
    // throw new Error(error);
    console.error(error);
    return error.response;
  }
};

const instancePDPA = axios.create({
  baseURL: PDPA_BASE_URI,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }
});
instancePDPA.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('t_c');
    if (token) {
      config.headers['Authorization'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instancePDPA.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const { access_token } = await getTokenConsent();
          localStorage.removeItem('t_c');
          localStorage.setItem('t_c', access_token);
          instancePDPA.defaults.headers.common['Authorization'] = access_token;
          return instancePDPA(originalConfig);
        } catch (_error) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }
          return Promise.reject(_error);
        }
      }
      if (err.response.status === 401 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }
    return Promise.reject(err);
  }
);

export const getSecretConsent = async () => {
  try {
    const apiUrl = `/auth/v1/secret`;
    const { data } = await instancePDPA.get(apiUrl);
    return data;
  } catch (error) {
    // throw new Error(error);
    console.log(error.response);
    return error.response;
  }
};

export const getConsentParties = async (phone) => {
  try {
    const apiUrl = `/arctic/v1/parties/consents`;
    const config = {
      params: {
        entity: 'SBD',
        channel: 'SpeedKub',
        phone: `${phone}`
      }
    };
    const { data } = await instancePDPA.get(apiUrl, config);
    return data;
  } catch (error) {
    // throw new Error(error);
    console.error(error);
    return error;
  }
};

export const getConsent = async (phone, status) => {
  try {
    const apiUrl = `/arctic/v1/consents`;
    const config = {
      params: {
        entity: 'SBD',
        channel: 'SpeedKub',
        status: `${status}`, // Fixed value (“active”)
        phone: `${phone}`
      }
    };
    const { data } = await instancePDPA.get(apiUrl, config);
    return data;
  } catch (error) {
    // throw new Error(error);

    console.error(error);
    return error;
  }
};

export const saveConsent = async (body, secret) => {
  try {
    const apiUrl = `/arctic/v1/parties/consents`;
    console.log('body', body);
    const data_encrypt = await pef1_encrypt(secret, JSON.stringify(body));
    console.log('data_encrypt', data_encrypt);
    const res = await instancePDPA.patch(apiUrl, data_encrypt);
    const data_decrypt = await pef1_decrypt(secret, res?.data);
    return data_decrypt;
  } catch (error) {
    // throw new Error(error);
    // await handleAuth(error);
    console.error(error);
    return error;
  }
};

export const parseJwt = async (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    await Logout();
    localStorage.removeItem('token');
    window.location.assign('/login');
    console.error(error);
  }
};
