import { useTranslation } from 'react-i18next';
import {
  CloseIcon,
  CoinIcon,
  DownloadAndroid,
  DownloadIOS,
  InfoIcon,
  SpeedkubIcon
} from '../assets';

const AboutModal = () => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <input type="checkbox" id="sbm-about" className="modal-toggle" />
        <label
          id="sbm-about"
          className="modal min-h-full cursor-pointer z-[99999]"
          htmlFor="sbm-about"
        >
          <label className="modal-box flex flex-col w-11/12 max-w-5xl relative bg-slate-100 justify-between z-[9999] text-[#171717] px-3">
            <div className="overflow-auto px-3">
              <label htmlFor="sbm-about" className="absolute right-2 top-2">
                <img
                  src={CloseIcon}
                  className="aspect-square w-5 ml-2 cursor-pointer"
                />
              </label>
              <div className="flex flex-row justify-center items-center text-[22px] ">
                <div className="font-kanitBold ">{t('sbm.about.t')}</div>
                <div className="mx-2 text-[#49494A]">{`Speedkub`}</div>
                <img src={SpeedkubIcon} className="aspect-square w-6 " />
              </div>
              <div className="flex flex-row justify-center items-center my-4">
                <img src={CoinIcon} className="aspect-square w-20 flex-none" />
              </div>
              <div className="flex flex-col justify-center items-center  ">
                <div className="font-kanitBold text-[22px] text-[#ED1C24] mb-2">
                  {t('sbm.about.t1')}
                </div>
                <div className="text-[16px] mb-2">{t('sbm.about.t2')}</div>
              </div>
              <div className="flex flex-row justify-center items-center text-[22px]">
                <div className="">{t('sbm.about.download')}</div>
                <div className="mx-2 text-[#49494A]">{`Speedkub`}</div>
                <img src={SpeedkubIcon} className="aspect-square w-6 " />
              </div>
              <div className="flex flex-row justify-center items-center text-[22px] gap-3 mt-3">
                <a
                  href="https://app.adjust.com/10i1i8iv"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={DownloadIOS} className="h-8" />
                </a>
                <a
                  href="https://app.adjust.com/10i1i8iv"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={DownloadAndroid} className="h-8 " />
                </a>
              </div>
              <div className="flex flex-col justify-center items-center  mt-3">
                <div className="text-[16px] mb-2">{t('sbm.about.t3')}</div>
                <div className="text-[16px] text-[#8E93A5] mb-2">
                  {t('sbm.about.t4')}
                </div>
              </div>
            </div>
          </label>
        </label>
      </div>
    </>
  );
};

const PowerBy = () => {
  return (
    <div>
      <div className="flex flex-row justify-center items-center p-3">
        <div className=" text-sm text-[#8E93A5] whitespace-nowrap">
          Powered by{' '}
          <span className="text-[14px] text-[#49494A] font-bold ">
            Speedkub
          </span>
        </div>
        <img src={SpeedkubIcon} className="aspect-square w-4 ml-2" />
        <label htmlFor="sbm-about">
          <img
            src={InfoIcon}
            className="aspect-square w-5 ml-2 cursor-pointer"
          />
        </label>
      </div>
      <AboutModal />
    </div>
  );
};

export default PowerBy;
