import React, { useContext } from "react";
import "./term.scss";
import { useTranslation } from "react-i18next";
import HTMLReactParser from 'html-react-parser';
import { AuthContext } from "../../auth";

const TermAndConditionShow = () => {
    const { t } = useTranslation();
  return (
    <>
      <input type="checkbox" id="term-modal-show" className="modal-toggle"/>
      <div className="modal min-h-full z-[10]">
        <div className="modal-box flex flex-col w-11/12 max-w-5xl">
          <div className="flex modal-open w-full right-2 top-2 text-center">
            <h3 className="font-bold">{t('term.title')}</h3>
            <label
              htmlFor="term-modal-show"
              className="btn btn-sm btn-circle absolute right-2 top-2"
            >
              ✕
            </label>
          </div>
          <div className="overflow-auto mt-4">
          <ContentData t={t} />
          </div>
        </div>
      </div>
    </>
  );
};

const ContentData = ({t}) => {
  return (
    <article className="overflow-hidden text-left">
        {HTMLReactParser(t('term.content'))}
    </article>
  );
};

export default TermAndConditionShow;
