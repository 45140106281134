import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllNews } from '../../api/newsApi';
import { ImageLoading } from '../../components';
import styles from './News.module.scss';

const NewsItem = ({ dataItem }) => {
  const { id, title, thumbnail_image } = dataItem;
  const navigate = useNavigate();

  const onClick = (dataItem) => {
    navigate('/sabuymoney/news-detail', { state: dataItem });
  };

  return (
    <>
      <div
        className={`group rounded-[5px] cursor-pointer ${styles.items_box}`}
        onClick={() => onClick(dataItem)}
      >
        <div className="aspect-[103/58] p-1">
          <ImageLoading
            className="object-cover aspect-[4/3] rounded-[5px]"
            src={thumbnail_image}
            aspectDefault={'4/3'}
          />
          <div className="p-1 text-[#171717]">
            <div className="font-bold flex justify-between">
              <span className="line-clamp-2">{title}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewsItem;
