import { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../auth';
import { AccountInfo, infoUpdate } from '../../services/api';
import LockIcon from '../../assets/icon/lock.png';
// import clsx from "clsx";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserInfoFrom = ({ t, profileMode, profile }) => {
  const [loading, setLoading] = useState(false);
  const { state: stateData } = useLocation();
  const { token, setProfile } = useContext(AuthContext);

  const tokenData = profileMode ? token : stateData?.data?.token;
  const [tokenOtp, setTokenOtp] = useState(tokenData);
  const [phone, setPhone] = useState(stateData?.phone);
  const [isOpen, setIsOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const navigator = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm();

  const [select, setSelect] = useState('');
  const [dobDate, setDobDate] = useState('');
  const [dobDobTime, setDobTime] = useState('00:00:00');

  const onSubmit = async (fromData) => {
    const { firstName, lastName, genderName, birthdayName, emailName } =
      fromData;
    setLoading(true);

    const form = {
      username: phone ?? '',
      name: firstName ?? '',
      surname: lastName ?? '',
      gender: genderName ?? '',
      dob: `${birthdayName}` ?? '',
      // dob: `${birthdayName} ${dobDobTime}` ?? '',
      email: emailName ?? ''
    };
    let res;
    // debugger;
    if (profileMode) {
      res = await infoUpdate(form, token);
    } else {
      res = await infoUpdate(form, tokenOtp);
    }
    if (res.status === 200) {
      setErrorMsg(null);
      if (profileMode) {
        toast.success(t('info_form.update_success'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined
        });
        //Sync profile data
        const { data, status } = await AccountInfo(token);
        if (status === 200) {
          setProfile(data);
        } else {
          setProfile(null);
        }
      } else {
        navigator(`${stateData?.path}`, {
          replace: true,
          state: {
            stateData
          }
        });
      }
    } else {
      const { data } = res;
      setErrorMsg(data?.message ?? '');
    }

    setLoading(false);
  };

  const onSelectChange = (e) => {
    setSelect(e.target.value);
  };

  const onDatePickerChange = (e) => {
    setDobDate(e.target.value);
  };

  const getDateFormat = (dob) => {
    let dobStr = dob?.split(' ') ?? [];
    let hdbDate = '';
    if (dobStr.length > 1) {
      hdbDate = dobStr[0];
      setDobTime(dobStr[1]);
    }
    return hdbDate;
  };

  useEffect(() => {
    if (profile) {
      const { username, name, surname, gender, dob, email } = profile;
      const dateStr = getDateFormat(dob) ?? '';
      setPhone(username);
      setValue('firstName', name);
      setValue('lastName', surname);
      setValue('genderName', gender, { shouldValidate: true });
      setSelect(gender);
      setValue('birthdayName', dateStr);
      setDobDate(dateStr);
      setValue('emailName', email);
    }
  }, [profile, profileMode]);

  console.log('profileMode', profileMode);

  return (
    <>
      <ToastContainer
        bodyClassName="text-left"
        theme="dark"
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
      <div className="h-full md:h-screen flex flex-col items-center pt-5">
        <div className="flex flex-col h-full w-full justify-start items-center">
          <div className="container">
            <p className="text-3xl text-[#E6564E] text-center leading-loose lg:leading-loose">
              {t('info_form.t')}
            </p>
          </div>
          <div className="card flex-shrink-0 w-full max-w-xl mb-24">
            <form className="card-body pb-20" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-control relative">
                <label className="label">
                  <span className="label-text">{t('info_form.first')}</span>
                </label>
                <input
                  readOnly={true}
                  type="text"
                  placeholder={t('info_form.first')}
                  className="input input-bordered border-2 rounded-full btn-outline"
                  {...register('firstName', { disabled: false })}
                />
                <img className="absolute mr-4 w-6 right-0 top-12" src={LockIcon} alt="Lock Icon" />
                {errors.firstName && (
                  <p className="pl-5 text-error text-left text-sm">
                    {t('info_form.first_required')}
                  </p>
                )}
              </div>
              <div className="form-control relative">
                <label className="label">
                  <span className="label-text">{t('info_form.last')}</span>
                </label>
                <input
                  readOnly={true}
                  type="text"
                  placeholder={t('info_form.last')}
                  className="input input-bordered border-2 rounded-full btn-outline"
                  {...register('lastName')}
                />
                <img className="absolute mr-4 w-6 right-0 top-12" src={LockIcon} alt="Lock Icon" />

                {errors.lastName && (
                  <p className="pl-5 text-error text-left text-sm">
                    {t('info_form.last_required')}
                  </p>
                )}
              </div>
              <div className="form-control relative">
                <label className="label">
                  <span className="label-text">{t('info_form.hbd')}</span>
                </label>
                <input
                  readOnly={true}
                  {...register('birthdayName', { disabled: false })}
                  type="date"
                  placeholder="Birthday"
                  className="input input-bordered border-2 rounded-full btn-outline"
                  data={dobDate}
                  onChange={onDatePickerChange}
                />
                <img className="absolute mr-4 w-6 right-0 top-12" src={LockIcon} alt="Lock Icon" />

              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">{t('info_form.gender.t')}</span>
                </label>
                <select
                  defaultValue=""
                  className="select border-2 color rounded-full"
                  data={select}
                  onChange={onSelectChange}
                  {...register('genderName')}
                >
                  <option value="" disabled>
                    {t('info_form.gender.t')}
                  </option>
                  <option value="male">{t('info_form.gender.male')}</option>
                  <option value="female">{t('info_form.gender.female')}</option>
                </select>
              </div>

              <div className="form-control">
                <label className="label">
                  <span className="label-text">{t('info_form.email')}</span>
                </label>
                <input
                  {...register('emailName')}
                  type="text"
                  placeholder={t('info_form.email')}
                  className="input input-bordered border-2 rounded-full btn-outline"
                />
              </div>
              <div className="form-control mt-6">
                {loading ? (
                  <button className="btn btn-primary rounded-full text-white loading">
                    {profileMode ? t('info_form.update') : t('confirm')}
                  </button>
                ) : (
                  <button
                    className="btn btn-primary rounded-full text-white"
                    type="submit"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    {profileMode ? t('info_form.update') : t('confirm')}
                  </button>
                )}
                {/* <Link
                to="/otp?mode=change-email"
                className="btn btn-primary rounded-full text-white"
              >
                {t("confirm")}
              </Link> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserInfoFrom;
