import React from "react";
import { Content, MainContainer, Footer } from "../../layout";
import { useTranslation } from "react-i18next";
import HTMLReactParser from "html-react-parser";
import { useNavigate, useLocation } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import './cookie.scss';
const CookiePage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <MainContainer>
      <Content minHSceen={true}>
        <div className="flex flex-col pt-10 pb-32">
          <div className="flex w-full justify-between py-3 cursor-pointer px-5 mt-auto">
            <div
              className="flex justify-center items-center"
              onClick={() => navigate(-1)}
            >
              <ChevronLeftIcon
                className="aspect-square w-8 "
                aria-hidden="true"
              />
              <p>Back</p>
            </div>
          </div>
          <div className="container">
            <div className="w-full text-center">
              {/* <h3 className="font-bold">{t("term.title")}</h3> */}
              <h3 className="font-bold text-lg">Cookies</h3>
            </div>
            {/* <article className="text-left">
          {HTMLReactParser(t('term.content'))}
          </article> */}
            <article className="pt-5 text-left cookie-content">
              <div>
                <div aria-hidden="true"></div>
                <p>
                  <strong>1. คุกกี้คืออะไร</strong>
                </p>
                <p className="indent-10">
                  คุกกี้ คือ
                  ไฟล์ข้อความขนาดเล็กที่ถูกดาวน์โหลดไปยังอุปกรณ์คอมพิวเตอร์หรืออุปกรณ์มือถือของท่าน
                  ซึ่งทำหน้าที่บันทึกข้อมูลและการตั้งค่าต่าง ๆ เช่น
                  บันทึกข้อมูลการตั้งค่าภาษาในอุปกรณ์บนอุปกรณ์ของท่าน
                  บันทึกสถานะการเข้าใช้งานในปัจจุบันของท่าน
                  เพื่อช่วยให้ท่านสามารถเข้าใช้งานเว็บไซต์และ/หรือแอปพลิเคชัน
                  (รวมเรียกว่า “บริการ”) ได้อย่างต่อเนื่อง
                  รวมถึงมีการรวบรวมข้อมูลเกี่ยวกับประวัติการเข้าใช้งานบริการที่ท่านชื่นชอบในรูปแบบไฟล์
                  โดยคุกกี้ไม่ได้ทำให้เกิดอันตรายต่ออุปกรณ์ของท่าน
                  และเนื้อหาในคุกกี้จะถูกเรียกออกมาดูหรืออ่านได้โดยบริการที่สร้างคุกกี้ดังกล่าวเท่านั้น
                </p>
                <br />
                <p>
                  <strong>2. สบายดิจิตอลใช้คุกกี้อย่างไรบ้าง</strong>
                </p>
                <p className="indent-10">

                  สบายดิจิตอลจะใช้คุกกี้และเทคโนโลยีอื่น ๆ ที่คล้ายกัน
                  เพื่อวัตถุประสงค์ต่าง ๆ ดังนี้
                </p>
                <ul>
                  <li className="indent-8">
                    เพื่อช่วยจดจำข้อมูลเกี่ยวกับเบราว์เซอร์และการตั้งค่าของท่าน
                    และช่วยให้ท่านเข้าใช้บริการเว็บไซต์ได้อย่างต่อเนื่อง
                    และเพิ่มประสบการณ์และความพึงพอใจในการใช้บริการ
                  </li>
                  <li className="indent-8">
                    เพื่อช่วยประเมินประสิทธิภาพและผลการให้บริการเว็บไซต์ที่ยังทำงานได้ไม่ดีและควรปรับปรุง&nbsp;
                  </li>
                  <li className="indent-8">
                    เพื่อรวบรวมและวิเคราะห์ข้อมูลการเข้าเยี่ยมชมและใช้บริการเว็บไซต์
                    ที่จะทำให้ สบายดิจิตอลเข้าใจว่าผู้คนมีความสนใจอะไร
                    และมีการใช้บริการของสบายดิจิตอลอย่างไร
                  </li>
                  <li className="indent-8">
                    เพื่อให้
                    สบายดิจิตอลได้ส่งมอบประสบการณ์ในการใช้บริการเว็บไซต์ที่ดียิ่งขึ้นให้กับท่าน
                    รวมถึงช่วยให้
                    สามารถส่งมอบบริการและการประชาสัมพันธ์ได้ตรงตามสิ่งที่ท่านสนใจ
                  </li>
                </ul>

                <p>
                  ทั้งนี้ บริการต่าง ๆ
                  ของสบายดิจิตอลอาจให้บริการแยกอยู่ในหลายเว็บไซต์
                  โดยในแต่ละเว็บไซต์อาจมีการใช้คุกกี้เพื่อวัตถุประสงค์ข้อใดข้อหนึ่งหรือหลายข้อรวมกัน
                  โดยท่านสามารถดูได้จากรายละเอียดของประเภทคุกกี้ที่มีการใช้งานบนเว็บไซต์นั้น
                </p>
                <br />
                <p>
                  <strong>3.สบายดิจิตอลใช้คุกกี้อะไรบ้าง</strong>
                </p>

                <p className="indent-10">
                  เว็บไซต์สบายดิจิตอลมีการใช้คุกกี้ทั้งของสบายดิจิตอล (First party
                  cookies) และคุกกี้ที่เป็นของบุคคลอื่น (Third party cookies)
                  ซึ่งกำหนดและตั้งค่าโดยผู้ให้บริการบุคคลภายนอก เช่น
                  บริษัทภายนอกที่สบายดิจิตอลใช้บริการ
                  เพื่อเพิ่มเติมคุณสมบัติของการทำงานให้กับเว็บไซต์ของสบายดิจิตอล
                </p>

                <p>
                  คุกกี้ ที่สบายดิจิตอลใช้ อาจแบ่งได้ ๒ ประเภทตามการจัดเก็บ
                  คือ&nbsp;
                </p>

                <ul className="list-disc indent-10">
                  <li>
                    Session Cookies
                    เป็นคุกกี้ที่จะอยู่ชั่วคราวเพื่อจดจำท่านในระหว่างที่ท่านเข้าเยี่ยมชมเว็บไซต์ของสบายดิจิตอล
                    เช่น เฝ้าติดตามภาษาที่ท่านได้ตั้งค่าและเลือกใช้ เป็นต้น
                    และจะมีการลบออกจากเครื่องคอมพิวเตอร์หรืออุปกรณ์ของท่าน
                    เมื่อท่านออกจากเว็บไซต์หรือได้ทำการปิดเว็บเบราว์เซอร์&nbsp;
                  </li>
                  <li>
                    Persistent Cookie
                    เป็นคุกกี้ที่จะอยู่ตามระยะเวลาที่กำหนดหรือจนกว่าท่านจะลบออก
                    คุกกี้ประเภทนี้จะช่วยให้เว็บไซต์ของสบายดิจิตอลจดจำท่านและการตั้งค่าต่าง
                    ๆ ของท่านเมื่อท่านกลับมาใช้บริการเว็บไซต์อีกครั้ง
                    ซึ่งจะช่วยให้ท่านเข้าใช้บริการเว็บไซต์ได้สะดวกรวดเร็วยิ่งขึ้น&nbsp;
                  </li>
                </ul>
                <p>
                  และหากพิจารณาตามวัตถุประสงค์การใช้งานสบายดิจิตอลมีการนำคุกกี้มาใช้
                  ๔ ประเภท ดังนี้
                </p>
                <p className="indent-10">
                  1. คุกกี้ที่จำเป็นต่อการให้บริการ (Strictly Necessary
                  Cookies)&nbsp;
                  คุกกี้ประเภทนี้มีความจำเป็นต่อการให้บริการเว็บไซต์ของสบายดิจิตอลเพื่อให้ท่านสามารถเข้าใช้งานในส่วนต่าง
                  ๆ ของเว็บไซต์ได้
                  รวมถึงช่วยจดจำข้อมูลที่ท่านเคยให้ไว้ผ่านเว็บไซต์
                  การปิดการใช้งานคุกกี้ประเภทนี้จะส่งผลให้ท่านไม่สามารถใช้บริการในสาระสำคัญของสบายดิจิตอลซึ่งจำเป็นต้องเรียกใช้คุกกี้ได้
                </p>
                <p className="indent-10">
                  2.&nbsp; คุกกี้เพื่อการวิเคราะห์และประเมินผลใช้งาน
                  (Performance Cookies)
                  คุกกี้ประเภทนี้ช่วยให้สบายดิจิตอลเห็นการปฏิสัมพันธ์ของผู้ใช้งานในการใช้บริการเว็บไซต์ของสบายดิจิตอล
                  รวมถึงหน้าเพจหรือพื้นที่ใดของเว็บไซต์ที่ได้รับความนิยม
                  ตลอดจนการวิเคราะห์ข้อมูลด้านอื่น ๆ
                  สบายดิจิตอลยังใช้ข้อมูลนี้เพื่อการปรับปรุงการทำงานของเว็บไซต์
                  และเพื่อเข้าใจพฤติกรรมของผู้ใช้งาน อย่างไรก็ดี
                  ข้อมูลที่คุกกี้นี้เก็บรวบรวมจะเป็นข้อมูลที่ไม่สามารถระบุตัวตนได้
                  และนำมาใช้วิเคราะห์ทางสถิติเท่านั้น
                  การปิดการใช้งานคุกกี้ประเภทนี้จะส่งผลให้สบายดิจิตอลไม่สามารถทราบปริมาณผู้เข้าเยี่ยมชมเว็บไซต์
                  และไม่สามารถประเมินคุณภาพการให้บริการได้
                </p>
                <p className="indent-10">
                  3. คุกกี้เพื่อการใช้งานเว็บไซต์ (Functional Cookies)
                  คุกกี้ประเภทนี้จะช่วยให้เว็บไซต์ของ สบายดิจิตอลจดจำตัวเลือกต่าง ๆ
                  ที่ท่านได้ตั้งค่าไว้และช่วยให้เว็บไซต์ส่งมอบคุณสมบัติและเนื้อหาเพิ่มเติมให้ตรงกับการใช้งานของท่านได้
                  เช่น ช่วยจดจำชื่อบัญชีผู้ใช้งานของท่าน
                  หรือจดจำการเปลี่ยนแปลงการตั้งค่าขนาดฟอนต์หรือการตั้งค่าต่าง ๆ
                  ของหน้าเพจซึ่งท่านสามารถปรับแต่งได้
                  การปิดการใช้งานคุกกี้ประเภทนี้อาจส่งผลให้เว็บไซต์ไม่สามารถทำงานได้อย่างสมบูรณ์
                </p>
                <p className="indent-10">
                  4. คุกกี้เพื่อการโฆษณา (Targeting Cookies)
                  คุกกี้ประเภทนี้เป็นคุกกี้ที่เกิดจากการเชื่อมโยงเว็บไซต์ของบุคคลที่สาม
                  ซึ่งเก็บข้อมูลการเข้าใช้งานและเว็บไซต์ที่ท่านได้เข้าเยี่ยมชม
                  เพื่อนำเสนอสินค้าหรือบริการบนเว็บไซต์อื่นที่ไม่ใช่เว็บไซต์ของ
                  สบายดิจิตอลทั้งนี้
                  หากท่านปิดการใช้งานคุกกี้ประเภทนี้จะไม่ส่งผลต่อการใช้งานเว็บไซต์ของสบายดิจิตอล
                  แต่จะส่งผลให้การนำเสนอสินค้าหรือบริการบนเว็บไซต์อื่น ๆ
                  ไม่สอดคล้องกับความสนใจของท่าน
                </p>
                <p>
                  ดังที่ได้แจ้งไว้ข้างต้น บริการต่าง ๆ
                  ของสบายดิจิตอลอาจมีการให้บริการแยกอยู่ในหลายเว็บไซต์
                  ดังนั้นในแต่ละเว็บไซต์อาจเลือกใช้ประเภทคุกกี้ที่แตกต่างกันตามความเหมาะสมและจำเป็นต่อการให้บริการนั้น
                  ๆ ซึ่งสบายดิจิตอลจะแสดงข้อมูลและรายละเอียดคุกกี้แต่ละประเภท
                  รวมถึงชื่อคุกกี้ที่มีการใช้งานบนเว็บไซต์หน้าแรกของแต่ละบริการ
                  โดยเป็นข้อความแบนเนอร์แจ้งการใช้คุกกี้ และมีลิงก์ (การตั้งค่า)
                  ที่ท่านสามารถคลิกเข้าไปอ่านรายละเอียดของคุกกี้แต่ละประเภทที่มีการใช้งานบนบริการนั้นได้
                </p>
                <br />
                <p>
                  <strong>4.วิธีปิดคุกกี้</strong>
                </p>
                <p className="indent-10">
                  ท่านสามารถปิดการทำงานของคุกกี้ได้
                  โดยการตั้งค่าเบราว์เซอร์ของท่าน
                  และตั้งค่าความเป็นส่วนตัวเพื่อระงับการรวบรวมข้อมูลโดยคุกกี้ในอนาคต
                  (รายละเอียดเพิ่มเติมสามารถศึกษาได้จาก&nbsp;
                  <a href="http://aboutcookies.org/">AboutCookies.org</a>)
                </p>
                <p>
                  อย่างไรก็ตาม บริการบางอย่างบนเว็บไซต์จำเป็นต้องมีการใช้คุกกี้
                  หากท่านปิดการทำงานคุกกี้อาจทำให้ท่านใช้งานฟังก์ชันบางอย่างหรือทั้งหมดของบริการดังกล่าวได้อย่างไม่ราบรื่น
                </p>
                <br />
                <p>
                  <strong>5.การเชื่อมโยงไปยังเว็บไซต์ของบุคคลภายนอก</strong>
                </p>
                <p className="indent-10">
                  เว็บไซต์ของสบายดิจิตอล
                  อาจมีลิงก์ที่เชื่อมโยงไปยังเว็บไซต์หรือโซเชียลมีเดียของบุคคลภายนอก
                  รวมถึงอาจมีการฝังเนื้อหาหรือวีดีโอที่มาจากโซเชียลมีเดีย เช่น
                  YouTube หรือ Facebook เป็นต้น
                  ซึ่งจะช่วยให้ท่านเข้าถึงเนื้อหาและสร้างปฏิสัมพันธ์กับบุคคลอื่นบนโซเชียลมีเดียจากเว็บไซต์ของสบายดิจิตอล
                  ได้
                  ซึ่งเว็บไซต์หรือโซเชียลมีเดียของบุคคลภายนอกจะมีการกำหนดและตั้งค่าคุกกี้ขึ้นมาเอง
                  โดยที่สบายดิจิตอลไม่สามารถควบคุมหรือรับผิดชอบต่อคุกกี้เหล่านั้นได้
                  และขอแนะนำให้ท่านควรเข้าไปอ่านและศึกษานโยบายหรือประกาศการใช้คุกกี้ของบุคคลภายนอกเหล่านั้นด้วย
                </p>
                <br />
                <p>
                  <strong>6.การเปลี่ยนแปลงประกาศ</strong>
                </p>
                <p className="indent-10">
                  ประกาศนี้อาจมีการปรับปรุงเป็นครั้งคราวเพื่อความเหมาะสมและสอดคล้องกับสถานการณ์ที่อาจมีการเปลี่ยนแปลง
                  และสบายดิจิตอลจะมีการแจ้งประกาศที่มีการปรับปรุงใหม่บนเว็บไซต์นี้สบายดิจิตอลขอให้ท่านหมั่นเข้ามาตรวจสอบเว็บไซต์นี้อย่างสม่ำเสมอ
                </p>
                <br />
                <p>
                  <strong>7.ติดต่อสบายดิจิตอล</strong>
                </p>
                <p>
                  บริษัท สบาย ดิจิตอล จำกัด
                  <br />
                  230 ถ. บางขุนเทียน-ชายทะเล แขวงแสมดำ เขตบางขุนเทียน
                  กรุงเทพมหานคร 10150
                  <br />
                  Call Center : 02-451-5335
                </p>
              </div>
            </article>
          </div>
        </div>
      </Content>
    </MainContainer>
  );
};
export default CookiePage;
