import { useEffect, useState, createContext, useContext } from 'react';
import * as walletApi from '../api/walletApi';
import { getToken } from '../utils/localStorage';
import _ from 'lodash';
import { useSystem } from './SystemContext';

const WalletContext = createContext();

function WalletContextProvider({ children }) {
  // const [wallet, setWallet] = useState([]);
  // const [earn, setEarn] = useState([]);
  // const [burn, setBurn] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [transaction, setTransaction] = useState([]);

  const [hasMore, setHasMore] = useState(true);
  const [tab, setTab] = useState('total');
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);

  const { reloadConfig } = useSystem();

  useEffect(() => {
    const fetchWallet = async () => {
      try {
        setInitialLoading(true);
        await reloadConfig();
        if (getToken()) {
          await getWallet(tab);
        }
      } catch (err) {
        // console.log('window.location.href', window.location.href);
        // console.log(err);
      } finally {
        setInitialLoading(false);
      }
    };
    fetchWallet();
  }, []);

  const getWallet = async (type) => {
    try {
      setInitialLoading(true);
      const res = await walletApi.getWalletWallet(type, 1, limit);
      const data = res.data.data.data ?? []
      const dataSort = await _.sortBy(res.data.data.data, 'created_at').reverse();
      // const earnCurrent = [];
      // const burnCurrent = [];
      if (data.length < limit) {
        setHasMore(false)
      }else{
        setHasMore(true)
      }
      // if (data.length > 0) {
        // for (const item of data) {
        //   if (type === 'burn') {
        //     burnCurrent.push(item);
        //   } else {
        //     earnCurrent.push(item);
        //   }
        // }
      // }

      // setWallet(res.data.data);
      setTransaction(dataSort)
      setPage(res.data.data?.page);
      // setBurn(burnCurrent);
      // setEarn(earnCurrent);
    } catch (err) {
      const { pathname, search } = window.location;
      window.location.replace(pathname + search);
    } finally {
      setInitialLoading(false);
    }
  };

  const loadMore = async () => {
    try {
      const res = await walletApi.getWalletWallet(tab, page + 1, limit);
      const data = res.data.data.data ?? []
      // const dataWallet = tab === 'burn' ? burn : earn;
      const allData = [...transaction, ...(res.data.data.data ?? [])];
      const dataSort = await _.sortBy(allData, 'created_at').reverse();
      if (data < limit) {
        setHasMore(false)
      }else{
        setHasMore(true)
      }
      setTransaction(dataSort)
      setPage(res.data.data?.page);

      // const earnCurrent = [];
      // const burnCurrent = [];

      // if (data.length > 0) {
      //   for (const item of data) {
      //     if (tab === 'burn') {
      //       burnCurrent.push(item);
      //     } else {
      //       earnCurrent.push(item);
      //     }
      //   }
      // }
      // setWallet(res.data.data);
      // setBurn(burnCurrent);
      // setEarn(earnCurrent);
      // if (res.data.data.data === null) {
      //   setHasMore(false);
      // }
    } catch (err) {
      console.log('err', err);
    } finally {
    }
  };

  return (
    <WalletContext.Provider
      value={{
        initialLoading,
        getWallet,
        // wallet,
        // earn,
        // burn,
        loadMore,
        tab,
        setTab,
        hasMore,
        transaction
      }}
    >
      {children}
    </WalletContext.Provider>
  );
}

export const useWallet = () => {
  return useContext(WalletContext);
};

export default WalletContextProvider;
