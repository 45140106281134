import { useContext, useEffect, useState } from 'react';
import { Content, MainContainer, Footer } from '../../layout';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../auth';
import { FAIL, ROUTES } from '../../constant';
import { Alert } from '../../components';

import QrIcon from '../../assets/login/qr.png';
import AppIcon from '../../assets/login/icon-app.png';
import DownLoadIcon from '../../assets/login/download.png';
import Qrcode from '../../assets/qr_download-new.png';

import './login.scss';
import PasswordInput2 from '../../components/form/password/password2';
import { useRegister } from '../../RegisterContext';


const LoginPage = () => {
  const { t } = useTranslation();
  const {
    user,
    login,
    token,
    isLogin,
    setConsentData,
    setConsentDataDashboard,
    phoneNumber,
    setPhoneNumber,
    profile
  } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { resetAllData } = useRegister()

  const handleSubmit = async (event) => {
    setLoading(true);
    setConsentData(undefined);
    setConsentData(undefined);
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let userForm = formData.get('user');
    let passForm = formData.get('pass');
    await login(userForm, passForm);

    setLoading(false);
  };

  useEffect(() => {
    console.log('isLogin', isLogin);
    const callApi = async () => {
      if (isLogin) {
        resetAllData();
        console.log('profile', profile);
        const phone = profile?.username;
        setPhoneNumber(phone);
        navigate(ROUTES.DASHBOARD, { replace: true });
      }
    };
    callApi();
  }, [user, token, navigate, isLogin]);

  return (
    <MainContainer>
      {!isLogin ? (
        <>
          <div className="bg-login grow">
            <Content minHSceen={true}>
              <div className=" flex flex-col justify-center items-center ">
                <div className="container flex justify-center">
                  <p className="text-3xl text-[#E6564E] text-center leading-loose lg:leading-loose">
                    {t('login.t')}
                  </p>
                </div>
                <Link to="/register" className="flex my-5 gap-2 flex-wrap justify-center">
                  <div className="text-[16px]">{t('login.has_reg')}</div>
                  <div className='text-[16px] underline font-bold'>{t('login.register')}</div>
                </Link>
                <div className="card w-11/12 xs:w-full max-w-lg bg-[#38383C] mb-10">
                  <form
                    className="card-body p-4 xs:p-6"
                    name="loginfrom"
                    onSubmit={handleSubmit}
                  >
                    {user && user?.type === FAIL ? (
                      <Alert text={user?.data?.message} />
                    ) : null}
                    <div className="form-control">
                      <label className="label">
                        <span className="label-text">{t('info.phone')}</span>
                      </label>
                      <input
                        name="user"
                        autoComplete="phone"
                        type="text"
                        placeholder={t('info.phone')}
                        data-lpignore="true"
                        className='custom-input'
                      />
                    </div>
                    <div className="form-control relative">
                      <label className="label">
                        <span className="label-text">{t('login.password')}</span>
                      </label>
                      <PasswordInput2
                        name="pass"
                        placeholder={t('login.password')}
                      />
                    </div>
                    {/* <div className=" flex justify-end ">
                      <label className="label">
                        <Link
                          to="/forgot"
                          className="label-text-alt link link-hover"
                        >
                          {t('login.forgot')}
                        </Link>
                      </label>
                    </div> */}
                    <div className="form-control">
                      <div className="flex justify-center">
                        <button
                          type="submit"
                          className={` w-fit px-7 py-1 btn btn-primary rounded-full ${loading ? 'loading' : ''
                            }`}
                        >
                          {t('login.t')}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <label className="label cursor-pointer">
                  <input
                    type="checkbox"
                    id="register-sidebarLogin"
                    className="modal-toggle"
                    htmlFor="register-sidebarLogin"
                  />
                  <div className="modal">
                    <div className="modal-box relative grid justify-items-center">
                      <label
                        htmlFor="register-sidebarLogin"
                        className="btn btn-sm btn-circle absolute right-2 top-2"
                      >
                        ✕
                      </label>
                      <h3 className="text-lg font-bold mb-2">
                        {t('scan_qr.ms')}
                      </h3>
                      <img src={Qrcode} alt="" />
                    </div>
                  </div>
                  <div className="flex flex-col justify-center w-full max-w-sm mb-5">
                    <div>{t('download_t')}</div>
                    <div className="grid grid-cols-3 gap-5">
                      <div>
                        <div className="aspect-square">
                          <img src={AppIcon} alt="AppIcon" />
                        </div>
                      </div>
                      <div>
                        <div className="aspect-square p-4">
                          <img src={QrIcon} alt="QrIcon" />
                        </div>
                      </div>
                      <div className='flex items-center'>
                        <div className="aspect-[13/9]">
                          <img src={DownLoadIcon} alt="download" />
                        </div>
                      </div>
                    </div>
                  </div>
                </label>
              </div>
            </Content>
          </div>
          <Footer />
        </>
      ) : null}
    </MainContainer>
  );
};
export default LoginPage;
