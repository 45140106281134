import { useTranslation } from 'react-i18next';
import LogoWeb from '../assets/logo.png';
// import Promotion from '../assets/pro/pro-04-2023.jpg';
import DownloadApp from '../assets/download.png';
import GooglePlay from '../assets/google_play.png';
import AppStore from '../assets/app_store.png';

import { useEffect, useRef, useState } from 'react';
import { HidePopup, GetPopup, SavePopup } from '../utils';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

// import required modules
import { Autoplay, Navigation, Thumbs } from 'swiper';
import { useLocation, useNavigate } from 'react-router-dom';
import PromotionSlider from './promotion/promotion-slider';

const PopupDownloadApp = ({ idx = 'modal-download-app', autoShow = false }) => {
  const { t } = useTranslation();

  const modalRef = useRef(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);


  useEffect(() => {
    const initModal = () => {
      if (autoShow && GetPopup()) {
        modalRef.current.click();
        HidePopup();
      }
    };
    initModal();
  }, []);

  useEffect(() => {
    window.onbeforeunload = function () {
      SavePopup('open');
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <>
      <label htmlFor={idx} className="cursor-pointer">
        <input
          ref={modalRef}
          type="checkbox"
          id={idx}
          className="modal-toggle"
        />
        <div className="modal ">
          <div className="modal-box relative w-11/12 max-w-5xl lg:max-h-[95vh] max-h-[80vh] m-5 shadow-xl">
            <div className="flex flex-col lg:justify-center lg:items-center md:justify-center md:items-center h-full w-full">
              <div className="mb-5 flex justify-center items-center">
                <img src={LogoWeb} className="w-9/12 md:w-60 lg:w-60" />
              </div>
              <div className="flex justify-center items-center flex-wrap pb-3">
                <PromotionSlider />
                {/* <div className="w-1/1 lg:w-1/2 md:w-1/2 p-2">
                  <img
                    alt="promotion"
                    src={'https://s3.speedkub.io/assets/popup-promotion.jpg'}
                  />
                </div> */}
                <div className="w-1/1 lg:w-1/2 md:w-1/2 flex flex-col justify-center items-center">
                  <h3 className="text-lg text-center font-bold lg:mb-2 md:mb-2 mt-6">
                    {t('scan_qr.ms')}
                  </h3>
                  <div className='my-5'>
                    <img alt="qrcode" src={'https://s3.speedkub.io/assets/qr-code.jpg'} />
                  </div>
                  <div className="w-9/12 justify-center items-center mt-4">
                    <a
                      href="https://app.adjust.com/10i1i8iv"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="flex flex-row w-full">
                        <div className="float-left">
                          <img src={GooglePlay} alt="download-app-android" />
                        </div>
                        <div className="float-left">
                          <img src={AppStore} alt="download-app-android" />
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </label>
    </>
  );
};

export default PopupDownloadApp;
