import { useTranslation } from 'react-i18next';
import Footer from '../layout/footer/Footer';
import Header from '../layout/header/Header';
import ProfileContainer from '../features/Profile/ProfileContainer';
const ProfilePage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header title={t('line.profile')} showBalance />
      <ProfileContainer />
      <Footer />
    </>
  );
};
export default ProfilePage;
