import { useState, createContext, useContext } from 'react';
import { useSystem } from './SystemContext';
import { ConsentSetup } from '../utils/pdpa';
import dayjs from 'dayjs';
import { saveConsent } from '../api/pdpaApi';
import { registerStep1, registerStep2 } from '../api/authApi';

const RegisterContext = createContext();

function RegisterContextProvider({ children }) {
  const [initialLoading, setInitialLoading] = useState(true);

  const [policyAccept, setPolicyAccept] = useState(false);
  const [showPolicy, setShowPolicy] = useState(false);

  const [termAccept, setTermAccept] = useState(false);
  const [showTerm, setShowTerm] = useState(false);

  const [pdpa, setPdpa] = useState();
  const [phone, setPhone] = useState();
  const [secret, setSecret] = useState();

  const conectPdpa = async (phone) => {
    const res = await ConsentSetup(phone);
    setPhone(phone);
    console.log('res', res);
    const { result, encryption_secret } = res;
    setSecret(encryption_secret);
    setPdpa({
      ...res,
      step1: result?.find((i) => i.id === 'CONSENT_CS22005'),
      step2: result?.find((i) => i.id === 'CONSENT_CS22006')
    });
  };

  const savePdpa = async (item, action) => {
    try {
      const requestData = {
        phone: `${phone}`,
        entity: 'SBD',
        consent: [
          {
            uri: item?.content_url,
            value: action,
            consent_created: item?.created_at,
            consent_updated: dayjs().toISOString(),
            consent_id: item?.id,
            channel: 'SpeedKub'
          }
        ]
      };
      await saveConsent(requestData, secret);
      console.log('requestData', requestData);
    } catch (error) {}
  };

  const register1 = async (data) => {
    try {
      console.log('data', data);
      localStorage.setItem('line_p', data?.password);
      const payload = {
        phone: data?.username,
        referrer_by: data?.refer ? data?.refer : null
      };
      return await registerStep1(payload);
    } catch (error) {
      throw error.response.data.message;
    }
  };

  const register2 = async (data) => {
    try {
      console.log('data', data);
      localStorage.setItem('line_p', data?.password);
      const payload = {
        username: data?.username,
        password: data?.password,
        ref_code: data?.ref_code,
        pin: data?.pin,
        register_from: 'line'
      };
      return await registerStep2(payload);
    } catch (error) {
      console.error('error registerStep2', error);
      throw error.response.data.message;
    }
  };

  const resentOTP = async (data) => {
    try {
      const payload = {
        phone: data?.phone,
        referrer_by: data?.refer ? data?.refer : null
      };
      return await registerStep1(payload);
    } catch (error) {
      console.error('error resentOTP', error);
      throw error.response.data.message;
    }
  };

  return (
    <RegisterContext.Provider
      value={{
        initialLoading,
        policyAccept,
        setPolicyAccept,
        termAccept,
        setTermAccept,
        showPolicy,
        setShowPolicy,
        showTerm,
        setShowTerm,
        pdpa,
        conectPdpa,
        savePdpa,
        register1,
        register2,
        resentOTP
      }}
    >
      {children}
    </RegisterContext.Provider>
  );
}

export const useRegister = () => {
  return useContext(RegisterContext);
};

export default RegisterContextProvider;
