import React, { useContext, useEffect, useState } from 'react';
import { Content, MainContainer, Footer } from '../../layout';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { SwitchConsentPDPA } from '../../layout/term/pdpa';
import { AuthContext } from '../../auth';
import { getAllConsent } from '../../utils';

const PdpaPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    token,
    consentData,
    setConsentData,
    setConsentDataDashboard,
    initProfile
  } = useContext(AuthContext);
  const [phoneNumber, setPhoneNumber] = useState();
  const [isFetch, setIsFetch] = useState(false);

  useEffect(() => {
    const callApi = async () => {
      if (isFetch === false && phoneNumber) {
        setConsentDataDashboard(undefined);
        let res = await getAllConsent(phoneNumber);
        const dataFilter = res?.consent.filter(
          (i) => i.consent_id === 'CONSENT_CS22002'
        );
        res.consent = dataFilter;
        setConsentData(res);
        setIsFetch(true);
      }
    };
    const initPhoneNumber = async () => {
      const profileRes = await initProfile(token);
      setPhoneNumber(profileRes?.username.replace('+66', '0'));
    };
    initPhoneNumber();
    callApi();
  }, [setConsentData, consentData, phoneNumber, isFetch]);

  return (
    <MainContainer hideMenu={true}>
      <Content minHSceen={true}>
        <div className="flex flex-col pt-10 pb-32">
          <div className="flex w-full justify-between py-3 cursor-pointer px-5 mt-auto">
            <div
              className="flex justify-center items-center"
              onClick={() => navigate(-1)}
            >
              <ChevronLeftIcon
                className="aspect-square w-8 "
                aria-hidden="true"
              />
              <p>Back</p>
            </div>
          </div>

          <div className="flex w-full flex-col justify-between py-3 cursor-pointer px-5 mt-auto">
            <ul className="menu bg-base-100 w-full p-2">
              {consentData &&
                consentData?.consent.map((item, index) => {
                  return (
                    <li key={index}>
                      <label
                        className="flex justify-between items-center container"
                        htmlFor={item?.consent_id}
                      >
                        <p>{item?.name}</p>
                        <ChevronRightIcon
                          className="aspect-square w-8 "
                          aria-hidden="true"
                        />
                      </label>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </Content>
      {phoneNumber ? <SwitchConsentPDPA phone={phoneNumber} /> : null}
    </MainContainer>
  );
};
export default PdpaPage;
