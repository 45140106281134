import { LogoFull, IconSuccess, Token } from '../../assets';

import { Link } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { t } from 'i18next';
import { numberWithCommas } from '../../utils/number';

const KycSuccess = () => {
  let [searchParams] = useSearchParams();

  return (
    <>
      <div className="flex flex-col w-full h-screen gap-5 pb-5">
        <div className="flex justify-center items-center my-4">
          <img src={LogoFull} className="h-8" alt="logo" />
        </div>
        <div className=" bg-[#222225] p-4 rounded-lg mx-4 flex flex-col gap-3">
          <div className="flex justify-center">
            <img src={IconSuccess} className=" h-28" alt="logo" />
          </div>
          <div className="text-white font-bold text-[20px] ">
            {t('line.kyc.success')}
          </div>
          <div className="bg-[#4D4D52] px-6 py-3 h-full w-full rounded-full flex justify-center text-[16px] font-kanitLight text-white gap-2">
            <div className="">{t('line.kyc.give')}</div>
            {numberWithCommas(Number(searchParams?.get('reward'))) ?? 0}
            <img src={Token} className="h-6" alt="logo" />
            <div>{t('line.kyc.from')}</div>
          </div>
          {/* */}
        </div>
        <div className=" pb-5 px-4">
          <Link
            to={'/line/profile'}
            className=" btn bg-[#E6564E] text-white btn-ghost hover:bg-[#E6564E] h-full w-full rounded-full flex  gap-3"
          >
            {t('line.kyc.back')}
          </Link>
        </div>
      </div>
    </>
  );
};
export default KycSuccess;
