import DealContextProvider from '../contexts/DealContext';
import DealContainerDetail, {
  ModelMain
} from '../features/Home/DealContainerDetail';
import Container from '../layout/Container';
import Footer from '../layout/footer/Footer';
import Header from '../layout/header/Header';

const DealDetailPage = () => {
  return (
    <>
      <div className=" h-screen">
        <Header showBalance={true} showBack={true} />
        <div className="h-[80vh] overflow-x-hidden overflow-y-auto scroll-ui">
          <DealContextProvider>
            <Container>
              <DealContainerDetail />
            </Container>
            <ModelMain />
          </DealContextProvider>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default DealDetailPage;
