import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { openInNewTab } from '../../utils/fileOpen';
import dayjs from 'dayjs';
import { useAuth } from '../../contexts/AuthContext';
import { ErrorIcon, LogoFull } from '../../assets';
import { useNavigate } from 'react-router-dom';
import { useSystem } from '../../contexts/SystemContext';

const ConfirmCancelMembership = ({ t }) => {
  const navigate = useNavigate();
  const { cancelUser } = useAuth();
  const { resetSystem } = useSystem();
  const closeModal = () => {
    document.getElementById('confirm-cancel-member').click();
  };

  const onActionCancelUser = async () => {
    const isCancel = await cancelUser();
    console.log('isCancel', isCancel);
    if (isCancel) {
      document.getElementById('confirm-cancel-member').click();
      await resetSystem();
      return navigate('/line?page=deal');
    }
  };

  return (
    <div>
      <input
        type="checkbox"
        id="confirm-cancel-member"
        className="modal-toggle"
      />
      <label
        id="modal-confirm-cancel-member"
        className="modal min-h-full cursor-pointer z-[99999]"
        htmlFor="confirm-cancel-member"
      >
        <label className="modal-box flex flex-col w-11/12 max-w-5xl relative bg-[#4D4D52] justify-between z-[9999] text-white">
          <div className="overflow-auto">
            <div className="flex modal-open w-full right-2 top-2 text-center">
              <button
                onClick={closeModal}
                className="btn btn-sm btn-circle bg-transparent border-0 absolute right-2 top-2"
              >
                ✕
              </button>
            </div>
            <div className="card card-side rounded-none my-4 flex-col justify-center items-center">
              <figure className="flex flex-col w-full justify-center items-center">
                <img
                  className="h-28 w-full object-contain rounded-xl"
                  src={ErrorIcon}
                  alt="require kyc"
                />
              </figure>
              <div className="py-5 w-full">
                <p className="text-lg font-medium text-center text">
                  {t('cancel_user.title')}
                </p>
                <p className="text-lg font-medium text-center text">
                  {t('cancel_user.sub')}
                </p>
              </div>
              <button
                onClick={onActionCancelUser}
                className="btn rounded-full text-white bg-[#E6564E] border-0 w-full"
              >
                {t('line.confirm')}
              </button>
              <button
                onClick={closeModal}
                className="w-full mt-4 underline text-slate-300"
              >
                {t('line.cancel')}
              </button>
            </div>
          </div>
        </label>
      </label>
    </div>
  );
};

const ProfileContainer = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <>
      <div className="flex flex-col w-full gap-5 pb-5">
        <div className=" bg-[#222225] p-4 rounded-lg mx-4 flex flex-col gap-3">
          <div className="flex flex-col justify-start w-full">
            <label className="text-left font-normal">
              {user?.profile?.name} {user?.profile?.surname}
            </label>
          </div>
          <div className="flex flex-col justify-start w-full">
            <label className="text-left font-normal">{user?.username}</label>
          </div>
          <div className="flex flex-col justify-start w-full">
            <label className="text-left font-normal">
              {`${t('line.info.registerAt')} : ${dayjs
                .unix(user?.registered_at)
                .format('DD MMM YYYY HH:mm')}`}
            </label>
          </div>
        </div>
        {user?.is_kyc ? null : (
          <div className="px-4">
            <Link
              to="/line/kyc"
              className=" btn bg-[#4D4D52] text-white btn-ghost hover:bg-[#4D4D52] h-full w-full rounded-full flex flex-col gap-3"
            >
              {t('line.info.kyc')}
            </Link>
          </div>
        )}
        <div className="bg-[#222225] p-4 rounded-lg mx-4 grow">
          <div className="flex flex-col justify-start w-full gap-3">
            <div className="text-left font-kanit font-bold">
              {t('line.info.helpcenter')}
            </div>
            <Link
              className="link link-hover text-left font-kanitLight"
              to="/about"
            >
              {t('about')}
            </Link>
            <Link
              className="link link-hover text-left font-kanitLight"
              to="https://www.facebook.com/SabuyDigital"
            >
              {t('footter.contact_me')}
            </Link>
            <Link
              className="link link-hover text-left font-kanitLight"
              to="/term"
            >
              {t('line.info.term_and_condition')}
            </Link>
            <Link
              className="link link-hover text-left font-kanitLight"
              onClick={openInNewTab}
            >
              {t('line.info.privacy_policy')}
            </Link>
            <label
              className="link link-hover text-left font-kanitLight"
              htmlFor="confirm-cancel-member"
            >
              {t('line.info.logout')}
            </label>
          </div>
        </div>

        <div className="flex justify-center items-center my-4">
          <img src={LogoFull} className="h-8" alt="logo" />
        </div>
      </div>
      <ConfirmCancelMembership t={t} />
    </>
  );
};
export default ProfileContainer;
