import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import TimeOutIcon from '../../assets/time-out.png';
import Barcode from 'react-barcode';

const QRCodeGenerator = ({ t, value, qrTimeOut, hideBarCode, hideQrCode }) => {
  const elementRef = useRef(null);
  const [width, setWidth] = useState(elementRef.current ?? 0);

  const getSize = (w) => {
    return w - (w * 10) / 100;
  };

  // Update 'width' and 'height' when the window resizes
  useEffect(() => {
    // This function calculates width and height of the list
    const getListSize = () => {
      if (elementRef.current) {
        const w = getSize(elementRef.current.clientWidth);
        if (w < 576) {
          setWidth(w);
        } else {
          setWidth(576);
        }
      }
    };
    window.addEventListener('resize', getListSize);
  }, []);

  useLayoutEffect(() => {
    if (elementRef.current) {
      const w = getSize(elementRef.current.clientWidth);

      if (w < 576) {
        setWidth(w);
      } else {
        setWidth(576);
      }
    }
  }, []);

  return (
    <div
      ref={elementRef}
      className="container w-full flex flex-col justify-center mx-auto"
    >
      {qrTimeOut ? (
        <div className={`my-5`}>
          <div className="w-full text-center text-lg text-black">
            {t('redeem.out')}
          </div>
          <img
            width={width}
            className="h-48 w-full m-auto object-contain md:h-full md:w-48 rounded-xl"
            src={TimeOutIcon}
            alt="timeout"
          />
        </div>
      ) : (
        <>
          {hideQrCode ? null : (
            <div className="flex justify-center w-full">
              <QRCodeSVG
                value={value}
                size={width}
                bgColor={'#ffffff'}
                fgColor={'#000000'}
                level={'L'}
              />
            </div>
          )}
          {hideBarCode ? null : (
            <div className="flex justify-center w-full my-5 px-2">
              <Barcode value={value} format={'CODE128'} displayValue={false} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default QRCodeGenerator;
