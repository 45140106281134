import axios from 'axios';

export const getSystemInfo = () => {
  const baseUrl = 'https://utils.speedkub.io';
  const path = 'config';
  let name = 'speedkub_sbm_dev';
  const REACT_APP_PROJECT_NAME = process.env.REACT_APP_PROJECT_NAME;
  if (REACT_APP_PROJECT_NAME === 'speedkub-prod') {
    name = 'speedkub_sbm_prod';
  }
  return axios.get(`${baseUrl}/${path}/${name}`);
};
