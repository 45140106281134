import { createContext, useContext, useState } from 'react';

import _ from 'lodash';
import dayjs from 'dayjs';

import {
  HeartFilter,
  ExpiredFilter,
  DescendingFilter,
  NotSort,
  MaxSort,
  MinSort
} from '../../assets/filter';

const rawFilter = [
  {
    id: 1,
    name: 'all',
    value: 'all',
    sortType: 'desc',
    icon: HeartFilter
  },
  {
    id: 2,
    name: 'expire',
    value: 'expire',
    sortType: 'desc',
    icon: NotSort
  },
  {
    id: 3,
    name: 'descending',
    value: 'descending',
    sortType: 'asc',
    icon: NotSort
  }
];

const FavoriteContext = createContext();

function FavoriteContextProvider({ children }) {
  const [favoriteList, setFavoriteList] = useState([]);
  const [filterList, setFilterList] = useState(rawFilter);
  const [filterActive, setFilterActive] = useState(rawFilter[0]);

  const clearDataFavorite = () => {
    setFavoriteList([]);
    setFilterList([rawFilter]);
    setFilterActive(rawFilter[0]);
  };

  const addFieldDateUnix = (favoriteDataList) => {
    if (favoriteDataList) {
      return favoriteDataList.map((item) => {
        const parseDate = item?.expire.replace(/(\d+[/])(\d+[/])/, '$2$1'); // change 'DD/MM/YYYY' to 'MM/DD/YYYY'
        return {
          ...item,
          date_unix: dayjs(new Date(parseDate)).unix()
        };
      });
    }
    return [];
  };

  const initFavoriteList = (favoriteDataList) => {
    const mapDataList = addFieldDateUnix(favoriteDataList);
    setFavoriteList(mapDataList);
  };

  const getIconPath = (item) => {
    let path = '';
    if (item.value === 'all') {
      path = item.icon;
    } else {
      path = item.sortType === 'desc' ? MinSort : MaxSort;
    }
    return path;
  };

  const handleFavorite = (item, favorite) => {
    const currentActive = {
      ...item,
      sortType: item.sortType === 'desc' ? 'asc' : 'desc',
      icon: getIconPath(item)
    };

    let newArr = [...favorite];

    if (item.value === 'expire') {
      newArr = _.orderBy([...favorite], 'date_unix', currentActive.sortType);
    } else if (item.value === 'descending') {
      newArr = _.orderBy([...favorite], 'price', currentActive.sortType);
    } else if (item.value === 'all') {
      setFavoriteList(newArr);

      console.log(favorite);
    }

    // Update filter Active
    setFilterActive(currentActive);
    // Update favorite list by Sort filter
    setFavoriteList(newArr);
  };

  return (
    <FavoriteContext.Provider
      value={{
        filterActive,
        setFilterActive,
        favoriteList,
        setFavoriteList,
        initFavoriteList,
        handleFavorite,
        filterList,
        setFilterList,
        clearDataFavorite
      }}
    >
      {children}
    </FavoriteContext.Provider>
  );
}

export const useFavorite = () => {
  return useContext(FavoriteContext);
};

export default FavoriteContextProvider;
