import { useEffect, useState, createContext, useContext } from 'react';
import * as authApi from '../api/authApi';
import * as walletApi from '../api/walletApi';

import {
  addPhoneStorage,
  getLineTokenStorage,
  getToken
} from '../utils/localStorage';
import { useSystem } from './SystemContext';
import liff from '@line/liff';

const AuthContext = createContext();

function AuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [initialLoading, setInitialLoading] = useState(true);
  const [profileLineLiff, setProfileLineLiff] = useState();
  const { initialLoading: systemLoading } = useSystem();

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        if (getToken()) {
          await reloadUserInfo();
        }
      } catch (err) {
        // removeLocalStorage();
        // const { pathname } = window.location;
        // window.location.replace(pathname);
      } finally {
        setInitialLoading(false);
      }
    };
    if (!systemLoading) {
      fetchUserInfo();
    }
  }, [systemLoading]);

  const getUserInfo = async () => {
    const res = await authApi.getAccountInfo();
    const data = res.data.data;
    setUser(data);
    setPhoneNumber(data.username);
    addPhoneStorage(data.username);
  };

  const getWalletBalance = async () => {
    const res = await walletApi.getWalletBalance();
    setBalance(res.data.data);
  };

  const login = async (data) => {
    try {
      const lineToken = getLineTokenStorage();
      console.log('getLineIDToken()', lineToken);

      if (lineToken) {
        const res = await authApi.LoginWithLine(data);
        const { token, refresh_token } = res?.data?.data;
        console.log('res', res);
        console.log('customToken:', token);
        console.log('refresh_token', refresh_token);
        if (token) {
          localStorage.setItem('line_t', token);
          localStorage.setItem('line_t_r', refresh_token);
          await reloadUserInfo();
        } else {
          localStorage.removeItem('line_t');
          localStorage.removeItem('line_t_r');
        }

        return res?.data;
      }
    } catch (error) {
      console.log('error message', error.response.data.message);
      throw error.response.data.message;
    } finally {
    }
  };

  const getProfileLineLiff = async () => {
    const res = await liff.getProfile();
    console.log('res', res);
    setProfileLineLiff(res);
  };

  const reloadUserInfo = async () => {
    if (getToken()) {
      await getUserInfo();
      await getWalletBalance();
      await getProfileLineLiff();
    }
  };

  const cancelUser = async () => {
    let action = false;
    try {
      const res = await authApi.cancelUserMember();
      console.log('cancelUserMember res', res);
      const { status } = res?.data;
      console.log('status', status);
      if (status === 200) {
        localStorage.removeItem('line_t');
        localStorage.removeItem('line_t_r');
        localStorage.removeItem('line_id_t');

        action = true;
      }
    } catch (error) {
      console.log('error message', error.response.data.message);
      action = false;
      throw error.response.data.message;
    } finally {
      return action;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        initialLoading,
        user,
        phoneNumber,
        balance,
        getWalletBalance,
        login,
        reloadUserInfo,
        profileLineLiff,
        cancelUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthContextProvider;
