import { LoadingIcon } from '../assets';

const LoadingScreen = () => {
  return (
    <>
      <div className="flex justify-center items-center h-screen w-screen">
        <img src={LoadingIcon} className="w-44" alt="loading-page" />
      </div>
    </>
  );
};
export default LoadingScreen;
