import React from 'react';
import { Spinner } from './components';
import { useAuth } from './contexts/AuthContext';
import { useLoading } from './contexts/LoadingContext';
import RouterApp from './route/routerApp';
import { useSystem } from './contexts/SystemContext';
import MaintenanceMode from './MaintenanceMode';

const App = () => {
  const { loading } = useLoading();
  const { initialLoading: initialLoadingSystem, maintenance } = useSystem();
  const { initialLoading } = useAuth();

  if (initialLoading || initialLoadingSystem) return <Spinner />;
  if (maintenance.is_maintenance) return <MaintenanceMode />;

  return (
    <>
      {loading && <Spinner />}
      <RouterApp />
    </>
  );
};
export default App;
