import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import SabuyMoneyMain from './sabuymoney/SabuyMoneyMainApp';
import SpeedKubApp from './speedkub/SpeedKubApp';
import { SavePopup } from './speedkub/utils';
import LineMainApp from './line/LineMainApp';

const App = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  if (location.pathname.includes('/line')) {
    import(`./line/index.scss`);
    import(`./line/features/Home/hot-deal/hot-deal-sbm.scss`);
    const lang = searchParams.get('lang') ?? '';

    const token = searchParams.get('token') ?? '';
    if (token) {
      localStorage.setItem('line_t', token);
    }

    const refresh_token = searchParams.get('refresh_token') ?? '';
    if (refresh_token) {
      localStorage.setItem('line_t_r', refresh_token);
    }

    if (lang) {
      localStorage.setItem('line_lang', lang);
    } else {
      localStorage.setItem('line_lang', 'th');
    }

    return <LineMainApp />;
  }

  if (location.pathname.includes('/sabuymoney')) {
    import(`./sabuymoney/index.scss`);
    import(`./sabuymoney/features/Home/hot-deal/hot-deal-sbm.scss`);
    const token = searchParams.get('token') ?? '';
    const lang = searchParams.get('lang') ?? '';

    if (token) {
      localStorage.setItem('sbm_t', token);
    }

    if (lang) {
      localStorage.setItem('sbm_lang', lang);
    } else {
      localStorage.setItem('sbm_lang', 'th');
    }

    return <SabuyMoneyMain />;
  }
  import(`./speedkub/index.scss`);
  import(`./speedkub/components/hot-deal/hot-deal.scss`);
  return <SpeedKubApp />;
};
export default App;
