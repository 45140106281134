import { useEffect, useState, createContext, useContext } from 'react';
import { registerStep1, registerStep2 } from './services/api';
const RegisterContext = createContext();

function RegisterContextProvider({ children }) {

  const [phone, setPhone] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [refCode, setRefcode] = useState();
  const [pin, setPin] = useState();
  const [referrerBy, setReferrerBy] = useState();

  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    const initRegister = async () => {
      try {

      } catch (err) {
        // removeLocalStorage();
        // const { pathname } = window.location;
        // window.location.replace(pathname);
      } finally {
        setInitialLoading(false);
      }
    };
    initRegister();
  }, []);

  // const getUserInfo = async () => {
  //   const res = await authApi.getAccountInfo();
  //   const data = res.data.data;
  //   setUser(data);
  //   setPhoneNumber(data.username);
  //   addPhoneStorage(data.username);
  // };

  // const getWalletBalance = async () => {
  //   const res = await walletApi.getWalletBalance();
  //   setBalance(res.data.data);
  // };

  const resetAllData = () => {
    setPhone(undefined);
    setUsername(undefined);
    setPassword(undefined);
    setRefcode(undefined);
    setPin(undefined);
    setReferrerBy(undefined);
  }

  const getLocalAll = () => {
    const phoneLocal = localStorage.getItem('r_ph') ?? '';
    const passLocal = localStorage.getItem('r_ps') ?? '';
    const referLocal = localStorage.getItem('r_refer') ?? '';
    return { phone: phoneLocal, pass: passLocal, referrerBy: referLocal }
  }

  const registerActionStep1 = async (phone, pass, ref) => {
    try {
      setInitialLoading(true);

      localStorage.setItem('r_ph', phone);
      localStorage.setItem('r_ps', pass);
      localStorage.setItem('r_refer', ref);

      const res = await registerStep1(phone);
      const { ref_code } = res.data;
      setPhone(phone);
      setPassword(pass);
      setReferrerBy(ref);
      setRefcode(ref_code);

      // localStorage.setItem('r_refcode', ref);
      return res;
    } catch (err) {
      console.error(err);
    } finally {
      setInitialLoading(false);
    }
  }

  const resendOtpRegister = async (phone) => {
    try {
      setInitialLoading(true);

      localStorage.setItem('r_ph', phone);

      const res = await registerStep1(phone);
      const { ref_code } = res.data;
      setPhone(phone);
      setRefcode(ref_code);

      // localStorage.setItem('r_refcode', ref);
      return res;
    } catch (err) {
      console.error(err);
    } finally {
      setInitialLoading(false);
    }
  }

  const registerActionStep2 = async (refCodeData, pinData) => {
    try {
      setInitialLoading(true);

      const { phone, pass, referrerBy } = getLocalAll();

      let payload = {
        "username": phone,
        "password": pass,
        "ref_code": refCodeData,
        "pin": pinData,
        "referrer_by": referrerBy ?? ''
      };


      const res = await registerStep2(payload);
      // const { ref_code } = res.data;
      debugger;
      setReferrerBy(referrerBy);
      setRefcode(refCodeData);
      setPin(pinData);

      // localStorage.setItem('r_refcode', ref);
      return res;
    } catch (err) {
      console.error(err);
    } finally {
      setInitialLoading(false);
    }
  }

  return (
    <RegisterContext.Provider
      value={{
        initialLoading,
        phone,
        username,
        password,
        refCode,
        pin,
        referrerBy,
        resetAllData,
        registerActionStep1,
        registerActionStep2,
        resendOtpRegister
      }}
    >
      {children}
    </RegisterContext.Provider>
  );
}

export const useRegister = () => {
  return useContext(RegisterContext);
};

export default RegisterContextProvider;
