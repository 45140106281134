import React, { useContext, useState } from 'react';
import { HomeIcon, GiftIcon, UserIcon } from '@heroicons/react/outline';
import {
  HomeIcon as HomeIconSolid,
  GiftIcon as GiftIconSolid,
  UserIcon as UserIconSolid
} from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import './dashboardmenu.scss';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../auth';

const DashBoardMenu = () => {
  const { t } = useTranslation();
  const { cookiePolicy } = useContext(AuthContext);
  return (
    <>
      {/* Desktop */}
      <div className="navbar w-full bg-[#F2F2F2] shadow-xl sticky top-16 z-[65] hidden lg:flex">
        <MenuContent t={t} />
      </div>
      {/* Mobile */}
      <div className="navbar w-screen bg-[#F2F2F2] shadow-xl fixed bottom-0 z-[65] lg:hidden sm:flex md:flex">
        <MenuContent t={t} />
      </div>
      {cookiePolicy ? null : (
        <>
          {/* <div className="navbar w-screen justify-center bg-[#F2F2F2] shadow-xl fixed bottom-0 z-[65] hidden lg:flex">
            <CookieMenu/>
          </div>
          <div className="navbar justify-center box-cookie w-screen bg-[#F2F2F2] shadow-xl fixed z-[65] lg:hidden sm:flex md:flex">
            <CookieMenu/>
          </div> */}
        </>
      )}
    </>
  );
};

const MenuContent = ({ t }) => {
  // const [activeMenu, setActiveMenu] = useState("home");
  const { dashMenu, setDastMenu } = useContext(AuthContext);
  return (
    <div className="btn-group w-full no-animation flex flex-row justify-between">
      <Link
        to="/dashboard"
        className="btn btn-ghost flex flex-col justify-center nav-menu w-1/3"
        onClick={() => setDastMenu('home')}
      >
        {dashMenu === 'home' ? (
          <HomeIconSolid
            className="aspect-square w-6 active"
            aria-hidden="true"
          />
        ) : (
          <HomeIcon className="aspect-square w-6" aria-hidden="true" />
        )}
        <span className="mt-2">{t('dash_menu.home')}</span>
      </Link>
      <Link
        to="/rewards"
        className="btn btn-ghost flex flex-col justify-center nav-menu w-1/3"
        onClick={() => setDastMenu('gift')}
      >
        {dashMenu === 'gift' ? (
          <GiftIconSolid
            className="aspect-square w-6 active"
            aria-hidden="true"
          />
        ) : (
          <GiftIcon className="aspect-square w-6" aria-hidden="true" />
        )}
        <span className="mt-2">{t('dash_menu.rewards')}</span>
      </Link>
      <Link
        to="/profile"
        className="btn btn-ghost flex flex-col justify-center nav-menu w-1/3"
        onClick={() => setDastMenu('user')}
      >
        {dashMenu === 'user' ? (
          <UserIconSolid
            className="aspect-square w-6 active"
            aria-hidden="true"
          />
        ) : (
          <UserIcon className="aspect-square w-6" aria-hidden="true" />
        )}
        <span className="mt-2">{t('dash_menu.profile')}</span>
      </Link>
    </div>
  );
};

export default DashBoardMenu;
