import React, { useEffect, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

import styles from '../Home.module.scss';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

// import required modules
import { Autoplay, Navigation, Thumbs } from 'swiper';
import { useLocation, useNavigate } from 'react-router-dom';
import { CoinIcon, SpeedLogoGray } from '../../../assets';
import { useDeal } from '../../../contexts/DealContext';
import { numberWithCommas } from '../../../utils/number';
import { useTranslation } from 'react-i18next';

const PriceDeal = ({ item }) => {
  return (
    <div className="absolute bottom-2 right-0">
      <div className={`${styles.p_box} mx-4`}>
        <span>{numberWithCommas(item?.deal?.price)}</span>
        <div className="aspect-square w-5">
          <img src={CoinIcon} className="object-contain" alt={item.id} />
        </div>
      </div>
    </div>
  );
};

const HotDealItem = ({ item }) => {
  const navigator = useNavigate();
  const findBannerImage = (item) => {
    const { thumbnail_image } = item;
    if (thumbnail_image === '') {
      if (item.deal?.banner_image) {
        return item.deal.banner_image;
      }

      if (item.deal?.thumbnail_image) {
        return item.deal.thumbnail_image;
      }

      if (item.news?.thumbnail_image) {
        return item.news.thumbnail_image;
      }
    }
    return thumbnail_image;
  };

  const [urlImage, setUrlImage] = useState([]);
  useEffect(() => {
    const loadImage = async () => {
      let listImage = [];
      let urlFromFirebase;
      let bannerUrl = findBannerImage(item);
      if (bannerUrl) {
        urlFromFirebase = bannerUrl;
        listImage.push(urlFromFirebase);
      }
      setUrlImage(listImage);
    };
    loadImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickToDetail = (item) => {
    console.log('item', item);
    if (item.id) {
      if (item.destination_type === 'deal') {
        navigator('/line/deal-detail', { state: item.deal });
      } else if (item.destination_type === 'news') {
        navigator('/line/news-detail', { state: item.news });
      }
    }
  };

  return (
    <>
      {urlImage.length > 0 ? (
        <div
          className="aspect-video w-full"
          onClick={() => clickToDetail(item)}
        >
          <img
            src={urlImage[0]}
            className="object-cover rounded"
            alt={item?.id}
          />
          {item.destination_type === 'deal' ? <PriceDeal item={item} /> : null}
        </div>
      ) : (
        <div
          className="px-3 aspect-video w-full rounded-[10px] bg-[#D3D3D3]"
          onClick={() => clickToDetail(item)}
        >
          <div className="px-3 image-logo-gray-sbm">
            <img
              className="object-contain"
              src={SpeedLogoGray}
              alt={item?.id}
            />
          </div>
          {item.destination_type === 'deal' ? <PriceDeal item={item} /> : null}
        </div>
      )}
    </>
  );
};

const HotDealBanner = () => {
  const { t } = useTranslation();

  const { hotDealList, initialHotDealLoading } = useDeal();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  if (initialHotDealLoading) {
    return <div>Loading</div>;
  }

  // when cannot has data deal
  if (hotDealList?.length === 0) {
    return <></>;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className=" text-[#E6564E] text-[18px] font-bold px-4 text-left">
        {t('line.deal.title')}
      </div>
      <div className="w-full h-full flex flex-col justify-center items-center ">
        <div className="rounded w-full">
          {hotDealList && hotDealList.length > 0 ? (
            <>
              <Swiper
                loop={true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false
                }}
                slidesPerView={1.2}
                centeredSlides={true}
                spaceBetween={10}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[Thumbs, Autoplay, Navigation]}
                breakpoints={{
                  640: {
                    slidesPerView: 1.2,
                    spaceBetween: 10
                  },
                  768: {
                    slidesPerView: 1.9,
                    spaceBetween: 10
                  },
                  1024: {
                    slidesPerView: 2.1,
                    spaceBetween: 10
                  }
                }}
                className="swiper-content-sbm"
              >
                {hotDealList ? (
                  hotDealList.map((item) => {
                    return (
                      <SwiperSlide key={item.id}>
                        <HotDealItem item={item} />
                      </SwiperSlide>
                    );
                  })
                ) : (
                  <div className="flex flex-col justify-center items-center mt-10">
                    <div>No result not found</div>
                  </div>
                )}
              </Swiper>

              <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={hotDealList ? hotDealList.length : 0}
                className="bullet-swiper-sbm"
              >
                {hotDealList ? (
                  hotDealList.map((item) => {
                    return (
                      <SwiperSlide key={item.id}>
                        <div className="thumb-slider"></div>
                      </SwiperSlide>
                    );
                    //
                  })
                ) : (
                  <div></div>
                )}
              </Swiper>
            </>
          ) : (
            <>Loading</>
          )}
        </div>
      </div>
    </div>
  );
};
export default HotDealBanner;
