import { useEffect, useState, createContext, useContext } from 'react';
import * as newsApi from '../api/newsApi';
import { getToken, getPhoneStorage } from '../utils/localStorage';
import { useSystem } from './SystemContext';

const NewsContext = createContext();

function NewsContextProvider({ children }) {
  const [newsList, setNewsList] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  // const [limit, setLimit] = useState(100);
  // const [offset, setOffset] = useState(0);

  const { reloadConfig } = useSystem();

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        setInitialLoading(true);
        await reloadConfig();
        if (getToken()) {
          await getNews();
        }
      } catch (err) {
        console.log(err);
      } finally {
        setInitialLoading(false);
      }
    };
    fetchUserInfo();
  }, []);

  const getNews = async () => {
    const phone = getPhoneStorage();
    const res = await newsApi.getAllNews(phone);
    const data = res.data.data.data ?? [];
    setNewsList(data);
  };

  return (
    <NewsContext.Provider
      value={{
        initialLoading,
        newsList
      }}
    >
      {children}
    </NewsContext.Provider>
  );
}

export const useNews = () => {
  return useContext(NewsContext);
};

export default NewsContextProvider;
