import { useTranslation } from 'react-i18next';
import DealContextProvider from '../contexts/DealContext';
import HomeContainer from '../features/Home/HomeContainer';
import Footer from '../layout/footer/Footer';
import Header from '../layout/header/Header';

const HomePage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header showBalance search />
      {/* <Container> */}
      <div className="h-[80vh] overflow-x-hidden overflow-y-auto scroll-ui">
        <DealContextProvider>
          <HomeContainer />
        </DealContextProvider>
      </div>
      {/* </Container> */}
      <Footer />
    </>
  );
};
export default HomePage;
