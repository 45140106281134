import axios from 'axios';

export const getSystemInfo = () => {
  const baseUrl = 'https://utils.speedkub.io';
  const path = 'config';
  let name = 'speedkub_dev';
  const REACT_APP_PROJECT_NAME = process.env.REACT_APP_PROJECT_NAME;
  if (REACT_APP_PROJECT_NAME === 'speedkub-prod') {
    name = 'speedkub_prod';
  }
  return axios.get(`${baseUrl}/${path}/${name}`);
};

export const getConnectSpeedkubWithLineToken = (lineToken) => {
  const axiosLineOA = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_URI
  });

  const apiKey = process.env.REACT_APP_BACKEND_API_KEY;
  const path = 'api/v1/authen/line';
  axiosLineOA.defaults.headers.common['Authorization'] = `Bearer ${lineToken}`;
  axiosLineOA.defaults.headers.common['api-key'] = apiKey;

  return axiosLineOA.post(`/${path}`);
};
