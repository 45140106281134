import React, { useContext, useEffect } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import NavBar, { ReferMenu } from '../navbar';
import { PopupDownloadApp, SwitchLang } from '../../components';
import TermAndCondition from '../term';
import DashBoardMenu from '../dashboardmenu';
import { AuthContext } from '../../auth';
import { Link } from 'react-router-dom';
import TermAndConditionShow from '../term/termShow';
import { GetPopup, SavePopup, openInNewTab } from '../../utils';
import { AccountInfo } from '../../services/api';
import Qrcode from '../../assets/qr_download-new.png';
import packageJson from '../../../../package.json';
import { useLocation } from 'react-router-dom';

const toggleDrawer = () => {
  document.getElementById('my-drawer').click();
};

const MainContainer = ({ hideMenu, children, term, justify }) => {
  const location = useLocation();

  const { t } = useTranslation();
  const { logout, isLogin, token, setUpProfile, profile } =
    useContext(AuthContext);
  const justifyStyle = isLogin ? 'justify-start' : 'justify-between';

  useEffect(() => {
    const loadProfile = async () => {
      const { data, status } = await AccountInfo(token);
      if (status === 200) {
        setUpProfile(data);
      } else {
        setUpProfile(null);
      }
    };
    if (profile == null && isLogin) {
      loadProfile();
    }
  }, [profile, setUpProfile]);


  const checkPopUpFormPath = (pathLocation) => {
    const { pathname } = pathLocation;
    const pathHidePopup = ['/register', '/login', '/otp', '/userinfo', '/kycinfo', '/dashboard', '/about', '/refer'];
    const hasLock = pathHidePopup.find(pathHide => pathHide === pathname);
    if (hasLock) {
      return false;
    }

    return true;
  }

  return (
    <>
      <div className="drawer h-screen drawer-end font-notosansthai ">
        <input id="my-drawer" type="checkbox" className="drawer-toggle" />
        <div className={`drawer-content flex flex-col ${justifyStyle}`}>
          <NavBar
            // hideMenu={hideMenu}
            isLogin={isLogin}
            t={t}
            profile={profile}
          />
          {isLogin && !hideMenu ? <DashBoardMenu /> : null}
          {children}
        </div>
        {/* Mobile Nav menu */}
        <div className="drawer-side">
          <label htmlFor="my-drawer" className="drawer-overlay"></label>
          <ul className="menu p-4 overflow-y-auto w-80 bg-[#49494A] text-base-content justify-between">
            <div>
              <div className="px-4 py-3">
                <div className="flex justify-center items-center shrink-0">
                  <SwitchLang />
                  <XIcon
                    className="block h-6 w-6 ml-auto"
                    aria-hidden="true"
                    onClick={toggleDrawer}
                  />
                </div>
              </div>
              {isLogin ? (
                <>
                  <li>
                    <div className="text-white focus:bg-transparent active:bg-transparent w-full">
                      <ReferMenu t={t} profile={profile} />
                    </div>
                    <Link to="/refer" className="text-white">
                      {t('refer_menu')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" className="text-white">
                      {t('about')}
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/setpassword?mode=change" className="text-white">
                      {t('forgot.t_new')}
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/term" className="text-white">
                      {t('term_t')}
                    </Link>
                  </li>
                  <li>
                    <label onClick={openInNewTab} className="text-white">
                      {t('privacy')}
                    </label>
                  </li>
                  {/* <li>
                    <Link to="/pdpa" className="text-white">
                      {t('pdpa.personal')}
                    </Link>
                  </li> */}
                  <li onClick={() => logout()}>
                    <span className="text-white">{t('logout')}</span>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <Link to="/about" className="text-white">
                      {t('about')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/register" className="text-white">
                      {t('register')}
                    </Link>
                  </li>
                  {/* <li>
                    <a
                      className="text-white"
                      href="https://app.adjust.com/10i1i8iv"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('download_app')}
                    </a>
                  </li> */}
                  <li>
                    {/Android|webOS|iPhone|iPad/i.test(navigator.userAgent) ? (
                      <a
                        href="https://app.adjust.com/10i1i8iv"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="text-white">{t('download_app')}</div>
                      </a>
                    ) : (
                      <label className="cursor-pointer">
                        <div className="text-white">{t('download_app')}</div>
                        <input
                          type="checkbox"
                          id="register_sidebar"
                          className="modal-toggle"
                          htmlFor="register_sidebar"
                        />
                        <div className="modal">
                          <div className="modal-box relative grid justify-items-center">
                            <label
                              htmlFor="register_sidebar"
                              className="btn btn-sm btn-circle absolute right-2 top-2"
                            >
                              ✕
                            </label>
                            <h3 className="text-lg font-bold mb-2">
                              {' '}
                              {t('scan_qr.ms')}
                            </h3>
                            <img src={Qrcode} />
                          </div>
                        </div>
                      </label>
                    )}
                  </li>
                  <li>
                    <Link to="/login" className="text-white">
                      {t('login.t')}
                    </Link>
                  </li>
                  <li>
                    <label htmlFor="term-modal-show" className="text-white">
                      {t('term.title')}
                    </label>
                  </li>
                  <li>
                    <label onClick={openInNewTab} className="text-white">
                      {t('privacy')}
                    </label>
                  </li>
                </>
              )}
            </div>
            <div>
              <li>
                <label className="text-white">{`${t('version')} : ${packageJson.version
                  }`}</label>
              </li>
            </div>
          </ul>
        </div>
      </div>
      <PopupDownloadApp idx="auto-pro-popup" autoShow={checkPopUpFormPath(location)} />
      {/* {term ? <TermAndCondition /> : null} */}
      {isLogin ? null : (
        <>
          <TermAndCondition />
          <TermAndConditionShow />
        </>
      )}
    </>
  );
};

export default MainContainer;
