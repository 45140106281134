import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import TimeOutWhiteIcon from '../assets/time-out-white.png';
import Barcode from 'react-barcode';

const QRCodeGenerator = ({ t, value, qrTimeOut, hideBarCode, hideQrCode }) => {
  const elementRef = useRef(null);
  const [width, setWidth] = useState(elementRef.current ?? 0);

  const getSize = (w) => {
    return w - (w * 50) / 100;
  };

  // Update 'width' and 'height' when the window resizes
  useEffect(() => {
    // This function calculates width and height of the list
    const getListSize = () => {
      if (elementRef.current) {
        const w = getSize(elementRef.current.clientWidth);
        if (w < 576) {
          setWidth(w);
        } else {
          setWidth(288);
        }
      }
    };
    window.addEventListener('resize', getListSize);
  }, []);

  useLayoutEffect(() => {
    if (elementRef.current) {
      const w = getSize(elementRef.current.clientWidth);

      if (w < 576) {
        setWidth(w);
      } else {
        setWidth(460);
      }
    }
  }, []);

  return (
    <div
      ref={elementRef}
      className="container w-full flex flex-col justify-center mx-auto"
    >
      {qrTimeOut ? (
        <div className={`my-5`}>
          <div className="w-full text-center text-lg">
            {t('redeem.out')}
          </div>
          <img
            width={width}
            className="h-48 w-full mt-4 m-auto object-contain md:h-full md:w-48 rounded-xl"
            src={TimeOutWhiteIcon}
            alt="timeout"
          />
        </div>
      ) : (
        <>
          {hideQrCode ? null : (
            <div className="flex justify-center w-full">
              <div className='bg-white p-4 rounded-xl'>
                <QRCodeSVG
                  value={value}
                  size={width}
                  bgColor={'#ffffff'}
                  fgColor={'#000000'}
                  level={'L'}
                />
              </div>
            </div>
          )}
          {hideBarCode ? null : (
            <div className="flex justify-center !w-4/5 mx-auto mt-5">
              <div className="rounded-lg bg-white p-1">
                <Barcode value={value} format={'CODE128'} displayValue={false} height={70} />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default QRCodeGenerator;
