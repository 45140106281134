const AUTH_TOKEN = 'sbm_t';
const LANGUAGE = 'sbm_lang';
const PHONE = 'sbm_phone';

export const getLocalStorage = () => localStorage.getItem(AUTH_TOKEN);
export const addLocalStorage = (token) =>
  localStorage.setItem(AUTH_TOKEN, token);
export const removeLocalStorage = () => localStorage.removeItem(AUTH_TOKEN);

export const getLangStorage = () => localStorage.getItem(LANGUAGE);

export const getPhoneStorage = () => localStorage.getItem(PHONE);
export const addPhoneStorage = (phone) => localStorage.setItem(PHONE, phone);
