import { useTranslation } from 'react-i18next';
import { dealCategory } from '../../config/filter_deal';
import { useAuth } from '../../contexts/AuthContext';
import { useDeal } from '../../contexts/DealContext';
import styles from './Home.module.scss';

const FilterItems = ({ isActive, item, t }) => {
  const { filterDeal } = useDeal();
  const { phoneNumber } = useAuth();

  const onClick = (cat, phoneNumber) => {
    filterDeal(cat, phoneNumber);
  };

  return (
    <div
      className={`${isActive ? styles.f_box_active : styles.f_box}`}
      onClick={() => onClick(item.value, phoneNumber)}
    >
      <div>
        <img
          src={isActive ? item.iconActive : item.icon}
          className="aspect-square w-5 flex-none"
          alt='filter'
        />
      </div>
      <span className={`${isActive ? 'text-[#ffffff]' : 'text-[#E6564E]'}`}>
        {t(`filter.${item.name}`)}
      </span>
    </div>
  );
};

const FilterDeal = () => {
  const { t } = useTranslation();
  const { category } = useDeal();
  return (
    <div className={`${styles.filter} mt-6`}>
      {dealCategory.map((item, keys) => {
        return (
          <FilterItems
            item={item}
            key={keys}
            t={t}
            isActive={category === item.value}
          />
        );
      })}
    </div>
  );
};
export default FilterDeal;
