import React, { useState } from "react";
import "./otp.scss";
import { cn } from "../../utils/css";

const OtpInput = ({ name, valueData, size }) => {
  const [value, setValue] = useState("");
  const [length] = useState(size);

  const handleInput = (e) => {
    const inputAll = document.querySelectorAll(".otp-data input");
    const input = e.target;
    const v = Array.from(Array(length), (element, i) => {
      return inputAll[i].value || "";
    }).join("");

    setValue(v);

    if (input.nextElementSibling && input.value) {
      input.nextElementSibling.focus();
      input.nextElementSibling.select();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData.getData("text");
    const paste = clipboardData.replace(/\D/g, '');
    const inputAll = document.querySelectorAll(".otp-data input");
    const inputs = Array.from(Array(length));
    inputs.forEach((element, i) => {
      inputAll[i].value = paste[i];
    });
    setValue(paste);
  };

  const handleBackspace = (e) => {
    const key = e.keyCode || e.charCode;

    // Handle number and backspace only
    // regex number 0-9 and numpad 0-9
    if (key !== 8 && !/4[8-9]|5[0-7]|9[6-9]|10[0-5]/.test(key)) {
      console.log('gokey')
      e.preventDefault();
    }

    if (key === 8) {
      const inputAll = document.querySelectorAll(".otp-data input");
      for (let i = 0; i < inputAll.length; i++) {
        if (inputAll[i] === e.target) {
          if (i > 0 && !e.target.value) {
            inputAll[i - 1].focus();
          }
        }
      }
    } else {
      e.target.select();
    }
  };

  return (
    <div className="flex justify-center gap-2 otp-data">
      {Array.from(Array(size), (e, i) => (
        <input
          useref={i}
          key={i}
          onInput={handleInput}
          onPaste={handlePaste}
          onKeyDown={handleBackspace}
          type="number"
          maxLength="1"
          className={cn('w-full max-w-[50px] aspect-square rounded-lg border-2 text-center border-[#E6564E] text-white bg-[#E6564E] bg-opacity-10 hover:bg-transparent hover:text-white focus-visible:text-white focus-visible:outline-0 focus-visible:bg-transparent focus-visible:!border-[#E6564E]')}
        />
      ))}
      <input type="hidden" value={value} name={name ?? 'otp'} />
    </div>
  );
};
export default OtpInput;
