import React, { useContext } from "react";
import "./term.scss";
import { useTranslation } from "react-i18next";
import HTMLReactParser from 'html-react-parser';
import { AuthContext } from "../../auth";

const TermAndCondition = () => {
    const { t } = useTranslation();
    const { setTermChecked } = useContext(AuthContext)

  const onClickAgree = (e) => {
    console.log('onClickAgree')
    setTermChecked(true)
    document.getElementById('term-agree')?.click();
    document.getElementById('pdpa-01')?.click();
  }

  return (
    <>
      <input type="checkbox" id="term-modal" className="modal-toggle"/>
      <div className="modal min-h-full z-[10]">
        <div className="modal-box flex flex-col w-11/12 max-w-5xl">
          <div className="flex modal-open w-full right-2 top-2 text-center">
            <h3 className="font-bold">{t('term.title')}</h3>
            <label
              htmlFor="term-modal"
              className="btn btn-sm btn-circle absolute right-2 top-2"
            >
              ✕
            </label>
          </div>
          <div className="overflow-auto mt-4">
          <ContentData t={t} />
          </div>
          <div className="modal-action justify-between">
          <label onClick={onClickAgree} htmlFor="term-modal" className="btn btn-info rounded-full text-white">
              {t('agree')}
          </label>
          <input id="term-agree" htmlFor="term-modal" type="hidden"/>
            <label htmlFor="term-modal" className="btn rounded-full text-white">
            {t('cancel')}
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

const ContentData = ({t}) => {
  return (
    <article className="overflow-hidden text-left">
        {HTMLReactParser(t('term.content'))}
    </article>
  );
};

export default TermAndCondition;
