import { useTranslation } from 'react-i18next';
import { useRegister } from '../../contexts/RegisterContext';
import { useEffect, useState } from 'react';
import { Loading } from '../../components';

const ContentPDPA = ({ step, loading, data, saveSuccess }) => {
  const { t } = useTranslation();
  const { setShowPolicy, setPolicyAccept, savePdpa } = useRegister();

  const onSavePdpa = async (action) => {
    if (step === 1) {
      if (action === 'agree') {
        await savePdpa(data, action);
        setPolicyAccept(false);
        setShowPolicy(true);
      } else {
        await savePdpa(data, action);
        setPolicyAccept(false);
        setShowPolicy(false);
      }
    }

    if (step === 2) {
      if (action === 'agree') {
        savePdpa(data, action);
        setPolicyAccept(true);
        setShowPolicy(false);
      } else {
        savePdpa(data, action);
        setPolicyAccept(true);
        setShowPolicy(false);
      }
    }

    // Update Success
    if (saveSuccess) {
      saveSuccess();
    }
  };

  return (
    <div className="p-4 h-screen flex flex-col">
      <div className=" text-white text-[20px] font-bold mb-4">
        {t('line.policy')}
      </div>
      <div className="mb-4">{data?.name}</div>
      <div className="grow mb-4 ">
        {loading ? (
          <Loading />
        ) : (
          <iframe
            title="policy"
            className="w-full h-full text-white"
            src={`https://docs.google.com/viewer?embedded=true&url=${data?.content_url}`}
          />
        )}
      </div>
      <div className="flex justify-between gap-4">
        <div
          className="grow btn bg-transparent text-white border border-white btn-ghost h-full rounded-full flex flex-col gap-3"
          onClick={() => onSavePdpa('disagree')}
        >
          {t('line.cancel')}
        </div>
        <div
          className="grow btn bg-[#E6564E] text-white btn-ghost hover:bg-[#E6564E] h-full rounded-full flex flex-col gap-3"
          onClick={() => onSavePdpa('agree')}
        >
          {t('line.agree')}
        </div>
      </div>
    </div>
  );
};

export const PolicyModal = ({ username }) => {
  const [loading, setLoading] = useState(true);
  const [loadPdf, setLoadPdf] = useState(true);
  const { setShowPolicy, setPolicyAccept, pdpa, conectPdpa } = useRegister();
  const { t } = useTranslation();
  console.log('pdpa', pdpa);

  useEffect(() => {
    const initPdpa = async () => {
      try {
        const phone = username;
        // const phone = '0947432901';
        if (phone) {
          await conectPdpa(phone);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    if (loading) {
      initPdpa();
    }
  }, [pdpa, loading]);

  if (pdpa?.step1) {
    return (
      <ContentPDPA
        step={1}
        data={pdpa?.step1}
        loading={loading}
        saveSuccess={() => setLoading(true)}
      />
    );
  }

  if (pdpa?.step2) {
    return (
      <ContentPDPA
        step={2}
        data={pdpa?.step2}
        loading={loading}
        saveSuccess={() => setLoading(true)}
      />
    );
  }

  const hideSpinner = () => {
    setLoadPdf(false);
  };

  const pdfUrl =
    'https://docs.google.com/viewer?embedded=true&url=https://s3.speedkub.io/doc/Master-Privacy-Policy-LOC-SDigital-20220519.pdf';
  return (
    <div className="p-4 h-screen flex flex-col">
      <div className=" text-white text-[20px] font-bold mb-4">
        {t('line.policy')}
      </div>
      <div className="mb-4">{pdpa?.step1?.name}</div>
      <div className="grow mb-4">
        {loading ? (
          <Loading />
        ) : (
          <>
            {loadPdf ? (
              <>
                <Loading />
                <iframe
                  title="policy"
                  className="w-full h-[80vh] text-white hidden"
                  src={pdfUrl}
                  onLoad={hideSpinner}
                />
              </>
            ) : (
              <iframe
                title="policy"
                className="w-full h-[80vh] text-white overflow-hidden"
                src={pdfUrl}
                onLoad={hideSpinner}
              />
            )}
          </>
        )}
      </div>
      <div className="flex justify-between gap-4">
        <div
          className="grow btn bg-transparent text-white border border-white btn-ghost h-full rounded-full flex flex-col gap-3"
          onClick={() => {
            setPolicyAccept(false);
            setShowPolicy(false);
          }}
        >
          {t('line.cancel')}
        </div>
        <div
          className="grow btn bg-[#E6564E] text-white btn-ghost hover:bg-[#E6564E] h-full rounded-full flex flex-col gap-3"
          onClick={() => {
            setPolicyAccept(true);
            setShowPolicy(false);
          }}
        >
          {t('line.agree')}
        </div>
      </div>
    </div>
  );
};
