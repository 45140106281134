import axiosLineOA from '../config/axios';

export const getDeal = (cat, phone, page, limit) => {
  let data = {};

  axiosLineOA.defaults.headers.common['x-sbd-platform'] = 'sbd-speedkub';

  if (phone) {
    axiosLineOA.defaults.headers.common['x-sbd-user'] = phone;
  }

  if (cat && cat !== 'special') {
    data['tag_ids'] = cat;
  }

  if (cat === 'special') {
    data['is_special'] = true;
  }

  data['page'] = page;
  data['limit'] = limit;
  data['order_desc'] = true;

  return axiosLineOA.get(`api/v1/deal/list`, {
    params: data
  });
};

export const getHotDeal = (phone, offset, limit) => {
  const data = {};
  axiosLineOA.defaults.headers.common['x-sbd-platform'] = 'sbd-speedkub';
  if (phone) {
    axiosLineOA.defaults.headers.common['x-sbd-user'] = phone;
  }

  return axiosLineOA.get(`/api/v1/banner/list`, data);
};

export const getCoupon = (data) => {
  return axiosLineOA.post(`api/v1/deal/redeem`, data);
};

export const getDealByKeyword = (keyword, phone, page, limit) => {
  let data = {};

  axiosLineOA.defaults.headers.common['x-sbd-platform'] = 'sbd-speedkub';

  if (phone) {
    axiosLineOA.defaults.headers.common['x-sbd-user'] = phone;
  }

  data['keyword'] = keyword;
  data['page'] = page;
  data['limit'] = limit;
  data['order_desc'] = true;

  return axiosLineOA.get(`api/v1/deal/list`, {
    params: data
  });
};