import { useTranslation } from 'react-i18next';
import { PowerBy } from '../components';
import DealContextProvider from '../contexts/DealContext';
import HomeContainer from '../features/Home/HomeContainer';
import Container from '../layout/Container';
import Footer from '../layout/footer/Footer';
import Header from '../layout/header/Header';

const HomePage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header showBalance={true} />
      <PowerBy />
      <Container>
        <DealContextProvider>
          <HomeContainer />
        </DealContextProvider>
      </Container>
      <Footer />
    </>
  );
};
export default HomePage;
