import {
  Special,
  SpecialActive,
  Food,
  FoodActive,
  Life,
  LifeActive,
  Shopping,
  ShoppingActive,
  Travel,
  TravelActive,
  Vending,
  VendingActive
} from '../assets/filter-menu';

export const dealCategory = [
  {
    name: 'special',
    value: 'special',
    icon: Special,
    iconActive: SpecialActive
  },
  {
    name: 'food',
    value: 'food',
    icon: Food,
    iconActive: FoodActive
  },
  {
    name: 'shopping',
    value: 'shopping',
    icon: Shopping,
    iconActive: ShoppingActive
  },
  {
    name: 'life',
    value: 'life',
    icon: Life,
    iconActive: LifeActive
  },
  {
    name: 'travel',
    value: 'travel',
    icon: Travel,
    iconActive: TravelActive
  },
  {
    name: 'vending',
    value: 'vending',
    icon: Vending,
    iconActive: VendingActive
  }
];
