import { useEffect, useRef, useState } from 'react';
import { LogoFull, EyeOpenIcon, EyeCloseIcon } from '../../assets';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useRegister } from '../../contexts/RegisterContext';
import { PolicyModal } from './PolicyModal';
import { TermModal } from './TermModal';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/outline';

const schema = yup
  .object({
    username: yup.string().required(),
    password: yup.string().required('password is required'),
    confirm_password: yup
      .string()
      .required('Confirm password is required')
      .oneOf([yup.ref('password')], 'Confirm passwords does not match'),
    policy: yup
      .bool() // use bool instead of boolean
      .oneOf([true], 'The policy must be accepted.'),
    term: yup
      .bool() // use bool instead of boolean
      .oneOf([true], 'The terms and conditions must be accepted.')
  })
  .required();

const RegisterContainer = () => {
  const initPage = useRef(false);
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState();

  const {
    policyAccept,
    showPolicy,
    setShowPolicy,
    termAccept,
    showTerm,
    setShowTerm,
    register1
  } = useRegister();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
      confirm_password: '',
      // refer: '',
      policy: false,
      term: false
    },
    resolver: yupResolver(schema)
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { t } = useTranslation();

  const onRegister = async (fromData) => {
    console.log('fromData', fromData);
    try {
      setErrorMsg(undefined);
      const res = await register1(fromData);
      if (res.data.status === 200) {
        const params = {
          // refer: fromData?.refer,
          ref_code: res?.data?.data?.ref_code,
          phone: fromData?.username
        };

        navigate({
          pathname: '/line/otp',
          replace: true,
          search: `?${createSearchParams(params)}`
        });
      }
      console.log('onRegister', res);
      // const phone = `08${Math.floor(Math.random() * 100000000)}`;
    } catch (error) {
      setErrorMsg(error);
    }
  };

  useEffect(() => {
    const checkTerm = () => {
      setValue('term', termAccept);
      if (initPage.current) {
        trigger('term');
      }
    };
    checkTerm();
  }, [termAccept]);

  useEffect(() => {
    const checkPolicy = () => {
      setValue('policy', policyAccept);
      if (initPage.current) {
        trigger('policy');
      }
    };
    checkPolicy();
  }, [policyAccept]);

  useEffect(() => {
    if (!initPage.current) {
      initPage.current = true;
      localStorage.removeItem('line_p');
      localStorage.removeItem('line_t');
    }
  }, []);

  if (showPolicy) {
    const username = getValues('username');
    return <PolicyModal username={username} />;
  }

  if (showTerm) {
    return <TermModal />;
  }

  return (
    <>
      <form
        className="flex flex-col w-full h-screen gap-5 pb-5"
        onSubmit={handleSubmit(onRegister)}
      >
        <div className=" ">
          <div className="flex justify-center items-center my-4 relative mx-4">
            <div
              className="flex justify-center items-center  absolute left-1"
              onClick={() => navigate(-1)}
            >
              <ArrowLeftIcon className="aspect-square w-5" aria-hidden="true" />
            </div>
            <img src={LogoFull} className="h-8 ml-5" alt="logo" />
          </div>
        </div>
        <div className=" bg-[#222225] p-4 rounded-lg mx-4 flex flex-col gap-3">
          <div className="text-white font-bold text-[20px]">
            {t('line.register')}
          </div>
          {errorMsg && (
            <div className="text-[#FC4D55] text-center">{errorMsg}</div>
          )}
          <Controller
            name="username"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col justify-start w-full">
                <label className="text-left font-normal">
                  {t('line.reg.phone')}
                </label>
                <input
                  {...field}
                  name={field?.name}
                  autoComplete="username"
                  className="input w-full border border-[#D0D1DA] rounded-lg bg-transparent  px-4 py-3"
                  placeholder="xxx-xxx-xxxx"
                />
                {errors?.username && (
                  <div className="text-[#FC4D55] text-left ml-3">
                    {errors?.username.message}
                  </div>
                )}
              </div>
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col justify-start w-full">
                <label className="text-left font-normal">
                  {t('line.reg.password')}
                </label>
                <div className="relative cursor-pointer">
                  <input
                    {...field}
                    name={field?.name}
                    autoComplete="new-password"
                    type={showPassword ? 'text' : 'password'}
                    className="input w-full border border-[#D0D1DA] rounded-lg bg-transparent  px-4 py-3"
                    placeholder="รหัสผ่าน อย่างน้อย 8 อักษร"
                  />
                  <img
                    alt="show-password"
                    className="w-6 absolute bottom-3 right-3"
                    src={showPassword ? EyeOpenIcon : EyeCloseIcon}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </div>
                {errors?.password && (
                  <div className="text-[#FC4D55] text-left ml-3">
                    {errors?.password.message}
                  </div>
                )}
              </div>
            )}
          />
          <Controller
            name="confirm_password"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col justify-start w-full">
                <label className="text-left font-normal">
                  {t('line.reg.confirm_password')}
                </label>
                <div className="relative cursor-pointer">
                  <input
                    {...field}
                    name={field?.name}
                    autoComplete="new-password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    className="input w-full border border-[#D0D1DA] rounded-lg bg-transparent  px-4 py-3"
                    placeholder="รหัสผ่าน อย่างน้อย 8 อักษร"
                  />
                  <img
                    alt="show-password"
                    className="w-6 absolute bottom-3 right-3"
                    src={showConfirmPassword ? EyeOpenIcon : EyeCloseIcon}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                </div>
                {errors?.confirm_password && (
                  <div className="text-[#FC4D55] text-left ml-3">
                    {errors?.confirm_password.message}
                  </div>
                )}
              </div>
            )}
          />
          {/* <Controller
            name="refer"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col justify-start w-full">
                <label className="text-left font-normal">
                  {t('line.reg.ref')}
                </label>
                <input
                  {...field}
                  className="input w-full border border-[#D0D1DA] rounded-lg bg-transparent  px-4 py-3"
                  placeholder="xxxx"
                />
              </div>
            )}
          /> */}
          <div className="flex flex-col text-left gap-1">
            <Controller
              name="policy"
              control={control}
              render={({ field }) => (
                <div
                  className="flex items-center gap-1 cursor-pointer font-normal"
                  onClick={() => setShowPolicy(true)}
                >
                  <input
                    {...field}
                    type="checkbox"
                    className="checkbox border-2 border-[#D0D1DA] rounded w-5 h-5"
                    defaultChecked={policyAccept}
                  />
                  {t('line.policy')}
                </div>
              )}
            />
            {errors?.policy && (
              <div className="text-[#FC4D55] text-left ml-3">
                {errors?.policy.message}
              </div>
            )}

            <Controller
              name="term"
              control={control}
              render={({ field }) => (
                <div
                  className="flex items-center gap-1 cursor-pointer font-normal"
                  onClick={() => setShowTerm(true)}
                >
                  <input
                    {...field}
                    name={field.name}
                    type="checkbox"
                    className="checkbox border-2 border-[#D0D1DA] rounded w-5 h-5"
                    defaultChecked={termAccept}
                  />
                  {t('line.term')}
                </div>
              )}
            />

            {errors?.term && (
              <div className="text-[#FC4D55] text-left ml-3">
                {errors?.term.message}
              </div>
            )}
          </div>
        </div>
        <div className=" pb-5 px-4">
          <button
            type="submit"
            className=" btn bg-[#E6564E] text-white btn-ghost hover:bg-[#E6564E] h-full w-full rounded-full flex flex-col gap-3"
          >
            {t('line.register')}
          </button>
        </div>
      </form>
    </>
  );
};
export default RegisterContainer;
