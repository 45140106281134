import { useTranslation } from 'react-i18next';
import { PowerBy } from '../components';
import DealContextProvider from '../contexts/DealContext';
import Container from '../layout/Container';
import Footer from '../layout/footer/Footer';
import Header from '../layout/header/Header';
import SearchContainer from '../features/Search/SearchContainer';

const SearchPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header showBalance showBack />
      <Container>
        <DealContextProvider>
          <SearchContainer />
        </DealContextProvider>
      </Container>
      <Footer />
    </>
  );
};
export default SearchPage;
