import { useEffect, useState, createContext, useContext } from 'react';
import * as configApi from '../api/configApi';

const SystemContext = createContext();

function SystemContextProvider({ children }) {
  const [initialLoading, setInitialLoading] = useState(true);
  const [maintenance, setMaintenance] = useState();

  useEffect(() => {
    const fetchSystemInfo = async () => {
      try {
        await getSystemInfo();
      } catch (err) {
      } finally {
        setInitialLoading(false);
      }
    };
    fetchSystemInfo();
  }, []);

  const getSystemInfo = async () => {
    const res = await configApi.getSystemInfo();
    const data = res.data.data;
    const { value } = data;
    const { maintenance: maintenanceData } = value;
    setMaintenance(maintenanceData);
  };

  const reloadConfig = async () => {
    await getSystemInfo();
  };

  return (
    <SystemContext.Provider
      value={{
        initialLoading,
        maintenance,
        reloadConfig
      }}
    >
      {children}
    </SystemContext.Provider>
  );
}

export const useSystem = () => {
  return useContext(SystemContext);
};

export default SystemContextProvider;
