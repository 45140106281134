import React, { useContext } from 'react';
import { Content, MainContainer, Footer } from '../../layout';
import { useTranslation } from 'react-i18next';
import './home.scss';
import CoinHome from '../../assets/coin.png';
import {
  CoinsIcon,
  RocketIcon,
  UsersIcon,
  PartnersIcon
} from '../../assets/icon';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../auth';
import {
  AboutSection,
  CustomerSection,
  PartnerSection,
  PopupDownloadApp
} from '../../components';
import {
  customerPart1,
  customerPart2,
  customerPart3,
  partner
} from '../../assets';
import GooglePlay from '../../assets/google_play.png';
import Qrcode from '../../assets/qr_download-new.png';
import AppStore from '../../assets/app_store.png';
import Snowfall from 'react-snowfall';

const HomePage = () => {
  const { t } = useTranslation();
  const { isLogin } = useContext(AuthContext);

  return (
    <MainContainer isLogin={isLogin} hideMenu={true}>
      <Content appBg={true} bgRight={true} minHSceen={true} hero={true}>
        <div className="container mx-auto px-16 h-full flex flex-col items-start justify-center">
          <div className="mt-10 mb-5 float-left">
            <img
              className="mask mask-circle w-48 md:w-48 lg:w-48"
              alt="speedkub-sks"
              src={CoinHome}
            />
          </div>
          <p className="text-3xl lg:text-4xl font-bold text-left leading-loose lg:leading-loose">
            {t('home.section_one.top')}
          </p>
          <p className="text-3xl lg:text-4xl font-bold text-left leading-loose lg:leading-loose">
            {t('home.section_one.sec')}
          </p>
          <div className="flex flex-row">
            <div className="mt-5 mb-5 float-left">
              {/Android|webOS|iPhone|iPad/i.test(navigator.userAgent) ? (
                <a
                  href="https://app.adjust.com/10i1i8iv"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="btn md:btn-md lg:btn-lg btn-outline px-4 py-2 font-semibold hover:bg-[#E6564E] text-white hover:text-white rounded-full shadow-sm hover:border-[#E6564E]">
                    {t('download_app')}
                  </div>
                </a>
              ) : (
                <PopupDownloadApp idx="my-modal-3" />
              )}
            </div>
            <div className="mt-5 mb-5 float-left ml-5">
              <Link
                to="/login"
                className="btn md:btn-md lg:btn-lg btn-outline px-4 py-2 font-semibold hover:bg-[#E6564E] text-white hover:text-white rounded-full shadow-sm hover:border-[#E6564E]"
              >
                {t('login.t')}
              </Link>
            </div>
          </div>

          <div className="w-96 mb-14">
            {/Android|webOS|iPhone|iPad/i.test(navigator.userAgent) ? (
              <a
                href="https://app.adjust.com/10i1i8iv"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex flex-row">
                  <div className="float-left">
                    <img src={GooglePlay} alt="download-app-android" />
                  </div>
                  <div className="float-left">
                    <img src={AppStore} alt="download-app-android" />
                  </div>
                </div>
              </a>
            ) : (
              <PopupDownloadApp idx="register-home" />
            )}
          </div>
        </div>
      </Content>
      <div className="pt-20 px-10 bg-[#F2F2F2]">
        <div className="flex justify-center">
          <p className="text-3xl text-[#E6564E] text-center leading-loose lg:leading-loose">
            {t('home.section_two.desc')}
          </p>
        </div>
        <div className="flex flex-row flex-wrap mt-16 mb-16">
          <AboutSection
            icon={CoinsIcon}
            top={t('home.section_two.what.top')}
            sec={t('home.section_two.what.desc')}
          />
          <AboutSection
            icon={RocketIcon}
            top={t('home.section_two.our_goal.top')}
            sec={t('home.section_two.our_goal.desc')}
          />
          <CustomerSection
            icon={UsersIcon}
            img={[customerPart1, customerPart2, customerPart3]}
            top={t('home.section_two.customer_group.top')}
            sec={t('home.section_two.customer_group.desc')}
            sec2={t('home.section_two.customer_group.desc2')}
          />
          <CustomerSection
            icon={PartnersIcon}
            img={[partner]}
            top={t('home.section_two.partner.top')}
            sec={t('home.section_two.partner.desc')}
          />
        </div>
      </div>
      <PartnerSection t={t} />
      <Footer />
      <PopupDownloadApp autoShow={true} />
    </MainContainer>
  );
};
export default HomePage;
