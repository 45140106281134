import { useState } from 'react';
import { LogoFull, EyeOpenIcon, EyeCloseIcon } from '../../assets';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { LoadingIcon } from '../../components';
import { useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

const schema = yup
  .object({
    username: yup.string().required().required('username is required'),
    password: yup.string().required('password is required')
  })
  .required();

const LoginContainer = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState();

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      username: '',
      password: ''
    },
    resolver: yupResolver(schema)
  });

  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();

  const onLogin = async (fromData) => {
    try {
      setLoading(true);
      const res = await login({
        username: fromData?.username,
        password: fromData?.password
      });
      console.log('resxxx', res);
      if (res?.status === 200) {
        setLoading(false);
        const goPageMapping = {
          deal: '/line',
          wallet: '/line/wallet'
        };
        const page = searchParams.get('page');
        navigate(goPageMapping[page], { replace: true });
      }
    } catch (error) {
      setLoading(false);
      setErrorMsg(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form
        className="flex flex-col w-full h-screen gap-5 pb-5"
        onSubmit={handleSubmit(onLogin)}
      >
        <div className="flex justify-center items-center my-4">
          <img src={LogoFull} className="h-8" alt="logo" />
        </div>
        <div className=" bg-[#222225] p-4 rounded-lg mx-4 flex flex-col gap-3">
          <div className=" text-white font-bold text-[20px]">
            {t('line.login')}
          </div>
          {errorMsg && (
            <div className="text-[#FC4D55] text-center">{errorMsg}</div>
          )}
          <Controller
            name="username"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col justify-start w-full">
                <label className="text-left font-normal">
                  {t('line.reg.phone')}
                </label>
                <input
                  {...field}
                  name={field?.name}
                  autoComplete="username"
                  className="input w-full border border-[#D0D1DA] rounded-lg bg-transparent  px-4 py-3"
                  placeholder="xxx-xxx-xxxx"
                />
                {errors?.username && (
                  <div className="text-[#FC4D55] text-left ml-3">
                    {errors?.username.message}
                  </div>
                )}
              </div>
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col justify-start w-full">
                <label className="text-left font-normal">
                  {t('line.reg.password')}
                </label>
                <div className="relative cursor-pointer">
                  <input
                    {...field}
                    name={field?.name}
                    autoComplete="current-password"
                    type={showPassword ? 'text' : 'password'}
                    className="input w-full border border-[#D0D1DA] rounded-lg bg-transparent  px-4 py-3"
                    // placeholder="รหัสผ่าน อย่างน้อย 8 อักษร"
                  />
                  <img
                    alt="show-password"
                    className="w-6 absolute bottom-3 right-3"
                    src={showPassword ? EyeOpenIcon : EyeCloseIcon}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </div>
                {errors?.password && (
                  <div className="text-[#FC4D55] text-left ml-3">
                    {errors?.password.message}
                  </div>
                )}
              </div>
            )}
          />

          <div className="flex gap-2 justify-center">
            <span className=" text-white font-kanitLight text-[16px]">
              {t('line.no_account')}
            </span>
            <Link
              to={'/line/register'}
              className=" text-[#E6564E] font-kanitLight text-[16px]"
            >
              {t('line.register')}
            </Link>
          </div>
        </div>
        <div className=" pb-5 px-4">
          {loading ? (
            <div className=" btn bg-[#E6564E] text-white btn-ghost hover:bg-[#E6564E] h-full w-full rounded-full flex gap-3">
              <LoadingIcon />
              {t('line.login')}
            </div>
          ) : (
            <button
              type="submit"
              className=" btn bg-[#E6564E] text-white btn-ghost hover:bg-[#E6564E] h-full w-full rounded-full flex flex-col gap-3"
            >
              {t('line.login')}
            </button>
          )}
        </div>
      </form>
    </>
  );
};
export default LoginContainer;
