import { useEffect, useState, createContext, useContext } from 'react';
import * as dealApi from '../api/dealApi';
import { getLocalStorage, getPhoneStorage } from '../utils/localStorage';
import { useSystem } from './SystemContext';

const DealContext = createContext();

function DealContextProvider({ children }) {
  const [hotDealList, setHotDealList] = useState([]);
  const [dealList, setDealList] = useState([]);
  const [category, setCategory] = useState('special');
  const [phone, setPhone] = useState(getPhoneStorage() ?? '');
  const [initialLoading, setInitialLoading] = useState(true);
  const [initialHotDealLoading, setInitialHotDealLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);

  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);

  const [coupon, setCoupon] = useState('');
  const [canRedeem, setCanRedeem] = useState(false);

  const { reloadConfig } = useSystem();

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        setInitialLoading(true);
        await reloadConfig();
        if (getLocalStorage()) {
          await getHotDeal(phone);
          await filterDeal(category, phone);
        }
      } catch (err) {
        const { pathname, search } = window.location;
        window.location.replace(pathname + search);
      } finally {
        setInitialLoading(false);
      }
    };
    fetchUserInfo();
  }, []);

  const getHotDeal = async (phone) => {
    try {
      setInitialHotDealLoading(true);
      const res = await dealApi.getHotDeal(phone, page, limit);
      const data = res.data?.data?.data ?? [];
      setHotDealList(data);
    } catch (err) {
      // console.log(err);
      const { pathname, search } = window.location;
      window.location.replace(pathname + search);
    } finally {
      setInitialHotDealLoading(false);
    }
  };

  const filterDeal = async (cat, phone) => {
    try {
      setInitialLoading(true);
      const res = await dealApi.getDeal(cat, phone, page, limit);
      const data = res.data.data.data ?? [];
      // debugger;
      setDealList(data);
      setCategory(cat);
      setPage(res.data.data.page);

      if (res.data.data.data === null) {
        setHasMore(false);
      }

    } catch (err) {
      // console.log(err);
      const { pathname, search } = window.location;
      window.location.replace(pathname + search);
    } finally {
      setInitialLoading(false);
    }
  };

  const getCouponDeal = async (useCoponData) => {
    try {
      console.log('useCoponData', useCoponData)
      setInitialLoading(true);
      const res = await dealApi.getCoupon(useCoponData);
      return res;
    } catch (err) {
      console.log(err.response.data);
      return err.response;
    } finally {
      setInitialLoading(false);
    }
  };

  const loadMore = async () => {
    try {
      // setInitialLoading(true);
      const currentPage = page + 1;
      const res = await dealApi.getDeal(
        category,
        phone,
        currentPage,
        limit
      );
      const allData = [...dealList, ...(res.data.data.data ?? [])];
      setDealList(allData);
      setPage(currentPage)
      if (res.data.data.data === null) {
        setHasMore(false);
      }
    } catch (err) {
      console.log(err);
      const { pathname, search } = window.location;
      window.location.replace(pathname + search);
    } finally {
      // setInitialLoading(false);
    }
  };

  return (
    <DealContext.Provider
      value={{
        initialLoading,
        initialHotDealLoading,
        hotDealList,
        dealList,
        category,
        filterDeal,
        getCouponDeal,
        canRedeem,
        setCanRedeem,
        hasMore,
        loadMore
      }}
    >
      {children}
    </DealContext.Provider>
  );
}

export const useDeal = () => {
  return useContext(DealContext);
};

export default DealContextProvider;
