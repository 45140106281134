import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  WalletPage,
  HomePage,
  NewsPage,
  NewsDetail,
  RegisterPage,
  OtpPage,
  LoginPage,
  KycPage,
  SearchPage
} from '../pages';
import DealDetailPage from '../pages/DealDetailPage';
// import { getToken } from '../utils/localStorage';
import OtpSuccess from '../features/otp-success/otp-success';
import RegisterContextProvider from '../contexts/RegisterContext';
import ProfilePage from '../pages/ProfilePage';
import KycSuccessPage from '../pages/KycSuccessPage';

const RouterApp = () => {
  // const token = getToken() ?? '';
  return (
    <RegisterContextProvider>
      <Routes>
        <Route path="/line" element={<HomePage />} />
        <Route path="/line/search" element={<SearchPage />} />
        <Route path="/line/login" element={<LoginPage />} />
        <Route path="/line/deal-detail" element={<DealDetailPage />} />
        <Route path="/line/news" element={<NewsPage />} />
        <Route path="/line/news-detail" element={<NewsDetail />} />
        <Route path="/line/wallet" element={<WalletPage />} />
        <Route path="/line/register" element={<RegisterPage />} />
        <Route path="/line/kyc" element={<KycPage />} />
        <Route path="/line/kyc-success" element={<KycSuccessPage />} />
        <Route path="/line/otp" element={<OtpPage />} />
        <Route path="/line/otp-success" element={<OtpSuccess />} />
        <Route path="/line/profile" element={<ProfilePage />} />
      </Routes>
    </RegisterContextProvider>
  );
  // return token ? (
  //   <RegisterContextProvider>
  //     <Routes>
  //       <Route path="/line" element={<HomePage />} />
  //       <Route path="/line/deal-detail" element={<DealDetailPage />} />
  //       <Route path="/line/news" element={<NewsPage />} />
  //       <Route path="/line/news-detail" element={<NewsDetail />} />
  //       <Route path="/line/wallet" element={<WalletPage />} />
  //       <Route path="/line/register" element={<RegisterPage />} />
  //       <Route path="/line/otp" element={<OtpPage />} />
  //       <Route path="/line/otp-success" element={<OtpSuccess />} />
  //       <Route path="/line/profile" element={<ProfilePage />} />
  //     </Routes>
  //   </RegisterContextProvider>
  // ) : (
  //   <Routes>
  //     <Route path="/">
  //       <Route path="*" element={<ErrorPage />} />
  //     </Route>
  //   </Routes>
  // );
};

export default RouterApp;
