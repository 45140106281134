import { useTranslation } from 'react-i18next';
import { useRegister } from '../../contexts/RegisterContext';
import HTMLReactParser from 'html-react-parser';

export const TermModal = () => {
  const { setShowTerm, setTermAccept } = useRegister();
  const { t } = useTranslation();
  return (
    <div className="p-4 h-screen flex flex-col">
      <div className=" text-white text-[20px] font-bold mb-4">
        {t('line.term')}
      </div>
      <article className="grow text-left mb-4 max-h-screen overflow-y-scroll scroll-ui">
        {HTMLReactParser(t('term.content'))}
      </article>
      <div className="flex justify-between gap-4">
        <div
          className="grow btn bg-transparent text-white border border-white btn-ghost h-full rounded-full flex flex-col gap-3"
          onClick={() => {
            setTermAccept(false);
            setShowTerm(false);
          }}
        >
          {t('line.cancel')}
        </div>
        <div
          className="grow btn bg-[#E6564E] text-white btn-ghost hover:bg-[#E6564E] h-full rounded-full flex flex-col gap-3"
          onClick={() => {
            setTermAccept(true);
            setShowTerm(false);
          }}
        >
          {t('line.agree')}
        </div>
      </div>
    </div>
  );
};
