import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllNews } from '../../api/newsApi';
import { ImageLoading } from '../../components';
import styles from './News.module.scss';
import HTMLReactParser from 'html-react-parser';

const NewsItem = ({ dataItem }) => {
  const { id, title, thumbnail_image, detail } = dataItem;
  const navigate = useNavigate();

  const onClick = (dataItem) => {
    navigate('/line/news-detail', { state: dataItem });
  };

  return (
    <>
      <div
        className={`group rounded-[5px] cursor-pointer ${styles.items_box}`}
        onClick={() => onClick(dataItem)}
      >
        <div className="grid grid-cols-12 p-2 gap-2">
          <div className=" col-span-4">
            <ImageLoading
              className="object-cover aspect-[3/2] rounded-[5px]"
              src={thumbnail_image}
              aspectDefault={'3/2'}
            />
          </div>
          <div className="col-span-8 flex flex-col gap-2">
            <div className="text-[14px] text-white font-medium flex justify-between text-left">
              <span className="line-clamp-2">{title}</span>
            </div>
            <div className="text-[10px] text-white font-normal flex justify-between text-left">
              <span className="line-clamp-2">{HTMLReactParser(detail)}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewsItem;
