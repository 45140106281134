import LoginContainer from '../features/login/LoginContainer';

const LoginPage = () => {
  return (
    <>
      <LoginContainer />
    </>
  );
};
export default LoginPage;
