import { useEffect, useState, createContext, useContext } from 'react';
import * as configApi from '../api/configApi';
import {
  logoutLineToken,
  getLineIDToken,
  initializeLiff
} from '../utils/lineliff';

const SystemContext = createContext();

function SystemContextProvider({ children }) {
  const [initialLoading, setInitialLoading] = useState(true);
  const [maintenance, setMaintenance] = useState();

  useEffect(() => {
    const fetchSystemInfo = async () => {
      try {
        await getSystemInfo();
        console.log('window.origin', window.origin);
        console.log('env liff id', process.env.REACT_APP_LIFF_ID);
        console.log('env api key', process.env.REACT_APP_BACKEND_API_KEY);
        if (window.origin !== 'http://localhost:3000') {
          await connectLineOA();
        }
      } catch (err) {
      } finally {
        setInitialLoading(false);
      }
    };
    if (initialLoading) {
      fetchSystemInfo();
    }
  }, [initialLoading]);

  const getSystemInfo = async () => {
    const res = await configApi.getSystemInfo();
    const data = res.data.data;
    const { value } = data;
    const { maintenance: maintenanceData } = value;
    setMaintenance(maintenanceData);
  };

  const connectLineOA = async () => {
    await initializeLiff();
    try {
      const lineToken = await getLineIDToken();
      console.log('LineToken', lineToken);
      if (lineToken) {
        localStorage.setItem('line_id_t', lineToken);
        const res = await configApi.getConnectSpeedkubWithLineToken(lineToken);
        const { token, refresh_token } = res.data.data;
        console.log('customToken:', token);
        if (token) {
          localStorage.setItem('line_t', token);
          localStorage.setItem('line_t_r', refresh_token);
        } else {
          localStorage.removeItem('line_t');
        }
      } else {
        localStorage.removeItem('line_id_t');
      }
    } catch (error) {
      if (error.response.status === 401) {
        console.log('error', error.response.status);
        localStorage.removeItem('line_t');
        localStorage.removeItem('line_t_r');
      }
    }
    // }
  };

  const reloadConfig = async () => {
    await getSystemInfo();
  };

  const resetSystem = async () => {
    await logoutLineToken();
    setInitialLoading(true);
  };

  return (
    <SystemContext.Provider
      value={{
        initialLoading,
        maintenance,
        reloadConfig,
        resetSystem
      }}
    >
      {children}
    </SystemContext.Provider>
  );
}

export const useSystem = () => {
  return useContext(SystemContext);
};

export default SystemContextProvider;
