import React from 'react';
import { Content, MainContainer } from '../../layout';
import CoinHome from '../../assets/coin.png';
import { Link, useLocation } from 'react-router-dom';
import { toCommas } from '../../utils';

const CorrectIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="61"
      height="60"
      viewBox="0 0 61 60"
      fill="none"
    >
      <path
        d="M27 34.5L21.5625 29.0625C21.1042 28.6042 20.5417 28.375 19.875 28.375C19.2083 28.375 18.625 28.625 18.125 29.125C17.6667 29.5833 17.4375 30.1667 17.4375 30.875C17.4375 31.5833 17.6667 32.1667 18.125 32.625L25.25 39.75C25.7083 40.2083 26.2917 40.4375 27 40.4375C27.7083 40.4375 28.2917 40.2083 28.75 39.75L42.9375 25.5625C43.3958 25.1042 43.625 24.5417 43.625 23.875C43.625 23.2083 43.375 22.625 42.875 22.125C42.4167 21.6667 41.8333 21.4375 41.125 21.4375C40.4167 21.4375 39.8333 21.6667 39.375 22.125L27 34.5ZM30.5 55C27.0417 55 23.7917 54.3433 20.75 53.03C17.7083 51.7167 15.0625 49.9358 12.8125 47.6875C10.5625 45.4375 8.78167 42.7917 7.47 39.75C6.15833 36.7083 5.50167 33.4583 5.5 30C5.5 26.5417 6.15667 23.2917 7.47 20.25C8.78333 17.2083 10.5642 14.5625 12.8125 12.3125C15.0625 10.0625 17.7083 8.28167 20.75 6.97C23.7917 5.65833 27.0417 5.00167 30.5 5C33.9583 5 37.2083 5.65667 40.25 6.97C43.2917 8.28333 45.9375 10.0642 48.1875 12.3125C50.4375 14.5625 52.2192 17.2083 53.5325 20.25C54.8458 23.2917 55.5017 26.5417 55.5 30C55.5 33.4583 54.8433 36.7083 53.53 39.75C52.2167 42.7917 50.4358 45.4375 48.1875 47.6875C45.9375 49.9375 43.2917 51.7192 40.25 53.0325C37.2083 54.3458 33.9583 55.0017 30.5 55Z"
        fill="white"
      />
    </svg>
  );
};

const CloseIcon = () => {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path d="M23 23L9 9M23 9L9 23" stroke="#C0C3CD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>);
}

const KycInfoPage = () => {
  const { state } = useLocation();
  const rewardCoinValue = state?.data?.reward ?? null;
  // const rewardCoinValue = 2000;
  return (
    <MainContainer>
      <Content minHSceen={true}>
        <div className="w-full h-full flex flex-col justify-center items-center p-6">
          <div className="card flex-shrink-0 w-full max-w-xl bg-[#5A5A62] rounded-[8px]">
            <div className="card-body relative">
              <Link to="/dashboard" className='absolute top-2 right-2 cursor-pointer' >
                <CloseIcon />
              </Link>
              <div className="flex justify-center">
                <CorrectIcon />
              </div>
              <div className="form-control">
                {rewardCoinValue ? (
                  <>
                    <p className="mt-5 text-2xl text-[#FFFFFF] text-center">
                      สมัครสำเร็จ!
                    </p>
                    <div className="text-lg text-[#FFFFFF] text-center flex items-center justify-center">
                      <span>
                        ยินดีด้วยคุณได้รับ {toCommas(rewardCoinValue)}
                      </span>
                      <div className="avatar">
                        <div className="w-8 rounded">
                          <img
                            src={CoinHome}
                            alt="Tailwind-CSS-Avatar-component"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 card w-full  bg-[#47474E] p-4 flex justify-center rounded-[8px]">
                      <div>ยืนยันตัวตนเพื่อรับเหรียญเพิ่ม</div>
                      <div className="w-full mt-2">
                        <a
                          className="btn btn-primary text-[16px] w-fit rounded-full text-white "
                          href="https://app.adjust.com/10i1i8iv"
                          target="_blank"
                          rel="noopener noreferrer"
                        >ยืนยันตัวตน</a>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <>
                      <p className="mt-5 text-2xl text-[#FFFFFF] text-center">
                        สมัครสำเร็จ!
                      </p>
                      <div className="mt-6 card w-full  bg-[#47474E] p-4 flex justify-center rounded-[8px]">
                        <div>ยืนยันตัวตนเพื่อรับเหรียญเพิ่ม</div>
                        <div className="w-full mt-2">
                          <a
                            className="btn btn-primary text-[16px] w-fit rounded-full text-white "
                            href="https://app.adjust.com/10i1i8iv"
                            target="_blank"
                            rel="noopener noreferrer"
                          >ยืนยันตัวตน</a>
                        </div>
                      </div>
                    </>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Content>
    </MainContainer>
  );
};
export default KycInfoPage;
