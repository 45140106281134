import React from 'react';
import { Content, MainContainer, Footer } from '../../layout';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/outline';

const TermPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [qureyString] = useSearchParams();
  const isMobileApp = qureyString.get('open');

  return (
    <>
      {isMobileApp ? (
        <div className="flex flex-col pt-10 pb-32 px-5">
          <div className="container mx-auto">
            <article className="text-left">
              {HTMLReactParser(t('term.content'))}
            </article>
          </div>
        </div>
      ) : (
        <MainContainer hideMenu={true}>
          <Content minHSceen={true}>
            <div className="flex flex-col pt-10 pb-32">
              <div className="flex w-full justify-between py-3 cursor-pointer px-5 mt-auto">
                <div
                  className="flex justify-center items-center"
                  onClick={() => navigate(-1)}
                >
                  <ChevronLeftIcon
                    className="aspect-square w-8 "
                    aria-hidden="true"
                  />
                  <p>Back</p>
                </div>
              </div>
              <div className="container">
                <div className="flex w-full text-center">
                  <h3 className="font-bold">{t('term.title')}</h3>
                </div>
                <article className="text-left">
                  {HTMLReactParser(t('term.content'))}
                </article>
              </div>
            </div>
          </Content>
        </MainContainer>
      )}
    </>
  );
};
export default TermPage;
