import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { ClipboardCopyIcon, MenuIcon } from '@heroicons/react/solid';
import navLogo from '../../assets/logo.png';
import { SwitchLang } from '../../components';
import './navbar.scss';

import { AuthContext } from '../../auth';
import { openInNewTab } from '../../utils';

import Qrcode from '../../assets/qr_download-new.png';


export const ReferMenu = ({ t, profile }) => {
  const [isCopy, setIsCopy] = useState(false);
  const { isKyc } = useContext(AuthContext);

  const copyRefer = () => {
    navigator.clipboard.writeText('Copy this text to clipboard');
    const el = document.getElementById('nav-refer');
    const range = document.createRange();
    // range.selectNodeContents(el);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
    const registerPath = `register?ref=${el.innerHTML.toString()}`;
    let copyCode = `https://dev.speedkub.io/${registerPath}`;
    const REACT_APP_PROJECT_NAME = process.env.REACT_APP_PROJECT_NAME;
    if (REACT_APP_PROJECT_NAME === 'speedkub-prod') {
      copyCode = `https://speedkub.io/${registerPath}`;
    }
    navigator.clipboard.writeText(copyCode);
    setIsCopy(true);
  };

  return (
    <div className="flex-col w-full">
      <div className="text-left">{t('refer.profile')}</div>
      <div className="flex-col mt-5">
        {profile?.profile?.name || profile?.profile?.surname ? (
          <div className="text-left mb-1">
            {`${profile?.profile?.name ? profile?.profile?.name : ''} ${profile?.profile?.surname ? profile?.profile?.surname : ''
              }`}
          </div>
        ) : null}
        <div className="text-left" >{t('refer.ref_code')}</div>
        <div className="refer-field flex justify-between items-center border-2 rounded-md border-slate-400 px-2 py-1 w-full">
          <span className="line-height-4" id="nav-refer">
            {isKyc ? profile?.referral : t('refer.p_kyc')}
          </span>

          {isKyc ? <div className="tooltip" data-tip={isCopy ? 'Copied' : 'Copy'}>
            <button
              onClick={copyRefer}
              className="btn btn-xs btn-circle btn-ghost"
            >
              <ClipboardCopyIcon className="bg-transparent"></ClipboardCopyIcon>
            </button>
          </div> : null}

        </div>
      </div>
    </div>
  );
};

const DropDownMenu = ({ t, logout, profile }) => {
  return (
    <div className="dropdown dropdown-end">
      <label tabIndex={0} className="btn btn-ghost m-1">
        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
      </label>

      <ul
        tabIndex={0}
        className="dropdown-content menu p-2 shadow bg-white rounded-box w-52 z-auto"
      >
        <div className="text-black focus:bg-transparent active:bg-transparent w-full p-3">
          <ReferMenu t={t} profile={profile} />
        </div>
        <li>
          <Link to="/refer" className="text-black">
            {t('refer_menu')}
          </Link>
        </li>

        <li>
          {/Android|webOS|iPhone|iPad/i.test(navigator.userAgent) ? (
            <a
              href="https://app.adjust.com/10i1i8iv"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="text-black">
                {t('download_app')}
              </div>
            </a>
          ) : (
            <label className="cursor-pointer">
              <div className="text-black">
                {t('download_app')}
              </div>
              <input
                type="checkbox"
                id="register-sidebarLogin"
                className="modal-toggle"
                htmlFor="register-sidebarLogin"
              />
              <div className="modal">
                <div className="modal-box relative grid justify-items-center">
                  <label
                    htmlFor="register-sidebarLogin"
                    className="btn btn-sm btn-circle absolute right-2 top-2"
                  >
                    ✕
                  </label>
                  <h3 className="text-lg font-bold mb-2">
                    {' '}
                    {t('scan_qr.ms')}
                  </h3>
                  <img src={Qrcode} />
                </div>
              </div>
            </label>
          )}
          {/* <a
            className="text-black"
            href="https://app.adjust.com/10i1i8iv"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('download_app')}
          </a> */}
        </li>
        <li>
          <Link to="/about" className="text-black">
            {t('about')}
          </Link>
        </li>
        <li>
          <Link to="/dashboard" className="text-black">
            {t('dash_menu.home')}
          </Link>
        </li>
        {/* <li>
          <Link to="/setpassword?mode=change" className="text-black">
            {t('forgot.t_new')}
          </Link>
        </li> */}
        <li>
          <Link to="/term" className="text-black">
            {t('term_t')}
          </Link>
        </li>
        <li>
          <label onClick={openInNewTab} className="text-black">
            {t('privacy')}
          </label>
        </li>
        {/* <li>
          <Link to="/pdpa" className="text-black">
            {t('pdpa.personal')}
          </Link>
        </li> */}
        <div className="divider my-1"></div>
        <li onClick={() => logout()}>
          <a className="text-black">{t('logout')}</a>
        </li>
      </ul>
    </div>
  );
};

const MenuList = ({ t, isLogin, profile }) => {
  console.log('MenuList isLogin', isLogin);
  const { logout } = useContext(AuthContext);
  const navRight = `${isLogin
    ? 'grid grid-cols-2 gap-4 sm:grid-cols-2 '
    : 'grid grid-cols-1 gap-4 sm:grid-cols-4'
    } text-sm text-white`;

  return (
    <div className={navRight}>
      {!isLogin ? (
        <>
          <div className="flex flex-col justify-center items-center  shrink-0">
            <Link
              className="px-4 py-2 font-semibold text-sm text-white hover:text-[#E6564E] rounded-full shadow-sm"
              to="/about"
            >
              {t('about')}
            </Link>
          </div>

          <div className="flex flex-col justify-center items-center shrink-0">
            <Link
              className="px-4 py-2 font-semibold text-sm text-white hover:text-[#E6564E]  rounded-full shadow-sm"
              to="/login"
            >
              {t('login.t')}
            </Link>
          </div>
          <div className="flex flex-col justify-center items-center shrink-0">
            {/Android|webOS|iPhone|iPad/i.test(navigator.userAgent) ? (
              <a
                href="https://app.adjust.com/10i1i8iv"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="px-3 py-2 font-semibold bg-white hover:bg-[#E6564E] text-black hover:text-white rounded-full shadow-sm">
                  {t('download')}
                </div>
              </a>
            ) : (
              <label className="cursor-pointer">
                <div className="px-4 py-2 font-semibold text-sm bg-white hover:bg-[#E6564E] text-black hover:text-white rounded-full shadow-sm">
                  {t('download')}
                </div>
                <input
                  type="checkbox"
                  id="register-navbar"
                  className="modal-toggle"
                  htmlFor="register-navbar"
                />
                <div className="modal">
                  <div className="modal-box relative grid justify-items-center">
                    <label
                      htmlFor="register-navbar"
                      className="btn btn-sm btn-circle absolute right-2 top-2"
                    >
                      ✕
                    </label>
                    <h3 className="text-lg font-bold mb-2">
                      {' '}
                      {t('scan_qr.ms')}
                    </h3>
                    <img src={Qrcode} />
                  </div>
                </div>
              </label>
            )}

          </div>
          <SwitchLang />
        </>
      ) : (
        <>
          <SwitchLang />
          <DropDownMenu t={t} logout={logout} profile={profile} />
        </>
      )}
    </div>
  );
};

const NavBar = ({ hideMenu, isLogin, t, profile }) => {
  const NavBarClassName = `navbar bg-base-100 nav-home-bg fixed top-0 z-[70] ${isLogin ? ' shadow-xl ' : ''
    }`;
  return (
    <nav className={NavBarClassName}>
      <div className="navbar-start">
        <div className="navbar-start nav-logo">
          <Link to={isLogin ? '/dashboard' : '/'}>
            <img to={isLogin ? '/dashboard' : '/'} src={navLogo} alt="navLogo" />
          </Link>
        </div>
      </div>
      {/* Desktop Nav menu */}
      <div className="navbar-end hidden lg:flex">
        {hideMenu ? (
          <SwitchLang />
        ) : (
          <MenuList t={t} isLogin={isLogin} profile={profile} />
        )}
      </div>
      {/* Mobile Nav menu */}
      <div className="navbar-end lg:hidden flex justify-end">
        <label htmlFor="my-drawer" className="drawer-button mr-3">
          <MenuIcon
            htmlFor="my-drawer"
            className="block h-6 w-6"
            aria-hidden="true"
          />
        </label>
      </div>
    </nav>
  );
};

export default NavBar;
