import { useContext } from "react";
import { AuthContext } from "../../auth";
import { useTranslation } from "react-i18next";
import {
  CoinsIcon,
  RocketIcon,
  UsersIcon,
  PartnersIcon,
} from "../../assets/icon";

import { MainContainer, Footer } from "../../layout";
import { AboutSection, CustomerSection, PartnerSection } from "../../components";
import {
  customerPart1,
  customerPart2,
  customerPart3,
  partner,
} from "../../assets";

const AboutPage = () => {
  const { isLogin } = useContext(AuthContext);
  const { t } = useTranslation();

  return (
    <>
      <MainContainer hideMenu={true} isLogin={isLogin}>
        <div className="pt-20 px-10 bg-[#F2F2F2]">
          <div className="flex justify-center">
            <p className="text-3xl text-[#E6564E] text-center leading-loose lg:leading-loose">
              {t("home.section_two.desc")}
            </p>
          </div>
          <div className="flex flex-row flex-wrap mt-16 mb-16">
            <AboutSection
              icon={CoinsIcon}
              top={t("home.section_two.what.top")}
              sec={t("home.section_two.what.desc")}
            />
            <AboutSection
              icon={RocketIcon}
              top={t("home.section_two.our_goal.top")}
              sec={t("home.section_two.our_goal.desc")}
            />
            <CustomerSection
              icon={UsersIcon}
              img={[customerPart1, customerPart2, customerPart3]}
              top={t("home.section_two.customer_group.top")}
              sec={t("home.section_two.customer_group.desc")}
              sec2={t("home.section_two.customer_group.desc2")}
            />
            <CustomerSection
              icon={PartnersIcon}
              img={[partner]}
              top={t("home.section_two.partner.top")}
              sec={t("home.section_two.partner.desc")}
            />
          </div>
        </div>
        <PartnerSection t={t} />
        <Footer />
      </MainContainer>
    </>
  );
};
export default AboutPage;
