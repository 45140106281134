import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";
import { useState } from "react";
import { Switch } from "@headlessui/react";
import './SwitchLang.scss';
// import './swichx.scss'

const SwitchX = ({ isOn, valueChange, onColor }) => {
  const swichChange = () => {
    console.log('swichChange value',isOn)
    // valueChange(isOn);
  };

  return (
    <div onClick={swichChange}> 
      <label
        className="react-switch-label"
        htmlFor={`react-switch-new`}
      >
        <span className={`react-switch-button`} > {isOn?'EN':'TH'}</span>
      </label>
    </div>
  );
};

const SwitchLang = () => {

  const { i18n } = useTranslation();
  const getLang = (lang) => {
    let shortLang = lang;
    if (shortLang.indexOf("-") !== -1) {
      shortLang = shortLang.split("-")[0];
    }

    if (shortLang.indexOf("_") !== -1) {
      shortLang = shortLang.split("_")[0];
    }
    return shortLang;
  };

  let [lang, setLang] = useState(getLang(i18n.language));
  const getSwitchValueByLang = (lang)=> lang.toUpperCase() === 'EN' ? true : false;
  const [isEnabled, setIsEnabled] = useState(getSwitchValueByLang(lang));

  const valueChange = (val)=>{
    setIsEnabled(val);
    if(val){
      changeLanguage('en');
    }else{
      changeLanguage('th');
    }
  }
  return (
    <div className="flex justify-center items-center h">
      <Switch className="block h-10 w-10" checked={isEnabled} onChange={valueChange}>
          <div className="block switch-box">
            <span
              className={`switch-bt text-font text-white block h-full w-1/2 transition duration-100 ease-in-out transform text-sm ${
                isEnabled ? "bg-[#49494A] translate-x-full" : "bg-[#49494A]"
              }`}
            >
              {isEnabled ? "EN" : "TH"}
            </span>
            <div className="switch-line"></div>
          </div>
        </Switch>
    </div>
  );
};

export default SwitchLang;
