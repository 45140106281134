import { useTranslation } from 'react-i18next';
import { CoinIcon } from '../../assets';
import Loading from '../../components/loading';
import { useWallet } from '../../contexts/WalletContext';
import styles from './History.module.scss';
import { numberWithCommas, displayPhone } from '../../utils/number';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import InfiniteScroll from 'react-infinite-scroll-component';

const ItemsHistory = ({ item = {} }) => {
  const { t } = useTranslation();
  const { transaction_from, created_at, transaction_to, amount, description, convert_type } = item;
  const isPositive = convert_type === 'EARN';
  const symbol = isPositive ? '+' : '-';
  const colorSymbol = isPositive ? 'text-[#0103D1]' : 'text-[#ED1C24]';

  return (
    <div className={`collapse collapse-arrow ${styles.item}`}>
      <input type="checkbox" className="peer" />
      <div className="collapse-title">
        <div className="flex justify-between">
          <div className="flex flex-col items-start">
            <div className="text-[#171717]">{isPositive ? transaction_from : transaction_to}</div>
            <div className="text-[#8E93A5]">
              {dayjs.unix(created_at).format('DD MMM YYYY HH:mm')}
            </div>
          </div>
          <div className="flex items-center">
            <span className={`font-bold mx-1 ${colorSymbol}`}>
              {`${symbol} ${numberWithCommas(amount)}`}
            </span>
            <img src={CoinIcon} className="aspect-square w-5 flex-none" alt='token' />
          </div>
        </div>
      </div>
      <div className="collapse-content">
        <div className={`${styles.line} mb-4`}></div>
        <div className="text-[#171717] text-left text-[12px]">
          {isPositive ? (
            <>
              <div>
                {t('sbm.wallet.from')}:{' '}
                {displayPhone({ phone: transaction_from, showNumber: 3 })}
              </div>
              <div>
                {t('sbm.wallet.to')}: {transaction_to}
              </div>
              <div>
                {t('sbm.wallet.des')}: {description}
              </div>
            </>
          ) : (
            <>
              <div>
                {t('sbm.wallet.from')}:{' '}
                {displayPhone({ phone: transaction_from, showNumber: 3 })}
              </div>
              <div>
                {t('sbm.wallet.to')}: {transaction_to}
              </div>
              <div>
                {t('sbm.wallet.des')}: {description}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const ItemList = ({ data = [], loadMore, hasMore }) => {
  if (data.length === 0) {
    return (
      <div>
        <p className="text-[#8E93A5] text-center">ไม่มีรายการ</p>
      </div>
    );
  }
  return (
    <InfiniteScroll
      dataLength={data.length}
      next={loadMore}
      hasMore={hasMore}
      loader={<Loading />}
      scrollableTarget="scrollableDiv"
      endMessage={<h1>Not Data</h1>}
    >
      {data.map((item, keys) => (
        <ItemsHistory key={keys} item={item} />
      ))}
    </InfiniteScroll>
  );
};

const TabBox = ({ item }) => {
  dayjs.extend(utc);

  const { t } = useTranslation();
  const { earn, burn, loadMore, tab, setTab, hasMore, getHistory } = useWallet();

  console.log('earn', earn);
  console.log('burn', burn);

  const onChangeTab = async (type) => {
    setTab(type);
    await getHistory(type);
  }

  return (
    <>
      <div className="tabs">
        <div
          className={`tab tab-bordered ${tab === 'earn' ? `tab-active ${styles.tab_active}` : null
            } w-1/2`}
          onClick={() => onChangeTab('earn')}
        >
          {t('sbm.h.earn')}
        </div>
        <div
          className={`tab tab-bordered ${tab === 'burn' ? `tab-active ${styles.tab_active}` : null
            }  w-1/2`}
          onClick={() => onChangeTab('burn')}
        >
          {t('sbm.h.burn')}
        </div>
      </div>
      <div id="scrollableDiv" className={`container p-5 ${styles.content}`}>
        {tab === 'earn' ? (
          <ItemList data={earn} loadMore={loadMore} hasMore={hasMore} />
        ) : (
          <ItemList data={burn} loadMore={loadMore} hasMore={hasMore} />
        )}
      </div>
    </>
  );
};

const HistoryContainer = () => {
  const { history, initialLoading } = useWallet();

  if (initialLoading) return <Loading />;

  return (
    <>
      <TabBox />
    </>
  );
};
export default HistoryContainer;
