import KycContainer from '../features/kyc/KycContainer';
import Header from '../layout/header/Header';
import { useTranslation } from 'react-i18next';

const KycPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header title={t('line.kyc.title')} showBack />
      <KycContainer />
    </>
  );
};
export default KycPage;
