import { ArrowLeftIcon } from '@heroicons/react/outline';
import { Link, NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom/dist';
import { LogoFull, CoinIcon, SearchIcon, Token, IsKyc } from '../../assets';
import { useAuth } from '../../contexts/AuthContext';
import { numberWithCommas } from '../../utils/number';
import styles from './Header.module.scss';
import { WalletMenu } from '../../assets/menu';

const Balance = () => {
  const { balance } = useAuth();
  return (
    <>
      <div className={styles.balance}>
        <div className={styles.box}>
          <div className="flex mr-3">
            <img
              src={CoinIcon}
              className="aspect-square w-10 flex-none"
              alt="coin icon"
            />
          </div>
          <div className="flex flex-col justify-start items-start">
            <div className="text-[#181617] text-[14px]">SpeedKUB</div>
            <div className="flex flex-row justify-start items-center w-full">
              <div className="text-[#181617] font-kanitBold text-[20px] text-lg mr-2 ">
                {numberWithCommas(balance?.balance ?? 0)}
              </div>
              <div className="text-[#181617]">SKS</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const BackMenu = () => {
  const navigate = useNavigate();
  return (
    <div className={`flex w-full py-3 cursor-pointer px-5  ${styles.back}`}>
      <div
        className="flex justify-center items-center"
        onClick={() => navigate(-1)}
      >
        <ArrowLeftIcon className="aspect-square w-5" aria-hidden="true" />
      </div>
    </div>
  );
};

function Header({
  title,
  showBalance = false,
  showBack = false,
  search = false
}) {
  const { balance, user } = useAuth();
  const navigate = useNavigate();

  if (title && showBack) {
    return (
      <div className="flex justify-between items-center py-4 px-4 gap-3 relative">
        {showBack ? (
          <div className="w-5">
            <BackMenu />
          </div>
        ) : null}
        {title ? (
          <div className="text-[18px] text-left font-bold grow">{title}</div>
        ) : null}
      </div>
    );
  }

  return (
    <div className="flex justify-between items-center py-4 px-4 relative">
      <div>
        {title ? (
          <div className="text-[18px] font-bold">{title}</div>
        ) : showBack ? (
          <BackMenu />
        ) : (
          <img src={LogoFull} className="h-6" alt="logo" />
        )}
      </div>
      {showBalance ? (
        <div className="flex flex-nowrap items-center gap-2">
          <div>
            {search ? (
              <img
                src={SearchIcon}
                className="h-6"
                alt="logo"
                onClick={() => {
                  navigate('/line/search');
                }}
              />
            ) : null}
          </div>
          <div className="flex flex-nowrap gap-1 bg-[#38383C] box-balance rounded-[100px] p-1">
            {user?.is_kyc ? (
              <img src={IsKyc} className="h-6" alt="logo" />
            ) : (
              <img src={WalletMenu} className="h-6" alt="logo" />
            )}
            <span className="text-white">
              {numberWithCommas(balance?.balance ?? 0)}
            </span>
            <img src={Token} className="h-6" alt="logo" />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Header;
