import RegisterContainer from '../features/register/RegisterContainer';

const RegisterPage = () => {
  return (
    <>
      {/* <RegisterContextProvider> */}
      <RegisterContainer />
      {/* </RegisterContextProvider> */}
    </>
  );
};
export default RegisterPage;
