import { useContext } from 'react';
import { MainContainer } from '../../layout';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../auth';
import CoinHome from '../../assets/coin.png';
import { ClipboardCopyIcon } from '@heroicons/react/solid';
import ConfigApi from '../../services/config';
import { QRCodeGenerator } from '../../components';

import './refer.scss';

const copyRefer = () => {
  /* Get the text field */
  const el = document.getElementById('refer-number');

  const range = document.createRange();
  range.selectNodeContents(el);
  const sel = window.getSelection();
  sel.removeAllRanges();
  sel.addRange(range);

  navigator.clipboard.writeText(el.innerHTML);
};

const copyReferLink = () => {
  /* Get the text field */
  const el = document.getElementById('refer-link');

  const range = document.createRange();
  range.selectNodeContents(el);
  const sel = window.getSelection();
  sel.removeAllRanges();
  sel.addRange(range);

  navigator.clipboard.writeText(el.innerHTML);
};

const ReferPage = () => {
  const { t } = useTranslation();
  const { isLogin, profile, referUrl, isKyc } = useContext(AuthContext);

  return (
    <MainContainer isLogin={isLogin} hideMenu={true}>
      <>
        <div className="bg-refer">
          <div className="h-screen flex justify-center items-center p-5 mt-16 mb-48 md:mt-16 md:mb-20 lg:mt-0 lg:mb-0 xl:mt-0 xl:mb-0">
            <div className="h-full lg:h-min-screen lg:flex flex-col justify-center items-center">
              <div className="flex flex-col justify-center items-center lg:hidden sm:flex md:flex w-full">
                <div className="w-full sm:w-[20rem] md:w-[25rem] lg:w-[25rem] bg-white py-4">
                  {referUrl ? (
                    <QRCodeGenerator value={referUrl} hideBarCode={true} />
                  ) : null}
                </div>
              </div>
              <div className="flex justify-center items-center flex-wrap">
                <img
                  className="mask mask-circle w-16"
                  alt="speedkub-sks"
                  src={CoinHome}
                />
                <p className="text-3xl text-[#E6564E] text-center leading-loose lg:leading-loose mr-7">
                  {t('refer.title')}
                </p>
              </div>
              <div className="flex-col w-full">
                <div className="flex-col mt-5 md:mt-10">
                  <div className="text-left">{t('refer.ref_code')}</div>
                  <div className="refer-field flex justify-between items-center border-2 rounded-md border-slate-600 px-2 py-1 w-full">
                    <span className="line-height-4" id="refer-number">
                      {isKyc ? profile?.referral : t('refer.p_kyc')}
                    </span>
                    {
                      isKyc ? <button
                        className="btn rounded-lg btn-xs gap-2"
                        onClick={isKyc ? copyRefer : () => { }}
                      >
                        {t('refer.copy')}
                        <ClipboardCopyIcon
                          className="bg-transparent w-4"
                          aria-hidden="true"
                        ></ClipboardCopyIcon>
                      </button> : null
                    }
                  </div>
                </div>

                {/* <div className="flex-col mt-5">
                  <div className="text-left">{t('refer.ref_link')}</div>
                  <div className="refer-field flex justify-between items-center border-2 rounded-md border-slate-600 px-2 py-1 w-full">
                    <span className="line-height-4" id="refer-link">
                      {isKyc ? referUrl : t('refer.p_kyc')}
                    </span>
                    {
                      isKyc ? <button
                        className="btn rounded-lg btn-xs gap-2"
                        onClick={copyReferLink}
                      >
                        {t('refer.copy')}
                        <ClipboardCopyIcon
                          className="bg-transparent w-4"
                          aria-hidden="true"
                        ></ClipboardCopyIcon>
                      </button> : null
                    }

                  </div>
                </div> */}
              </div>
            </div>
            {
              isKyc ? <div className="mt-10 ml-5 mb-5 float-left hidden lg:flex">
                <div className="w-[20rem] bg-white py-4">
                  {/* {referUrl ? (
                  <QRCodeGenerator value={referUrl} hideBarCode={true} />
                ) : null} */}
                  {profile?.referral ? (
                    <QRCodeGenerator value={profile?.referral} hideBarCode={true} />
                  ) : null}
                </div>
              </div> : null
            }
          </div>
        </div>
      </>
    </MainContainer>
  );
};
export default ReferPage;
