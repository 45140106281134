import axios from 'axios';
import ConfigApi from './config';
import { removeLocalStorage } from '../utils/localStorage';

const appConfig = new ConfigApi(process.env);
const BASE_URI = appConfig.baseUrl;
const API_KEY = appConfig.apiKey;

const axiosSBM = axios.create({
  baseURL: BASE_URI
});

axiosSBM.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('sbm_t') ?? '';
    // config.headers['api-key'] = API_KEY;
    // 'Content-Type': 'application/json',

    // config.headers['Access-Control-Allow-Origin'] = '*';
    config.headers['Content-Type'] = 'application/json';
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }
  // (error) => Promise.reject(error)
);

axiosSBM.interceptors.request.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === '401') {
      removeLocalStorage();
      return window.location.replace('/sabuymoney');
    }
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      removeLocalStorage();
      // const access_token = await refreshAccessToken();
      // axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
      return axiosSBM(originalRequest);
    }
    return Promise.reject(error);
  }
);

// axios.interceptors.response.use(
// 	(response) => {
// 		console.log(response);
// 		return response;
// 	},
// 	(err) => {
// 		console.log(err);
// 		if (err.response.status === '401') {
// 			removeLocalStorage();
// 			return window.location.replace('/');
// 		}
// 	}
// );

export default axiosSBM;
