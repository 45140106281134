import KycSuccess from '../features/kyc-success/kyc-success';

const KycSuccessPage = () => {
  return (
    <>
      <KycSuccess />
    </>
  );
};
export default KycSuccessPage;
