import { useTranslation } from 'react-i18next';
import { PowerBy } from '../components';
import WalletContextProvider from '../contexts/WalletContext';
import HistoryContainer from '../features/history/HistoryContainer';
import Container from '../layout/Container';
import Footer from '../layout/footer/Footer';
import Header from '../layout/header/Header';

const HistoryPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header title={t('sbm.history')} showBalance={true} />
      <PowerBy />
      <Container>
        <WalletContextProvider>
          <HistoryContainer />
        </WalletContextProvider>
      </Container>
      <Footer />
    </>
  );
};
export default HistoryPage;
