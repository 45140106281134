import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LogoGrey from '../../assets/logo-gray.png';
import { FacebookIcon, LineIcon } from '../../assets/icon';
import { AuthContext } from '../../auth';
import { openInNewTab } from '../../utils';
import DownloadApp from '../../assets/download.png';
import Qrcode from '../../assets/qr_download-new.png';

const SocialFooter = () => {
  return (
    <>
      <a
        href="https://lin.ee/KH6FkOc"
        target="_blank"
        rel="noreferrer noopener"
      >
        <LineIcon />
      </a>
      <a
        href="https://www.facebook.com/SabuyDigital"
        target="_blank"
        rel="noreferrer noopener"
      >
        <FacebookIcon />
      </a>
    </>
  );
};

const Footer = (props) => {
  const { t } = useTranslation();
  const { logout, isLogin } = useContext(AuthContext);

  return (
    <div className="footer p-4 text-neutral-content bg-[#49494A] pb-20 flex justify-center items-center">
      <div className='container mx-auto w-full'>
        <div className="grid xs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5 w-full">
          <div className="w-full flex flex-col xs:items-center lg:items-center gap-5">
            <Link className="w-2/2" to="/">
              <img
                to="/"
                className="w-48 md:w-48 lg:w-48"
                src={LogoGrey}
                alt="logo-footer"
              />
            </Link>
            <div className="w-2/2 flex gap-4">
              <SocialFooter />
            </div>
          </div>
          <div className="flex flex-col xs:items-center lg:items-start mr-5 gap-4 ">
            <Link className="link link-hover" to="/about">
              {t('footter.contact_me')}
            </Link>
            <Link className="link link-hover" to="/login">
              {t('footter.login')}
            </Link>
            {/Android|webOS|iPhone|iPad/i.test(navigator.userAgent) ? (
              <a
                href="https://app.adjust.com/10i1i8iv"
                target="_blank"
                rel="noopener noreferrer"
              >

                <div className="link link-hover" >
                  {t('download_app')}
                </div>
              </a>
            ) : (
              <label className="cursor-pointer">
                <div className="link link-hover">
                  {t('download_app')}

                </div>
                <input
                  type="checkbox"
                  id="register_footer"
                  className="modal-toggle"
                  htmlFor="register_footer"
                />
                <div className="modal">
                  <div className="modal-box relative grid justify-items-center">
                    <label
                      htmlFor="register_footer"
                      className="btn btn-sm btn-circle absolute right-2 top-2"
                    >
                      ✕
                    </label>
                    <h3 className="text-lg font-bold mb-2">
                      {' '}
                      {t('scan_qr.ms')}
                    </h3>
                    <img src={Qrcode} />
                  </div>
                </div>
              </label>
            )}

          </div>
          <div className="flex flex-col xs:items-center lg:items-start gap-4 ">
            <Link className="link link-hover" to="/term">
              {t('footter.term')}
            </Link>
            {isLogin ? (
              <div className="link link-hover" onClick={() => logout()}>
                {t('footter.logout')}
              </div>
            ) : null}
            <a
              className="link link-hover"
              href="https://www.facebook.com/SabuyDigital"
              target="_blank"
              rel="noreferrer noopener"
            >
              {t('footter.about')}
            </a>
            <label onClick={openInNewTab} className="link link-hover">
              {t('privacy')}
            </label>
          </div>
          <div className='flex flex-col xs:items-center lg:items-center gap-4 '>
            <div className="w-32">
              <div className="xxs:flex xs:flex sm:hidden md:flex lg:flex">
                <a
                  href="https://app.adjust.com/10i1i8iv"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={DownloadApp} alt="download-app-android" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
