import React, { useContext, useEffect, useRef } from 'react';
import { Content, MainContainer } from '../../layout';

import { useTranslation } from 'react-i18next';
import RewardsContent from '../../layout/rewards';
import { AuthContext } from '../../auth';
import { ConsentSetup, getAllConsent, getPhoneByToken } from '../../utils';
import { DashboardPDPA } from '../../layout/term/pdpa';
import { PopupDownloadApp } from '../../components';
import PopupDownloadBox from '../../components/DownloadAppBox';
import DownloadAppBox from '../../components/DownloadAppBox';

const DashboardPage = () => {
  const { t } = useTranslation();

  const {
    isLogin,
    phoneNumber,
    setPhoneNumber,
    token,
    initProfile,
    consentDataDashboard,
    setConsentDataDashboard,
    isLoading
  } = useContext(AuthContext);

  console.log('DashboardPage isLogin', isLogin);

  const handlePDPA = async () => {
    // const phone = await getPhoneByToken(token);
    const phone = phoneNumber;
    if (phone) {
      const res = await ConsentSetup(phone);
      console.log('DashboardPage getAllConsent', res);
      setConsentDataDashboard(res);
      console.log('DashboardPage consentData login', consentDataDashboard);
    }
  };

  const initProcessConsent = async () => {
    console.log('processConsent===>', consentDataDashboard); //handle Consent data register call consent api
    if (consentDataDashboard?.result?.length) {
      // when has show windows 1
      const filter_consent1 = consentDataDashboard?.result.filter(
        (i) => i.id === 'CONSENT_CS22001'
      );
      if (filter_consent1?.length) {
        document.getElementById(filter_consent1[0]?.id).click();
      } else {
        // when has show windows 2 after agree 1
        document.getElementById(consentDataDashboard?.result[0].id).click();
      }
    }
  };

  useEffect(() => {
    let mounted = true;

    console.log('useEffect consentDataDashboard', consentDataDashboard);
    const callApi = async () => {
      console.log('setLoading consentData', consentDataDashboard);
      console.log('isLogin', isLogin);
      console.log('callApi phoneNumber', phoneNumber);
      if (isLogin) {
        if (consentDataDashboard === undefined) {
          await handlePDPA();
        } else {
          await initProcessConsent();
        }
      }
    };

    const initPhone = async () => {
      // const phone = await getPhoneByToken(token);
      const profileRes = await initProfile(token);
      console.log('profile xxxxxx', profileRes?.username);
      const phone = profileRes?.username;
      setPhoneNumber(phone);
      // setPhoneNumber('0875551605');
      await callApi();
    };

    if (phoneNumber) {
      callApi();
    } else {
      initPhone();
    }

    return () => (mounted = false);
  }, [isLogin, token, phoneNumber, consentDataDashboard]);

  if (isLoading) {
    return <></>;
  }

  return (
    <MainContainer isLogin={isLogin} hideMenu={true}>
      <Content>
        <>
          <RewardsContent t={t} title={'Speedkub SKS.'} phone={phoneNumber} />
        </>
      </Content>
      {phoneNumber ? <DashboardPDPA phone={phoneNumber} /> : null}
    </MainContainer>
  );
};
export default DashboardPage;
