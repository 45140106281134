import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import './main.scss';

import App from './App';
import Snowfall from 'react-snowfall';

// // eslint-disable-next-line
// import "swiper/css/bundle";

// import i18n (needs to be bundled ;))
import './i18n';
import { SavePopup } from './speedkub/utils';
SavePopup('open');

if (process.env.REACT_APP_PROJECT_NAME === 'speedkub-prod') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const container = document.getElementById('root');
const root = createRoot(container);
const snow = false;
const app = (
  // <StrictMode>
  <BrowserRouter>
    {snow ? (
      <Snowfall
        snowflakeCount={244}
        speed={[0.5, 2.5]}
        wind={[-0.5, 2.0]}
        radius={[0.5, 3.0]}
        style={{ zIndex: 500 }}
      />
    ) : null}
    <App />
  </BrowserRouter>
  // </StrictMode>
);
root.render(app);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
