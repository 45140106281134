import { useTranslation } from 'react-i18next';
import { CoinIcon } from '../../assets';
import Loading from '../../components/loading';
import { useWallet } from '../../contexts/WalletContext';
import styles from './Wallet.module.scss';
import { numberWithCommas, displayPhone } from '../../utils/number';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import InfiniteScroll from 'react-infinite-scroll-component';
import { cn } from '../../utils/css';
import { useEffect, useRef, useState } from 'react';

const ItemsWallet = ({ item = {} }) => {
  const { t } = useTranslation();
  const {
    transaction_from,
    created_at,
    transaction_to,
    amount,
    description,
    convert_type
  } = item;
  const isPositive = convert_type === 'EARN';
  const symbol = isPositive ? '+' : '-';
  const colorSymbol = isPositive ? 'text-[#39CA5F]' : 'text-[#ED1C24]';

  return (
    <div className={`collapse collapse-arrow ${styles.item}`}>
      <input type="checkbox" className="peer" />
      <div className="collapse-title">
        <div className="flex justify-between">
          <div className="flex flex-col items-start">
            <div className="text-white font-kanitLight">
              {isPositive ? transaction_from : transaction_to}
            </div>
            <div className="text-[#D0D1DA] font-kanitLight">
              {dayjs.unix(created_at).format('DD MMM YYYY HH:mm')}
            </div>
          </div>
          <div className="flex items-center">
            <span className={`font-bold mx-1 ${colorSymbol}`}>
              {`${symbol} ${numberWithCommas(amount)}`}
            </span>
            <img
              src={CoinIcon}
              className="aspect-square w-5 flex-none"
              alt="token"
            />
          </div>
        </div>
      </div>
      <div className="collapse-content">
        <div className={`${styles.line} mb-4`}></div>
        <div className="text-white text-left text-[12px]">
          {isPositive ? (
            <>
              <div className="font-kanitLight">
                {t('line.wallet.from')}:
                {displayPhone({ phone: transaction_from, showNumber: 3 })}
              </div>
              <div className="font-kanitLight">
                {t('line.wallet.to')}: {transaction_to}
              </div>
              <div className="font-kanitLight">
                {t('line.wallet.des')}: {description}
              </div>
            </>
          ) : (
            <>
              <div className="font-kanitLight">
                {t('line.wallet.from')}:
                {displayPhone({ phone: transaction_from, showNumber: 3 })}
              </div>
              <div className="font-kanitLight">
                {t('line.wallet.to')}: {transaction_to}
              </div>
              <div className="font-kanitLight">
                {t('line.wallet.des')}: {description}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const ItemList = ({ data = [], loadMore, hasMore }) => {
  if (data.length === 0) {
    return (
      <div>
        <p className="text-[#8E93A5] text-center">ไม่มีรายการ</p>
      </div>
    );
  }
  return (
    <InfiniteScroll
      className="overflow-hidden scroll-ui-hide"
      dataLength={data.length}
      next={loadMore}
      hasMore={hasMore}
      loader={<Loading />}
      scrollableTarget="scrollableDiv"
      endMessage={<h1>Not Data</h1>}
    >
      {data.map((item, keys) => (
        <ItemsWallet key={keys} item={item} />
      ))}
    </InfiniteScroll>
  );
};

const TabBox = ({ item }) => {
  dayjs.extend(utc);
  const [height, setHeight] = useState(0);

  const { t } = useTranslation();
  const { transaction, loadMore, tab, setTab, hasMore, getWallet } =
    useWallet();

  const onChangeTab = async (type) => {
    setTab(type);
    await getWallet(type);
  };

  const boxScroll = useRef();

  useEffect(() => {
    setHeight(boxScroll.current.clientHeight);
  }, []);

  return (
    <>
      <div className="flex flex-col grow">
        <div className="p-4 flex gap-3">
          <div
            onClick={() => onChangeTab('total')}
            className={cn(
              ' py-2 bg-[#ffffff] text-[#E6564E] w-1/2 rounded-full',
              tab === 'total' && 'bg-[#E6564E] text-white'
            )}
          >
            {t('line.h.total')}
          </div>
          <div
            onClick={() => onChangeTab('earn')}
            className={cn(
              ' py-2 bg-[#ffffff] text-[#E6564E] w-1/2 rounded-full',
              tab === 'earn' && 'bg-[#E6564E] text-white'
            )}
          >
            {t('line.h.earn')}
          </div>
          <div
            onClick={() => onChangeTab('burn')}
            className={cn(
              'py-2 bg-[#ffffff] text-[#E6564E] w-1/2 rounded-full',
              tab === 'burn' && 'bg-[#E6564E] text-white'
            )}
          >
            {t('line.h.burn')}
          </div>
        </div>

        {height ? (
          <div
            id="scrollableDiv"
            className={` p-5 overflow-y-scroll scroll-ui ${styles.content}`}
            style={{ height: `${height - 68}px` }}
          >
            <ItemList
              data={transaction}
              loadMore={loadMore}
              hasMore={hasMore}
            />
          </div>
        ) : (
          <div ref={boxScroll} className="grow">
            <Loading />
          </div>
        )}
      </div>
    </>
  );
};

const WalletContainer = () => {
  const { initialLoading } = useWallet();

  if (initialLoading) return <Loading />;

  return (
    <>
      <TabBox />
    </>
  );
};
export default WalletContainer;
