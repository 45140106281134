import { ChevronLeftIcon } from '@heroicons/react/outline';
import { Link, NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom/dist';
import { HeaderMenu, CoinIcon } from '../../assets';
import { useAuth } from '../../contexts/AuthContext';
import { numberWithCommas } from '../../utils/number';
import styles from './Header.module.scss';

const Balance = () => {
  const { balance } = useAuth();
  return (
    <>
      <div className={styles.balance}>
        <div className={styles.box}>
          <div className="flex mr-3">
            <img src={CoinIcon} className="aspect-square w-10 flex-none" alt='coin icon' />
          </div>
          <div className="flex flex-col justify-start items-start">
            <div className="text-[#181617] text-[14px]">SpeedKUB</div>
            <div className="flex flex-row justify-start items-center w-full">
              <div className="text-[#181617] font-kanitBold text-[20px] text-lg mr-2 ">
                {numberWithCommas(balance?.balance ?? 0)}
              </div>
              <div className="text-[#181617]">SKS</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const BackMenu = () => {
  const navigate = useNavigate();
  return (
    <div
      className={`flex w-full py-3 cursor-pointer px-5 mt-auto ${styles.back}`}
    >
      <div
        className="flex justify-center items-center"
        onClick={() => navigate(-1)}
      >
        <ChevronLeftIcon className="aspect-square w-8 " aria-hidden="true" />
      </div>
    </div>
  );
};

function Header({ title, showBalance = false, showBack = false }) {
  const topTitle = showBalance ? '40%' : '50%';
  return (
    <>
      <div className={`sticky top-0 z-[100]`}>
        {showBack ? <BackMenu /> : null}
        <div className={`${styles.bg_size}`}>
          <img src={HeaderMenu} alt='powerby' />
        </div>

        <h3 className={`${styles.title}`} style={{ top: topTitle }}>
          {title}
        </h3>
        {showBalance ? <Balance /> : <div></div>}
      </div>
    </>
  );
}

export default Header;
