import {
  getConsent,
  getConsentParties,
  getSecretConsent,
  getTokenConsent
} from '../api/pdpaApi.js';
import { Buffer } from 'buffer';
import * as crypto from 'crypto';
import jwt_decode from 'jwt-decode';

export const getConsentTokenLocal = () => {
  const tokenString = localStorage.getItem('t_c');
  const userToken = JSON.parse(tokenString);
  return userToken;
};

export const saveConsentTokenLocal = async (userToken) => {
  localStorage.setItem('t_c', JSON.stringify(userToken));
};

export const resetConsentTokenLocal = async () => {
  localStorage.removeItem('t_c');
  const res = await getTokenConsent();
  await saveConsentTokenLocal(res?.access_token);
};

export const ConsentSetup = async (phoneNumber) => {
  let dataConsent;
  const { encryption_secret } = await getSecretConsent();
  const res = await getConsent(phoneNumber, 'active');
  dataConsent = await pef1_decrypt(encryption_secret, res);
  if (dataConsent && encryption_secret) {
    return { ...dataConsent, encryption_secret };
  }
};

export const getAllConsent = async (phoneNumber) => {
  let dataConsent;
  const { encryption_secret } = await getSecretConsent();
  const res = await getConsentParties(phoneNumber);
  dataConsent = await pef1_decrypt(encryption_secret, res);

  // Filter channel with SpeedKub
  if (dataConsent.consent.length > 0) {
    const newConsent = dataConsent.consent.filter(
      (i) => i.channel === 'SpeedKub'
    );
    dataConsent.consent = newConsent;
  }

  if (dataConsent && encryption_secret) {
    return { ...dataConsent, encryption_secret };
  }
};

export const getPhoneByToken = async (token) => {
  let phone = '';
  if (token) {
    const { phone_number } = await jwt_decode(token);
    phone = phone_number.replace('+66', '0');
  }
  return phone;
};

export const pef1_decrypt = async (secret, encryptedText) => {
  const msg = encryptedText;
  const salt = new Buffer(msg.s, 'base64');
  const iv = new Buffer(msg.i, 'base64');
  const tag = new Buffer(msg.t, 'base64');
  const cipherText = msg.d;
  const key = crypto.createHmac('sha256', secret).update(salt).digest();
  let decipher = crypto.createDecipheriv('aes-256-gcm', key, iv);
  decipher.setAuthTag(tag);
  const decipherText =
    decipher.update(cipherText, 'base64', 'utf8') + decipher.final('utf8');
  console.log(
    'pef1_decrypt JSON.parse(decipherText)',
    JSON.parse(decipherText)
  );
  return JSON.parse(decipherText);
};

export const pef1_encrypt = async (secret, plainText) => {
  const iv = crypto.randomBytes(12);
  const salt = crypto.randomBytes(16);
  const key = crypto.createHmac('sha256', secret).update(salt).digest();
  const cipher = crypto.createCipheriv('aes-256-gcm', key, iv);
  let cipherText =
    cipher.update(plainText, 'utf8', 'base64') + cipher.final('base64');
  const tag = cipher.getAuthTag();
  const msgBody = {
    f: 'pef1',
    s: salt.toString('base64'),
    i: iv.toString('base64'),
    t: tag.toString('base64'),
    d: cipherText
  };
  console.log(JSON.stringify(msgBody));
  return JSON.stringify(msgBody);
};
