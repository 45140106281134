import { useState } from 'react';
import * as customerApi from '../../api/customerApi';
import { IMaskInput } from 'react-imask';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import { useAuth } from '../../contexts/AuthContext';

const schema = yup
  .object({
    name: yup.string().required('name is required'),
    surname: yup.string().required('surname is required'),
    id: yup.string().required('id is required'),
    laser: yup.string().required('laser is required'),
    hbd: yup.string().required('hbd is required'),
    gender: yup.string().required('gender is required')
  })
  .required();

const KycContainer = () => {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState();
  const [startDate, setStartDate] = useState();
  const { reloadUserInfo } = useAuth();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    defaultValues: {
      name: '',
      surname: '',
      id: '',
      laser: '',
      hbd: '',
      gender: '',
      email: ''
    },
    resolver: yupResolver(schema)
  });

  const { t } = useTranslation();

  const onKyc = async (fromData) => {
    console.log('fromData', fromData);
    try {
      setErrorMsg(undefined);
      const payload = {
        citizen_id: fromData?.id,
        first_name: fromData?.name,
        last_name: fromData?.surname,
        date_of_birth: dayjs(startDate).add(543, 'y').format('DD/MM/YYYY'),
        lazer_code: fromData?.laser.replaceAll('-', ''),
        gender: fromData?.gender
      };
      console.log('payload', payload);
      const res = await customerApi.verifyKyc(payload);
      console.log('res', res);
      console.log('res.data', res?.data);
      console.log('res?.data?.status', res?.data?.status);
      if (res?.data?.status === 200) {
        const params = {
          reward: res?.data?.data?.reward ?? 0
        };

        await reloadUserInfo();

        navigate({
          pathname: '/line/kyc-success',
          replace: true,
          search: `?${createSearchParams(params)}`
        });
      }
      console.log('onKyc', res);
      // const phone = `08${Math.floor(Math.random() * 100000000)}`;
    } catch (error) {
      console.log('error.response.data.message', error.response.data.message);
      setErrorMsg(error.response.data.message);
    }
  };

  return (
    <>
      <form
        className="flex flex-col w-full h-screen gap-5 pb-5"
        onSubmit={handleSubmit(onKyc)}
      >
        <div className=" bg-[#222225] p-4 rounded-lg mx-4 flex flex-col gap-3">
          <div className="text-white font-bold text-[20px]">
            {t('line.kyc.c')}
          </div>
          {errorMsg && (
            <div className="text-[#FC4D55] text-center">{errorMsg}</div>
          )}
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col justify-start w-full">
                <label className="text-left font-normal">
                  {t('line.kyc.name')}
                </label>
                <input
                  {...field}
                  autoComplete=""
                  className="input w-full border border-[#D0D1DA] rounded-lg bg-transparent  px-4 py-3"
                  placeholder={t('line.kyc.name')}
                />
                {errors?.name && (
                  <div className="text-[#FC4D55] text-left ml-3">
                    {errors?.name.message}
                  </div>
                )}
              </div>
            )}
          />
          <Controller
            name="surname"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col justify-start w-full">
                <label className="text-left font-normal">
                  {t('line.kyc.surname')}
                </label>
                <input
                  {...field}
                  autoComplete=""
                  className="input w-full border border-[#D0D1DA] rounded-lg bg-transparent  px-4 py-3"
                  placeholder={t('line.kyc.surname')}
                />
                {errors?.surname && (
                  <div className="text-[#FC4D55] text-left ml-3">
                    {errors?.surname.message}
                  </div>
                )}
              </div>
            )}
          />
          <Controller
            name="id"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col justify-start w-full">
                <label className="text-left font-normal">
                  {t('line.kyc.id')}
                </label>
                <IMaskInput
                  {...field}
                  className="input w-full border border-[#D0D1DA] rounded-lg bg-transparent  px-4 py-3"
                  mask={'0-0000-00-000-00-0'}
                  // radix="."
                  value={field.value}
                  placeholder="0-0000-00-000-00-0"
                  unmask={true}
                  onAccept={(value) => field.onChange(value)}
                  maxLength={18}
                />
                {errors?.id && (
                  <div className="text-[#FC4D55] text-left ml-3">
                    {errors?.id.message}
                  </div>
                )}
              </div>
            )}
          />
          <Controller
            name="laser"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col justify-start w-full">
                <label className="text-left font-normal">
                  {t('line.kyc.laser')}
                </label>
                {/* <input
                  {...field}
                  autoComplete=""
                  className="input w-full border border-[#D0D1DA] rounded-lg bg-transparent  px-4 py-3"
                  placeholder="XX0-0000000-00"
                  maxLength={12}
                /> */}
                <IMaskInput
                  {...field}
                  className="input w-full border border-[#D0D1DA] rounded-lg bg-transparent  px-4 py-3"
                  mask={'aa0-0000000-00'}
                  value={field.value}
                  placeholder="XX0-0000000-00"
                  // unmask={true}
                  onAccept={(value) => field.onChange(value)}
                  maxLength={14}
                />
                {errors?.laser && (
                  <div className="text-[#FC4D55] text-left ml-3">
                    {errors?.laser.message}
                  </div>
                )}
              </div>
            )}
          />
          <Controller
            name="hbd"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col justify-start w-full">
                <label className="text-left font-normal">
                  {t('line.kyc.hbd')}
                </label>
                <DatePicker
                  {...field}
                  dateFormat="dd/MM/yyyy"
                  className="input w-full border border-[#D0D1DA] rounded-lg bg-transparent  px-4 py-3"
                  selected={startDate}
                  // onChange={(date) => setStartDate(date)}
                  onChange={(date) => {
                    setValue('hbd', dayjs(date).format('DD/MM/YYYY'));
                    setStartDate(date);
                  }}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  maxDate={new Date()}
                />
                {errors?.hbd && (
                  <div className="text-[#FC4D55] text-left ml-3">
                    {errors?.hbd.message}
                  </div>
                )}
              </div>
            )}
          />
          <Controller
            name="gender"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col justify-start w-full">
                <label className="text-left font-normal">
                  {t('line.kyc.gender')}
                </label>
                <select
                  {...field}
                  className="select border-1 color "
                  // data={select}
                  // onChange={onSelectChange}
                  // {...register('genderName')}
                >
                  <option value="" disabled>
                    {t('info_form.gender.t')}
                  </option>
                  <option value="male">{t('info_form.gender.male')}</option>
                  <option value="female">{t('info_form.gender.female')}</option>
                </select>
                {errors?.gender && (
                  <div className="text-[#FC4D55] text-left ml-3">
                    {errors?.gender.message}
                  </div>
                )}
              </div>
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col justify-start w-full">
                <label className="text-left font-normal">
                  {t('line.kyc.email')}
                </label>
                <input
                  {...field}
                  autoComplete=""
                  className="input w-full border border-[#D0D1DA] rounded-lg bg-transparent  px-4 py-3"
                  placeholder="example@mail.com"
                />
                {errors?.email && (
                  <div className="text-[#FC4D55] text-left ml-3">
                    {errors?.email.message}
                  </div>
                )}
              </div>
            )}
          />
        </div>

        <div className=" pb-5 px-4">
          <button
            type="submit"
            className=" btn bg-[#E6564E] text-white btn-ghost hover:bg-[#E6564E] h-full w-full rounded-full flex flex-col gap-3"
          >
            {t('line.kyc.c')}
          </button>
        </div>
      </form>
    </>
  );
};
export default KycContainer;
