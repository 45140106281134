import { MetroSpinner } from "react-spinners-kit";
import './loading.scss';
import { ImpulseSpinner } from "react-spinners-kit";

const Loading = ({ isLoading }) => {
  return (
    <div className="app-loading h-full">
      <ImpulseSpinner size={50} frontColor="#E6564E" loading={isLoading} />
    </div>
  )
}
export default Loading;