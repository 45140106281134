const AUTH_TOKEN = 'line_t';
const LINE_TOKEN = 'line_id_t';
const LANGUAGE = 'line_lang';
const PHONE = 'line_phone';

export const getToken = () => localStorage.getItem(AUTH_TOKEN);
export const addToken = (token) => localStorage.setItem(AUTH_TOKEN, token);
export const removeLocalStorage = () => localStorage.removeItem(AUTH_TOKEN);

export const getLangStorage = () => localStorage.getItem(LANGUAGE);

export const getPhoneStorage = () => localStorage.getItem(PHONE);
export const addPhoneStorage = (phone) => localStorage.setItem(PHONE, phone);

export const getLineTokenStorage = () => localStorage.getItem(LINE_TOKEN);
