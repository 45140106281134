import { useState } from 'react';
import { LogoFull, PhoneOTP } from '../../assets';

import OtpInput from '../../components/otp';
import { t } from 'i18next';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { useRegister } from '../../contexts/RegisterContext';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const OtpContainer = () => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [errorMsg, setErrorMsg] = useState();
  const { reloadUserInfo } = useAuth();
  const { resentOTP, register2 } = useRegister();

  const onSubmitOTP = async (e) => {
    try {
      setErrorMsg(undefined);
      const data = new FormData(e.currentTarget);
      e.preventDefault();
      const pin = data.get('otp');
      const phone = searchParams.get('phone');
      const ref_code = searchParams.get('ref_code');
      const payload = {
        username: phone,
        password: localStorage.getItem('line_p'),
        ref_code: ref_code,
        pin: pin,
        register_from: 'line'
      };

      const res = await register2(payload);

      if (res.data.status === 200) {
        const params = {
          reward: res?.data?.data?.reward ?? 0
        };

        await localStorage.setItem('line_t', res?.data?.data?.token);
        localStorage.removeItem('line_p');

        await reloadUserInfo();

        navigate({
          pathname: '/line/otp-success',
          replace: true,
          search: `?${createSearchParams(params)}`
        });
      }
      console.log('register2', res);
    } catch (error) {
      console.log('error', error);
      setErrorMsg(error);
    }
  };

  const resentOtp = async () => {
    try {
      setErrorMsg(undefined);
      const payload = {
        refer: searchParams.get('refer') ?? null,
        phone: searchParams.get('phone')
      };
      const res = await resentOTP(payload);
      if (res.data.status === 200) {
        const params = {
          ref_code: res?.data?.data?.ref_code,
          phone: searchParams.get('phone')
        };

        navigate({
          pathname: '/line/otp',
          replace: true,
          search: `?${createSearchParams(params)}`
        });
      }
    } catch (error) {
      console.log('error', error);
      setErrorMsg(error);
    }
  };

  return (
    <>
      <form
        className="flex flex-col w-full h-screen gap-5 pb-5"
        onSubmit={onSubmitOTP}
      >
        <div className="flex justify-center items-center my-4">
          <img src={LogoFull} className="h-8" alt="logo" />
        </div>
        <div className=" bg-[#222225] p-4 rounded-lg mx-4 flex flex-col gap-3">
          <div className="flex justify-center">
            <img src={PhoneOTP} className=" h-28" alt="logo" />
          </div>
          <div className="text-[#E6564E] font-bold text-[20px] ">
            {t('line.otp.title')}
          </div>
          <div className="flex flex-col text-[16px]  font-kanitLight">
            <div className="font-extralight">
              {t('line.otp.phone')} {searchParams.get('phone')}
            </div>
            <div className="font-extralight">
              {t('line.otp.ref')} : {searchParams.get('ref_code')}
            </div>
          </div>
          <OtpInput size={6} da />
          <div
            className="text-[#2FA8FF] font-kanitLight cursor-pointer"
            onClick={() => resentOtp()}
          >
            {t('line.otp.resend')}
          </div>
          {errorMsg && (
            <div className="text-[#FC4D55] text-center">{errorMsg}</div>
          )}
        </div>
        <div className=" pb-5 px-4">
          <button
            type="submit"
            className=" btn bg-[#E6564E] text-white btn-ghost hover:bg-[#E6564E] h-full w-full rounded-full flex flex-col gap-3"
          >
            {t('line.confirm')}
          </button>
        </div>
      </form>
    </>
  );
};
export default OtpContainer;
