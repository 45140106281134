import { useTranslation } from 'react-i18next';
import { PowerBy } from '../components';
import NewsContextProvider from '../contexts/NewsContext';
import NewsContainer from '../features/news/NewsContainer';
import Container from '../layout/Container';
import Footer from '../layout/footer/Footer';
import Header from '../layout/header/Header';

const NewsPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header showBalance title={t('line.news')} />
      {/* <PowerBy /> */}
      <Container>
        <NewsContextProvider>
          <NewsContainer />
        </NewsContextProvider>
      </Container>
      <Footer />
    </>
  );
};
export default NewsPage;
