import { useTranslation } from 'react-i18next';
import { PowerBy } from '../components';
import DealContextProvider from '../contexts/DealContext';
import DealContainerDetail, {
  ModelMain
} from '../features/Home/DealContainerDetail';
import Container from '../layout/Container';
import Footer from '../layout/footer/Footer';
import Header from '../layout/header/Header';

const DealDetailPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header showBalance={true} showBack={true} />
      <PowerBy />
      <DealContextProvider>
        <Container>
          <DealContainerDetail />
        </Container>
        <ModelMain />
      </DealContextProvider>
      <Footer />
    </>
  );
};
export default DealDetailPage;
