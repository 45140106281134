import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './Footer.module.scss';

import { useSearchParams, useLocation } from 'react-router-dom';
import { getLocalStorage } from '../../utils/localStorage';
import {
  HomeMenu,
  HomeActiveMenu,
  NewsMenu,
  NewsActiveMenu,
  HistoryMenu,
  HistoryActiveMenu
} from '../../assets/menu';

function Footer({ children }) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  let { pathname } = useLocation();
  let menuPath = pathname.replace('/sabuymoney', '');

  const token = getLocalStorage();

  return (
    <div
      className={`btn-group w-full no-animation flex flex-row justify-between sticky bottom-0  pb-1 pt-4 ${styles.menu}`}
    >
      <Link
        to={`/sabuymoney?token=${token}`}
        className={`btn btn-ghost cursor-auto flex flex-col justify-end nav-menu flex-1 hover:bg-inherit focus:outline-none  pb-1 ${
          menuPath === '' || menuPath === '/deal-detail'
            ? styles.menu_line_active
            : styles.menu_line
        }`}
      >
        <div
          className={`flex flex-col justify-center items-center ${
            menuPath === '' || menuPath === '/deal-detail'
              ? styles.btn_active_home
              : styles.btn
          }`}
        >
          <img
            className="aspect-square w-6"
            src={
              menuPath === '' || menuPath === '/deal-detail'
                ? HomeActiveMenu
                : HomeMenu
            }
            aria-hidden="true"
          />
          <span className="mt-2">{t('sbm.reward')}</span>
        </div>
      </Link>
      <Link
        to={`/sabuymoney/news?token=${token}`}
        className={`btn btn-ghost flex flex-col justify-center nav-menu shrink-0 hover:bg-inherit pb-1 ${
          menuPath === '/news' || menuPath === '/news-detail'
            ? styles.menu_line_active
            : styles.menu_line
        }`}
      >
        <div
          className={`flex flex-col justify-center items-center ${
            menuPath === '/news' || menuPath === '/news-detail'
              ? styles.btn_active
              : styles.btn
          }`}
        >
          <img
            className="aspect-square w-6"
            src={
              menuPath === '/news' || menuPath === '/news-detail'
                ? NewsActiveMenu
                : NewsMenu
            }
            aria-hidden="true"
          />
          <span className="mt-2">{t('sbm.news')}</span>
        </div>
      </Link>
      <Link
        to={`/sabuymoney/history?token=${token}`}
        className={`btn btn-ghost flex flex-col justify-center nav-menu flex-1 hover:bg-inherit pb-1 ${
          menuPath === '/history' ? styles.menu_line_active : styles.menu_line
        }`}
      >
        <div
          className={`flex flex-col justify-center items-center footer-btn ${
            menuPath === '/history' ? styles.btn_active : styles.btn
          }`}
        >
          <img
            className="aspect-square w-6"
            src={menuPath === '/history' ? HistoryActiveMenu : HistoryMenu}
            aria-hidden="true"
          />
          <span className="mt-2">{t('sbm.history')}</span>
        </div>
      </Link>
    </div>
  );
}

export default Footer;
