import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import NewsContainerDetail from '../features/news/NewsContainerDetail';
import Container from '../layout/Container';
import Footer from '../layout/footer/Footer';
import Header from '../layout/header/Header';

const NewsDetail = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header showBalance showBack />
      <Container>
        <NewsContainerDetail />
      </Container>
      <Footer />
    </>
  );
};
export default NewsDetail;
