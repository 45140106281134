import { useTranslation } from 'react-i18next';
import RegisterContextProvider from '../contexts/RegisterContext';
import OtpContainer from '../features/otp/otp';

const OtpPage = () => {
  const { t } = useTranslation();
  return (
    <>
      {/* <RegisterContextProvider> */}
      <OtpContainer />
      {/* </RegisterContextProvider> */}
    </>
  );
};
export default OtpPage;
