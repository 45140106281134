import React, { useContext, useEffect, useState } from 'react';
import { Content, MainContainer, Footer } from '../../layout';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { register as registerApi } from '../../services/api';
import { ROUTES } from '../../constant';
import { AuthContext } from '../../auth';

import { checkStartSpeedKub, ConsentSetup } from '../../utils';
import { RegisterPDPA } from '../../layout/term/pdpa';

import QrIcon from '../../assets/login/qr.png';
import AppIcon from '../../assets/login/icon-app.png';
import DownLoadIcon from '../../assets/login/download.png';
import Qrcode from '../../assets/qr_download-new.png';


import './register.scss';
import PasswordInput2 from '../../components/form/password/password2';
import { useRegister } from '../../RegisterContext';


const TextError = ({ text }) => {
  return (
    <div className="alert bg-transparent">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 text-error"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <span className="text-error">{text}</span>
      </div>
    </div>
  );
};

const RegisterPage = () => {
  const { initSystemInfo, systemInfo } = useContext(AuthContext);

  const { t } = useTranslation();
  const [errorMsg, setErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(true);

  const [isReferral, setIsReferral] = useState(false);
  const [searchParams] = useSearchParams();

  const navigator = useNavigate();

  const { termChecked, consentData, setConsentData } = useContext(AuthContext);
  const { registerActionStep1 } = useRegister();

  const formSchema = Yup.object().shape({
    phone: Yup.string()
      .min(10, 'please fill phone number')
      .required('Please enter phone number'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('password is required'),
    confirmPass: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('confirm password is required')
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    formState,
    getValues,
    setError,
    clearErrors,
    setValue
  } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = async () => {
    setLoading(true);

    const { password, confirmPass } = getValues();
    if (password === confirmPass) {
      await registerSubmit();
    }
    else {
      setError('confirmPass', {
        type: 'custom',
        message: 'Password must match'
      });
    }


    setLoading(false);
  };

  const registerSubmit = async () => {
    const { phone, password, ref } = getValues();
    if (termChecked === false) {
      const res = await ConsentSetup(phone);
      console.log('ConsentSetup ====>res', res);
      setConsentData(res);
      document.getElementById('term_link').click();
    } else {
      //handle Consent data register call consent api
      if (consentData?.result?.length) {
        // when has show windows 1
        const filter_consent1 = consentData?.result.filter(
          (i) => i.id === 'CONSENT_CS22001'
        );
        if (filter_consent1?.length) {
          document.getElementById(filter_consent1[0]?.id).click();
        } else {
          // when has show windows 2 after agree 1
          document.getElementById(consentData?.result[0].id).click();
        }
      }

      if (consentData?.result?.length === 0) {
        console.log('on Register');
        if (phone && password) {
          const res = await registerActionStep1(phone, password, ref);
          console.log('res', res);
          if (res.status === 200) {
            setErrorMsg(null);
            navigator(`${ROUTES.OTP}?phone=${phone}`, {
              replace: true,
              state: { ...res.data.data, phone, password }
            });
          } else {
            const { data } = res;
            setErrorMsg(data?.message ?? '');
          }
        } else {
          setErrorMsg(t('login.error'));
        }
      }
    }
  };

  useEffect(() => {
    const callApi = async () => {
      if (termChecked) {
        setLoading(true);
        await registerSubmit();
        setLoading(false);
      }
    };

    if (checkStartSpeedKub()) {
      // disable register for prod
      const keyInternalTest = localStorage.getItem('key');
      if (keyInternalTest === null) {
        const keyPromt = prompt('Please enter key');
        if (keyPromt === 'speedkub-2022') {
          localStorage.setItem('key', 'speedkub-2022');
        } else {
          navigator('/');
        }
      }
    }
    callApi();
    initSystemInfo();
  }, [termChecked]);

  useEffect(() => {
    const initRefer = () => {
      console.log('searchParams.get("ref")', searchParams.get('ref'));
      const referValue = searchParams.get('ref');
      if (referValue) {
        setValue('ref', referValue);
      }
    };

    if (systemInfo?.event?.is_referal) {
      initRefer();
      setIsReferral(systemInfo?.event?.is_referal ?? false);
    }

  }, [systemInfo]);


  const onchangeForm = () => {
    const { phone, password } = getValues();
    if (password.length < 8) {
      setError('password', {
        type: 'custom',
        message: 'Password must be at least 8 characters'
      });
    } else {
      clearErrors('password');
    }

    if (phone && password.length >= 8) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  };

  return (
    <MainContainer>
      <div className="bg-register grow">
        <Content minHSceen={true}>
          <div className="flex flex-col justify-center items-center">
            <div className="container flex justify-center">
              <p className="text-3xl text-[#E6564E] text-center leading-loose lg:leading-loose">
                {t('login.register')}
              </p>
            </div>
            <Link to="/login" className="flex my-5 gap-2 flex-wrap justify-center">
              <div className="text-[16px]">{t('reg.has_user')}</div>
              <div className='text-[16px] underline font-bold'>{t('login.t')}</div>
            </Link>
            <div className="card w-11/12 xs:w-full max-w-lg bg-[#38383C] mb-10">
              <form
                autoComplete="false"
                className="card-body p-4 xs:p-6"
                onSubmit={handleSubmit(onSubmit)}
                id="register_form"
                onChange={onchangeForm}
              >
                {errorMsg ? <TextError text={errorMsg} /> : null}
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">{t('info.phone')}</span>
                  </label>
                  <input
                    autoComplete="false"
                    type="text"
                    name="phone"
                    {...register('phone')}
                    placeholder="088-888-8888"
                    className='custom-input'
                  />
                  {errors.phone && (
                    <p className="pl-5 text-error text-left text-sm">
                      {errors.phone?.message}
                    </p>
                  )}
                </div>
                <div className="form-control relative">
                  <label className="label">
                    <span className="label-text">{t('info.password')}</span>
                  </label>
                  <PasswordInput2 name="password" register={register} autoComplete={false} />
                  {errors.password && (
                    <p className="pl-5 text-error text-left text-sm">
                      {errors.password?.message}
                    </p>
                  )}
                </div>
                <div className="form-control relative">
                  <label className="label">
                    <span className="label-text">{t('reg.confirm_pass')}</span>
                  </label>
                  <PasswordInput2 name="confirmPass" placeholder="confirm-password" register={register} autoComplete={false} />
                  {errors.confirmPass && (
                    <p className="pl-5 text-error text-left text-sm">
                      {errors.confirmPass?.message}
                    </p>
                  )}
                </div>
                {
                  isReferral ? <div className="form-control">
                    <label className="label">
                      <span className="label-text">{t('refer_sub')}</span>
                    </label>
                    <input
                      type="text"
                      name="phone"
                      readOnly={searchParams.get('ref') ? true : false}
                      {...register('ref')}
                      className='custom-input'
                    />
                    {errors.ref && (
                      <p className="pl-5 text-error text-left text-sm">
                        {errors.ref?.message}
                      </p>
                    )}
                  </div> : null
                }
                <label id="term_link" htmlFor="term-modal" type="hidden" />
                <div className="form-control">
                  <div className="flex justify-center">
                    {loading ? (
                      <button className="btn btn-primary rounded-full loading" />
                    ) : (
                      <button
                        id="register_submit"
                        type="submit"
                        className={` w-fit px-7 py-1 btn btn-primary rounded-full ${loading ? 'loading' : ''}`}
                        disabled={!canSubmit}
                      >
                        {t('reg.register')}
                      </button>
                    )}
                  </div>
                  {/* <Link to='/otp' className="btn btn-primary rounded-full">
                     {t('confirm')}
                  </Link> */}
                </div>
                {/* <div className="form-control mt-6">
                  <div className="flex justify-between">
                    <label className="label">
                      <span className="label-text-alt">
                        {t('info.have_account')}
                      </span>
                      <div className="m-1"></div>
                      <button className="badge">
                        <Link to="/login" className="label-text-alt font-bold">
                          {t('login.t')}
                        </Link>
                      </button>
                    </label>
                  </div>
                </div> */}
              </form>
            </div>
            <label className="label cursor-pointer">
              <input
                type="checkbox"
                id="register-sidebarLogin"
                className="modal-toggle"
                htmlFor="register-sidebarLogin"
              />
              <div className="modal">
                <div className="modal-box relative grid justify-items-center">
                  <label
                    htmlFor="register-sidebarLogin"
                    className="btn btn-sm btn-circle absolute right-2 top-2"
                  >
                    ✕
                  </label>
                  <h3 className="text-lg font-bold mb-2">
                    {t('scan_qr.ms')}
                  </h3>
                  <img src={Qrcode} alt="" />
                </div>
              </div>
              <div className="flex flex-col justify-center w-full max-w-sm mb-5">
                <div>{t('download_t')}</div>
                <div className="grid grid-cols-3 gap-5">
                  <div>
                    <div className="aspect-square">
                      <img src={AppIcon} alt="AppIcon" />
                    </div>
                  </div>
                  <div>
                    <div className="aspect-square p-4">
                      <img src={QrIcon} alt="QrIcon" />
                    </div>
                  </div>
                  <div className='flex items-center'>
                    <div className="aspect-[13/9]">
                      <img src={DownLoadIcon} alt="download" />
                    </div>
                  </div>
                </div>
              </div>
            </label>
          </div>
        </Content>
      </div>
      <Footer />
      <RegisterPDPA phone={getValues('phone')} />
    </MainContainer>
  );
};
export default RegisterPage;
