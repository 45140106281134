import DealList from './DealList';
import FilterDeal from './FilterDeal';
import HotDealBanner from './hot-deal/hot-deal';
// import RankPoint from './RankPoint';

const HomeContainer = () => {
  return (
    <>
      {/* <RankPoint /> */}
      <HotDealBanner />
      <FilterDeal />
      <DealList />
    </>
  );
};
export default HomeContainer;
