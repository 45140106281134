import React, { useContext } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from './constant';

import { AuthContext } from './auth';

const ProtectedRoutes = () => {
  const location = useLocation();
  const { pathname } = location;
  const { isLogin } = useContext(AuthContext);

  // if (pathname.includes('/register')) {
  //   if (isLogin) {
  //     return (
  //       <Navigate to={ROUTES.DASHBOARD} replace state={{ from: location }} />
  //     );
  //   } else {
  //     return <Outlet />;
  //   }
  // }

  return isLogin ? (
    <Outlet />
  ) : (
    <Navigate to={ROUTES.LOGIN} replace state={{ from: location }} />
  );
};
export default ProtectedRoutes;
