import React, { useContext, useState } from 'react';
import './term.scss';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../auth';
import { saveConsent } from '../../services/api';
import { getConsentTokenLocal } from '../../utils';
import dayjs from 'dayjs';

export const ConsentLayoutPDPA = ({
  id,
  show,
  url,
  dataAPI,
  phone,
  secret,
  type
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(show);
  const {
    setConsentData,
    consentData,
    setConsentDataDashboard,
    consentDataDashboard
  } = useContext(AuthContext);

  const onCloseRegister = async (e) => {
    console.log('onCloseRegister id', id);
    if (id === 'CONSENT_CS22001') {
      e.preventDefault();
      setShowModal(false);
      document.getElementById(id).click();
    } else {
      await savePDPAtoAPI(dataAPI, phone, secret, 'disagree');
      e.preventDefault();
      setShowModal(false);
      document.getElementById(id).click();
      handleRegisterFlow();
    }
  };

  const onAgreeRegister = async (e) => {
    console.log('onAgreeRegister id', id);
    if (id === 'CONSENT_CS22001') {
      await savePDPAtoAPI(dataAPI, phone, secret, 'agree');

      e.preventDefault();
      setShowModal(false);
      document.getElementById(id).click();

      handleRegisterFlow();
    } else {
      console.log('onAgreeRegister id', id);

      await savePDPAtoAPI(dataAPI, phone, secret, 'agree');

      e.preventDefault();
      setShowModal(false);
      document.getElementById(id).click();

      handleRegisterFlow();
    }
  };

  const handleRegisterFlow = () => {
    const removeDataIndex = consentData?.result.findIndex((i) => i.id === id);
    console.log('removeDataIndex', removeDataIndex);
    consentData?.result.splice(removeDataIndex, 1);
    console.log('removeDataIndex consentData', consentData);
    setConsentData(consentData);
    document.getElementById('register_submit').click();
  };

  const handleDashboardFlow = (action) => {
    const removeDataIndex = consentDataDashboard?.result.findIndex(
      (i) => i.id === id
    );
    console.log('removeDataIndex', removeDataIndex);
    consentDataDashboard?.result.splice(removeDataIndex, 1);
    console.log('removeDataIndex consentDataDashboard', consentDataDashboard);
    setConsentDataDashboard(consentDataDashboard);
    console.log('handleDashboardFlow processConsent', consentDataDashboard);
    initProcessConsent(action);
  };

  const initProcessConsent = async (action) => {
    console.log('initProcessConsent after click ===>', consentDataDashboard); //handle Consent data register call consent api
    // debugger;
    if (consentDataDashboard?.result?.length) {
      // when has show windows 1
      const filter_consent1 = consentDataDashboard?.result.filter(
        (i) => i.id === 'CONSENT_CS22001'
      );
      if (filter_consent1?.length && action === 'agree') {
        document.getElementById(filter_consent1[0]?.id).click();
      } else {
        // when click widows 2 agree
        // if(action === 'agree'){
        // when has show windows 2 after agree 1
        document.getElementById(consentDataDashboard?.result[0].id).click();
        // }
      }
    }
  };

  const onCloseRegisterModal = async (e) => {
    e.preventDefault();
    setShowModal(false);
    document.getElementById('register_submit').click();
  };

  const onCloseDashboardModal = async (e) => {
    console.log('onCloseModal DashBoard id', id);
    if (id === 'CONSENT_CS22001') {
      await savePDPAtoAPI(dataAPI, phone, secret, 'agree');
      handleDashboardFlow('disagree');
      setShowModal(false);
      // document.getElementById(id).click();
    } else {
      await savePDPAtoAPI(dataAPI, phone, secret, 'disagree');
      e.preventDefault();
      setShowModal(false);
      // document.getElementById(id).click();
      handleDashboardFlow('disagree');
    }
  };

  const savePDPAtoAPI = async (item, phone, secret, action) => {
    let requestData = {};

    if (type === 'register' || type === 'dashboard') {
      requestData = {
        phone: `${phone}`,
        entity: 'SBD',
        consent: [
          {
            uri: item?.content_url,
            value: action,
            consent_created: item?.created_at,
            consent_updated: dayjs().toISOString(),
            consent_id: item?.id,
            channel: 'SpeedKub'
          }
        ]
      };
    } else if (type === 'switch-consent') {
      requestData = {
        phone: `${phone}`,
        entity: 'SBD',
        consent: [
          {
            uri: item?.uri,
            value: action,
            consent_created: item?.consent_created,
            consent_updated: dayjs().toISOString(),
            consent_id: item?.consent_id,
            channel: item?.channel
          }
        ]
      };
    }
    const res = await saveConsent(requestData, secret);
    console.log('savePDPAtoAPI res', res);
    return res;
  };

  const switchChangePDPA = async (e) => {
    if (e.target.checked) {
      await savePDPAtoAPI(dataAPI, phone, secret, 'agree');
    } else {
      await savePDPAtoAPI(dataAPI, phone, secret, 'disagree');
    }
    setConsentDataDashboard(undefined);
  };

  const onAgreeDashBoard = async (e) => {
    console.log('onAgree DashBoard id', id);
    await savePDPAtoAPI(dataAPI, phone, secret, 'agree');

    e.preventDefault();
    setShowModal(false);
    handleDashboardFlow('agree');
  };

  const onCloseDashBoard = async (e) => {
    console.log('onClose DashBoard id', id);
    if (id === 'CONSENT_CS22001') {
      await savePDPAtoAPI(dataAPI, phone, secret, 'agree');
      handleDashboardFlow('disagree');
      setShowModal(false);
      document.getElementById(id).click();
    } else {
      await savePDPAtoAPI(dataAPI, phone, secret, 'disagree');
      e.preventDefault();
      setShowModal(false);
      document.getElementById(id).click();
      handleDashboardFlow('disagree');
    }
  };

  let actionAgree;
  let actionDisagree;
  let actionCloseModal;

  if (type === 'register') {
    actionAgree = onAgreeRegister;
    actionDisagree = onCloseRegister;
    actionCloseModal = onCloseRegisterModal;
  } else if (type === 'dashboard') {
    actionAgree = onAgreeDashBoard;
    actionDisagree = onCloseDashBoard;
    actionCloseModal = onCloseDashboardModal;
  } else if (type === 'switch-consent') {
    actionAgree = () => {};
    actionDisagree = () => {};
    actionCloseModal = () => {};
  }

  return (
    <>
      <input
        type="checkbox"
        id={id}
        className="modal-toggle"
        defaultChecked={showModal}
      />
      <div className="modal min-h-full z-[70]">
        <div className="modal-box flex flex-col w-11/12 max-w-5xl">
          <div className="flex modal-open w-full right-2 top-2 text-center">
            <label
              htmlFor={id}
              className="btn btn-sm btn-circle absolute right-2 top-2"
              onClick={actionCloseModal}
            >
              ✕
            </label>
          </div>
          <div className="overflow-auto mt-4">
            <ContentData id={id} t={t} url={url} data={dataAPI} />
          </div>
          {type === 'register' ? (
            <div className="modal-action justify-between">
              <label
                onClick={actionDisagree}
                className="btn rounded-full text-white"
              >
                {id === 'CONSENT_CS22001'
                  ? t('pdpa.close')
                  : t('pdpa.disagree')}
              </label>
              <label
                htmlFor={id}
                className="btn btn-info rounded-full text-white"
                onClick={actionAgree}
              >
                {t('pdpa.agree')}
              </label>
            </div>
          ) : null}
          {type === 'dashboard' ? (
            <div className="modal-action justify-between">
              <label
                onClick={actionDisagree}
                className="btn rounded-full text-white"
              >
                {id === 'CONSENT_CS22001'
                  ? t('pdpa.close')
                  : t('pdpa.disagree')}
              </label>
              <label
                htmlFor={id}
                className="btn btn-info rounded-full text-white"
                onClick={actionAgree}
              >
                {t('pdpa.agree')}
              </label>
            </div>
          ) : null}
          {type === 'switch-consent' ? (
            <div className="modal-action justify-between">
              <div className="flex justify-center w-full">
                <div className="form-control">
                  <label className="label cursor-pointer">
                    <span className="label-text">ยอมรับ {dataAPI?.name}</span>
                    <input
                      type="checkbox"
                      onChange={switchChangePDPA}
                      className="toggle toggle-secondary ml-5"
                      defaultChecked={dataAPI?.value === 'agree' ? true : false}
                    />
                  </label>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

const ContentData = ({ id, title, t, url, data }) => {
  let [isLoading, setIsloading] = useState(true);
  const onLoadFile = (e) => {
    setIsloading(false);
  };

  const openPDF = (url) => {
    const win = window.open(
      `${url}`,
      '_blank',
      `toolbar=0,status=0,width=${window.screen.availHeight},height=${window.screen.availWidth}`
    );
    win.focus();
  };

  return (
    <>
      <div className="block w-full text-center mb-5">
        <h3 className="font-bold">{data?.name}</h3>
      </div>
      <article className="overflow-hidden text-left">
        {/* {isLoading ? (
        <div className="h-full absolute top-0 left-0 right-0 bottom-0">
          <Loading isLoading={isLoading} />
        </div>
      ) : null} */}
        {data?.description}

        <a className="ml-3 cursor-pointer link" onClick={() => openPDF(url)}>
          อ่านเพิ่มเติม
        </a>

        {/* <iframe
        title={title}
        className="iframe-content"
        type="application/pdf"
        // src={`https://docs.google.com/gview?url=${url}&embedded=true`}
        src={`https://docs.google.com/viewer?embedded=true&url=${url}`}
        // src={`${url}`}
        onLoad={onLoadFile}
      /> */}
      </article>
    </>
  );
};

export const RegisterPDPA = ({ showStep, phone }) => {
  const { consentData } = useContext(AuthContext);
  let listConsent = consentData ? consentData?.result : [];
  return (
    listConsent &&
    listConsent.map((item) => {
      console.log('item', item);
      return (
        <ConsentLayoutPDPA
          type={'register'}
          secret={consentData?.encryption_secret}
          phone={phone}
          id={item.id}
          key={item.id}
          url={item?.content_url}
          dataAPI={item}
        />
      );
    })
  );
};

export const DashboardPDPA = () => {
  const { consentDataDashboard, phoneNumber } = useContext(AuthContext);
  let listConsent = consentDataDashboard ? consentDataDashboard?.result : [];
  return (
    listConsent &&
    listConsent.reverse().map((item) => {
      console.log('item', item);
      return (
        <ConsentLayoutPDPA
          type={'dashboard'}
          phone={phoneNumber}
          secret={consentDataDashboard?.encryption_secret}
          id={item.id}
          key={item.id}
          url={item?.content_url}
          dataAPI={item}
        />
      );
    })
  );
};

export const SwitchConsentPDPA = ({ showStep, phone }) => {
  const { consentData } = useContext(AuthContext);

  let listConsent = consentData ? consentData?.consent : [];

  return (
    listConsent &&
    listConsent.map((item, index) => {
      console.log('item', item);
      return (
        // <div key={index}>
        <ConsentLayoutPDPA
          key={index}
          type={'switch-consent'}
          secret={consentData?.encryption_secret}
          phone={phone}
          id={item?.consent_id}
          url={item?.uri}
          dataAPI={item}
        />
        // </div>
      );
    })
  );
};
