import HTMLReactParser from "html-react-parser";

export const AboutSection = ({ icon, img, top, sec, sec2 }) => {
  return (
    <div className="px-5 sm:basis-4/4 md:basis-2/4 lg:basis-2/4 xl:basis-1/4">
      <div className="mx-auto mt-5 mb-5 w-24">
        <img alt="logo-section" src={icon} />
      </div>
        <>
          <p className="text-[#E6564E] text-2xl font-bold text-center leading-loose lg:leading-loose">
            {top}
          </p>
          {
            img ? <div>
            <img alt="logo-section" src={img} />
          </div> : null
          }
          <p className="text-[#49494A] text-1xl font-bold text-center leading-loose lg:leading-loose">
            {HTMLReactParser(sec)}
          </p>
        </>
    </div>
  );
};

export const CustomerSection = ({ icon, img, top, sec, sec2 }) => {
  return (
    <div className="px-5 sm:basis-4/4 md:basis-2/4 lg:basis-2/4 xl:basis-1/4">
      {
        icon ? <div className="mx-auto mt-5 mb-5 w-24">
        <img alt="logo-section" src={icon} />
      </div> : null
      }
      {
        top ? <p className="text-[#E6564E] text-2xl  font-bold text-center leading-loose lg:leading-loose">
        {top}
      </p> : null
      }
      
      <div>
        {
          sec ? <p className="text-[#49494A] text-1xl font-bold text-center leading-loose lg:leading-loose">
          {HTMLReactParser(sec)}
        </p> : null
        }
        {img && img.map((i,key) => <img alt="logo-section" key={key} src={i} />)}
        {
          sec2 ? <p className="text-[#49494A] text-1xl font-bold text-center leading-loose lg:leading-loose">
          {HTMLReactParser(sec2)}
        </p> : null
        }
        
      </div>
    </div>
  );
};
