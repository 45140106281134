import { useEffect, useState, createContext, useContext } from 'react';
import * as authApi from '../api/authApi';
import * as walletApi from '../api/walletApi';
import {
  addPhoneStorage,
  getLocalStorage,
  removeLocalStorage
} from '../utils/localStorage';

const AuthContext = createContext();

function AuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        if (getLocalStorage()) {
          await getUserInfo();
          await getWalletBalance();
        }
      } catch (err) {
        // removeLocalStorage();
        // const { pathname } = window.location;
        // window.location.replace(pathname);
      } finally {
        setInitialLoading(false);
      }
    };
    fetchUserInfo();
  }, []);

  const getUserInfo = async () => {
    const res = await authApi.getAccountInfo();
    const data = res.data.data;
    setUser(data);
    setPhoneNumber(data.username);
    addPhoneStorage(data.username);
  };

  const getWalletBalance = async () => {
    const res = await walletApi.getWalletBalance();
    setBalance(res.data.data);
  };

  return (
    <AuthContext.Provider
      value={{
        initialLoading,
        user,
        phoneNumber,
        balance,
        getWalletBalance
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthContextProvider;
