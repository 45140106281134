import React from 'react';
import './countdown.scss';
import 'animate.css';

import { Content, MainContainer } from '../../layout';
import { CountdownTimer } from '../../components';
import { useTranslation } from 'react-i18next';
import { timeStart } from '../../utils';

//const timeCountEnd = 1659983662000; mock
// const timeCountEnd = 1652317200000;
// Epoch timestamp: 1652317200
// Timestamp in milliseconds: 1652317200000
// Date and time (your time zone): Thursday, May 12, 2022 8:00:00 AM GMT+07:00
// https://www.epochconverter.com/#tools

// Headline: Noto Sans Thai Semibold
// Body text: Noto Sans Thai Regular

const CountdownPage = () => {
  const { t } = useTranslation();

  const reload = ()=>{
    window.location.assign('/')
  }
  return (
    <MainContainer hideMenu={true}>
      <Content minHSceen={true} coinGif={true} appBg={true} bgRight={true} hero={true}>
        <div className="container flex flex-col h-full justify-center animate__animated animate__slideInDown">
            <p className="mb-5 text-4xl font-bold">{t('countdown.top')}</p>
            <CountdownTimer countdownTimestampMs={timeStart} timeOut={reload} type="hard"/>
            <div className="mt-10 mb-5">
              <button type="button" className="btn btn-outline btn-primary">SPEEDKUB TOKEN</button>
            </div>
            <div className="mt-10 mb-5">
            <p className="mb-5 text-dec font-bold"></p>
            <p className="font-bold">{t('countdown.footer')}</p>
            </div>
        </div>
      </Content>
    </MainContainer>
  );
}
export default CountdownPage;