export const numberWithCommas = (x) => {
  if (x === undefined) return '0';
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

export const displayPhone = ({ phone = '', showNumber = 3 }) => {
  if (!isNumeric(phone)) {
    return phone;
  }

  let arrStr = phone.split('').map((text, i) => (i >= showNumber ? 'x' : text));
  arrStr.splice(3, 0, '-');
  arrStr.splice(7, 0, '-');
  return arrStr.join('');
};

export const isNumeric = (value) => {
  return /^-?\d+$/.test(value);
};
