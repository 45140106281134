export default class ConfigApi {
  constructor(EnvFile) {
    this.API_KEY = EnvFile.REACT_APP_BACKEND_API_KEY;
    this.BASE_URI = EnvFile.REACT_APP_BACKEND_BASE_URI;
    this.PROJECT_ID = EnvFile.REACT_APP_PROJECT_ID;
    this.PDPA_BASE_URI = EnvFile.REACT_APP_BACKEND_PDPA_BASE_URI;
    this.PDPA_CLIENT_SECRET = EnvFile.REACT_APP_BACKEND_PDPA_CLIENT_SECRET;
  }

  get apiKey() {
    return this.API_KEY;
  }
  get baseUrl() {
    return this.BASE_URI;
  }
  get projectID() {
    return this.PROJECT_ID;
  }
  get pdpaUrl() {
    return this.PDPA_BASE_URI;
  }
  get pdpaClientSecret() {
    return this.PDPA_CLIENT_SECRET;
  }
}
