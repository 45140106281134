import React, { useContext, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Content, MainContainer, Footer } from '../../layout';
import { useTranslation } from 'react-i18next';
import { OtpInput } from '../../components/form';
import {
  forgotPassword,
  forgotVerifyOTP,
  loginWithUser,
  register,
  signUp
} from '../../services/api';
import { ROUTES } from '../../constant';
import { CountdownTimer } from '../../components';
import { AuthContext } from '../../auth';
import { useRegister } from '../../RegisterContext';

const nextPath = (mode) => {
  let path = 'userinfo';
  let state = {};
  switch (mode) {
    case 'forgot':
      path = '/setpassword';
      break;
    case 'change':
      path = '/status';
      state = {
        title: 'Change Password Complete',
        button_text: 'OK',
        path: '/dashboard'
      };
      break;
    case 'change-email':
      path = '/status';
      state = {
        title:
          'Email information is completed. You can now use email to login.',
        button_text: 'OK',
        path: '/profile'
      };
      break;
    default:
      path = '/success';
      break;
  }
  return {
    path: path,
    state: state
  };
};

const genResendTime = () => {
  const minutesToAdd = 1;
  const currentDate = new Date();
  return new Date(currentDate.getTime() + minutesToAdd * 60000).getTime();
};

const OtpPage = () => {

  const { initLoginByToken } = useContext(AuthContext);
  const { resendOtpRegister, registerActionStep2, password: passwordData } = useRegister();

  let [searchParams] = useSearchParams();
  const navigator = useNavigate();
  // searchParams is a URLSearchParams object.
  // See https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
  let mode = searchParams.get('mode');

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const { t } = useTranslation();

  const { state: stateData } = useLocation();
  const [tokenOtp, setTokenOtp] = useState(
    mode === 'forgot' ? stateData?.forgot?.key : stateData
  );
  const { password, setPassword } = useState(passwordData);

  const [reSendOTPTime, setReSendOTPTime] = useState(false);
  const [timeOTP, setTimeOTP] = useState(genResendTime());

  const timeOut = () => {
    console.log('setQrTimeOut');
    setReSendOTPTime(true);
  };

  const onSubmit = async (e) => {
    setLoading(true);
    console.log('data', e);
    const data = new FormData(e.currentTarget);
    e.preventDefault();
    const pin = data.get('otp');
    const phone = searchParams.get('phone');
    const { ref_code } = tokenOtp;
    // const res = await signUp(key, pin);
    const res = await registerActionStep2(ref_code, pin);
    // const res = await loginWithUser(phone, tokenOtp.password);
    document.getElementById('otp-form').reset();
    if (res.status === 200) {
      setErrorMsg(null);
      let mode = searchParams.get('mode');
      let { path, state } = nextPath(mode);
      await initLoginByToken(res.data.data);
      navigator(ROUTES.KYC_INFO, {
        replace: true,
        state: { ...res.data.data, phone, path, ...state }
      });
    } else {
      const { data } = res;
      setErrorMsg(data?.message);
    }
    setLoading(false);
  };

  const onSubmitForgot = async (e) => {
    setLoading(true);
    console.log('data', e);
    const dataFrom = new FormData(e.currentTarget);
    e.preventDefault();
    const pin = dataFrom.get('otp');
    // const phone = searchParams.get("phone");
    // const res = await signUp(tokenOtp, pin);

    const { phone } = stateData?.forgot;

    console.log('phone,pin,key', phone, pin, tokenOtp);
    if (pin.length === 6) {
      const { data } = await forgotVerifyOTP(phone, pin, tokenOtp);
      document.getElementById('otp-form').reset();
      const { status, code } = data;
      if (status === 200) {
        setErrorMsg(null);
        if (data.code === 0) {
          navigator('/setforgot?mode=forgot', {
            replace: true,
            state: { phone, pin, key: tokenOtp }
          });
        }
      } else {
        setErrorMsg(data?.message ?? '');
      }
    }

    setLoading(false);
  };

  const resendOtp = async () => {
    setLoading(true);
    const phone = searchParams.get('phone');
    const res = await resendOtpRegister(phone);
    document.getElementById('otp-form').reset();
    setTimeOTP(genResendTime());
    setReSendOTPTime(false);
    if (res.status === 200) {
      setTokenOtp(res.data);
    } else {
      const { data } = res;
      setErrorMsg(data?.message ?? '');
    }
    setLoading(false);
  };

  const resendOtpForgot = async () => {
    setLoading(true);
    const { phone } = stateData?.forgot;
    document.getElementById('otp-form').reset();
    setTimeOTP(genResendTime());
    setReSendOTPTime(false);
    const { status, data } = await forgotPassword(phone);
    if (status === 200) {
      setTokenOtp(data);
    } else {
      setErrorMsg(data?.message ?? '');
    }
    setLoading(false);
  };

  let submitAction, reSendOtpAction;
  if (mode === 'forgot') {
    submitAction = onSubmitForgot;
    reSendOtpAction = resendOtpForgot;
  } else {
    submitAction = onSubmit;
    reSendOtpAction = resendOtp;
  }

  return (
    <MainContainer>
      <Content minHSceen={true}>
        <div className="w-full h-full flex flex-col justify-center items-center">
          <div className="container flex justify-center">
            <p className="text-3xl text-[#E6564E] text-center leading-loose lg:leading-loose">
              {t('otp.title')}
            </p>
          </div>
          <form
            id="otp-form"
            className="card flex-shrink-0 w-full max-w-xl"
            onSubmit={submitAction}
          >
            <div className="card-body">
              <div className="form-control">
                <label className="label">
                  <span className="label-text">{t('otp.label')}</span>
                </label>
                <OtpInput size={6} />
                {!loading && errorMsg ? (
                  <p className="mt-5 text-error text-center">{errorMsg}</p>
                ) : null}
                {/* {form.formState.errors.otp && <p className="mt-5 text-error text-center">otppppp</p>} */}
              </div>
              <div className="form-control mt-5">
                {loading ? (
                  <button className="btn btn-primary rounded-full loading">
                    {t('confirm')}
                  </button>
                ) : (
                  <button
                    className="btn btn-primary rounded-full"
                    type="submit"
                  >
                    {t('confirm')}
                  </button>
                )}

                {/* <Link className="btn btn-primary rounded-full" to={path} state={state} >
                {t('confirm')}
                </Link> */}
              </div>
              {loading ? null : reSendOTPTime ? (
                <div
                  className="form-control my-2 cursor-pointer"
                  onClick={reSendOtpAction}
                >
                  {t('otp.resend')}
                </div>
              ) : (
                <div className="form-control my-2 text-gray-500 flex flex-row justify-center">
                  {reSendOTPTime ? null : (
                    <>
                      <span className="mx-2">{t('otp.time_resend_t')}</span>
                      <CountdownTimer
                        countdownTimestampMs={timeOTP}
                        timeOut={timeOut}
                      />
                      <span className="ml-2">{t('otp.time_resend')}</span>
                    </>
                  )}
                  { }
                </div>
              )}
            </div>
          </form>
        </div>
      </Content>
      {mode ? null : <Footer />}
    </MainContainer>
  );
};
export default OtpPage;
