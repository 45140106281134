import liff from '@line/liff';

export const initializeLiff = async () => {
  await liff.init({ liffId: process.env.REACT_APP_LIFF_ID });
};

export const getLineIDToken = async () => {
  if (!liff.isLoggedIn()) {
    await liff.login();
  }
  const lineIDToken = await liff.getAccessToken();
  return lineIDToken;
};

export const checkLineLogin = () => {
  return liff.isLoggedIn();
};

export const logoutLineToken = async () => {
  return await liff.logout();
};
