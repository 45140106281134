import axios from 'axios';
import ConfigApi from './config';
import { refreshToken } from '../api/authApi';

const appConfig = new ConfigApi(process.env);
const BASE_URI = appConfig.baseUrl;

const axiosLineOA = axios.create({
  baseURL: BASE_URI
});

axiosLineOA.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('line_t') ?? '';
    // config.headers['api-key'] = API_KEY;
    // 'Content-Type': 'application/json',

    // config.headers['Access-Control-Allow-Origin'] = '*';
    config.headers['Content-Type'] = 'application/json';
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }
  // (error) => Promise.reject(error)
);

axiosLineOA.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const { data } = await refreshToken();
          const { token, refresh_token } = data?.data;
          // debugger;a
          localStorage.setItem('line_t', token);
          localStorage.setItem('line_t_r', refresh_token);
          originalConfig.headers = {
            ...originalConfig.headers,
            Authorization: `Bearer ${token}`
          };

          return axiosLineOA(originalConfig);
        } catch (_error) {
          // debugger;
          // if (_error.response && _error.response.data) {
          //   return Promise.reject(_error.response.data);
          // }
          return Promise.reject(_error);
        }
      }

      // if (err.response.status === 401 && err.response.data) {
      //   return Promise.reject(err.response.data);
      // }
    }
    return Promise.reject(err);
  }
);

export default axiosLineOA;
