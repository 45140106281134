import React, { useContext, useEffect, useState } from 'react';
import SpeedLogoGray from '../../assets/logo-gray.png';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

// import required modules
import { Autoplay, Navigation, Thumbs } from 'swiper';
import { AuthContext } from '../../auth';
import { getNews } from '../../services/api';

const NewsItem = ({ item }) => {
  console.log('item', item)
  const findBannerImage = (item) => {
    return item.thumbnail_image;
  };

  const [urlImage, setUrlImage] = useState([]);
  useEffect(() => {
    const loadImage = async () => {
      let listImage = [];
      let urlFromFirebase;
      let bannerUrl = findBannerImage(item);
      if (bannerUrl) {
        urlFromFirebase = bannerUrl;
        listImage.push(urlFromFirebase);
      }
      setUrlImage(listImage);
    };
    loadImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      {urlImage.length > 0 ? (
        <>
          <div className="aspect-square">
            <img src={urlImage[0]} className="object-cover rounded" />
          </div>
        </>
      ) : (
        <div
          className="px-3 aspect-square rounded bg-[#D3D3D3]"
        >
          <div className="px-3 h-100  w-7/12  image-logo-gray">
            <img
              className="object-center object-contain h-28 w-28"
              src={SpeedLogoGray}
            />
          </div>
          <div>{item.title}</div>
        </div>
      )}
    </>
  );
};

const NewsSlider = () => {
  const [data, setData] = useState();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const { token, phoneNumber } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getNews(token, phoneNumber);
        setData(res.data.data.data ?? []);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  // when cannot has data deal
  if (!data) {
    return <></>;
  }

  return (
    <div className="w-full xxs:w-[450px] p-2">
      <Swiper
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false
        }}
        spaceBetween={10}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[Thumbs, Autoplay, Navigation]}
        className="swiper-content"
      >
        {data ? (
          data.map((item, keys) => {
            return (
              <SwiperSlide key={keys}>
                <NewsItem item={item} />
              </SwiperSlide>
            );
          })
        ) : (
          <div className="flex flex-col justify-center items-center mt-10">
            <div>No result not found</div>
          </div>
        )}
      </Swiper>

      <div className='p-2'>
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={data ? data.length : 0}
          className="bullet-swiper"
        >
          {data ? (
            data.map((item, keys) => {
              return (
                <SwiperSlide key={keys}>
                  <div className="thumb-slider"></div>
                </SwiperSlide>
              );
              //
            })
          ) : (
            <div></div>
          )}
        </Swiper>
      </div>
    </div>
  );
};
export default NewsSlider;
