import axiosSBM from '../config/axios';

export const getWalletBalance = () => {
  return axiosSBM.get('/api/v1/wallet/balance');
};
export const getWalletHistory = (type, page, limit) =>
  axiosSBM.get('api/v1/wallet/transactions', {
    params: {
      type,
      page,
      limit
    }
  });
