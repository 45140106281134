import DealList from '../Home/DealList';
import FilterSearch from './FilterDeal';

const SearchContainer = () => {
  return (
    <>
      {/* <RankPoint /> */}
      <FilterSearch />
      <DealList />
    </>
  );
};
export default SearchContainer;
