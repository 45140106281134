import {
  AirplaneFilter,
  CyclingFilter,
  FoodFilter,
  ShoppingFilter,
  VendingFilter,
  SpecialFilter
} from '../assets/filter';

export const dealCategory = [
  {
    name: 'special',
    value: '',
    icon: SpecialFilter
  },
  {
    name: 'food',
    value: 'food',
    icon: FoodFilter
  },
  {
    name: 'shopping',
    value: 'shopping',
    icon: ShoppingFilter
  },
  {
    name: 'life',
    value: 'life',
    icon: CyclingFilter
  },
  {
    name: 'travel',
    value: 'travel',
    icon: AirplaneFilter
  },
  {
    name: 'vending',
    value: 'vending',
    icon: VendingFilter
  }
];
