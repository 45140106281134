import React from 'react';
import App from './App';
import AuthContextProvider from './contexts/AuthContext';
import LoadingContextProvider from './contexts/LoadingContext';
import { getLangStorage } from './utils/localStorage';
import dayjs from 'dayjs';
import SystemContextProvider from './contexts/SystemContext';

const SabuyMoneyMainApp = () => {
  const lang = getLangStorage() ?? 'th';

  if (lang === 'th') {
    import(`dayjs/locale/th`);
    dayjs.locale('th');
  } else if (lang === 'en') {
    import(`dayjs/locale/en`);
    dayjs.locale('en');
  }

  return (
    <LoadingContextProvider>
      <SystemContextProvider>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </SystemContextProvider>
    </LoadingContextProvider>
  );
};
export default SabuyMoneyMainApp;
