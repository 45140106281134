import { useTranslation } from 'react-i18next';
import WalletContextProvider from '../contexts/WalletContext';
import WalletContainer from '../features/wallet/WalletContainer';
import Footer from '../layout/footer/Footer';
import Header from '../layout/header/Header';

const WalletPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header showBalance title={t('line.wallet_menu')} />
      <div className=" grow flex flex-col h-full">
        <WalletContextProvider>
          {/* <WalletBalance /> */}
          <WalletContainer />
        </WalletContextProvider>
      </div>
      <Footer />
    </>
  );
};
export default WalletPage;
