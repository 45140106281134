import { LogoFull, PhoneOtpSuccess, Token, BackIcon } from '../../assets';

import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { numberWithCommas } from '../../utils/number';

const OtpSuccess = () => {
  let [searchParams] = useSearchParams();

  return (
    <>
      <div className="flex flex-col w-full h-screen gap-5 pb-5">
        <div className="flex justify-center items-center my-4">
          <img src={LogoFull} className="h-8" alt="logo" />
        </div>
        <div className=" bg-[#222225] p-4 rounded-lg mx-4 flex flex-col gap-3">
          <div className="flex justify-center">
            <img src={PhoneOtpSuccess} className=" h-28" alt="logo" />
          </div>
          <div className="text-[#E6564E] font-bold text-[20px] ">
            {t('line.otp.success')}
          </div>
          <div className="flex flex-col text-[16px] font-kanitLight">
            <div className="font-extralight">{t('line.otp.phone_success')}</div>
          </div>
          {Number(searchParams?.get('reward')) !== 0 ? (
            <div className="bg-[#4D4D52] px-6 py-3 h-full w-full rounded-full flex justify-center text-[16px] font-kanitLight text-white gap-2">
              <div className="">คุณได้รับ</div>{' '}
              {numberWithCommas(searchParams?.get('reward'))}
              <img src={Token} className="h-6" alt="logo" />
              <div className="">จากการสมัครสมาชิก</div>
            </div>
          ) : null}
          {/* */}
        </div>
        <div className=" pb-5 px-4">
          <Link
            to={'/line'}
            className=" btn bg-[#E6564E] text-white btn-ghost hover:bg-[#E6564E] h-full w-full rounded-full flex  gap-3"
          >
            {t('line.otp.start')}
            <img src={BackIcon} className="h-6" alt="logo" />
          </Link>
        </div>
      </div>
    </>
  );
};
export default OtpSuccess;
